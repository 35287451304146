import React, {useState, useEffect} from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import ListIcon from '@rsuite/icons/List';
import PageIcon from '@rsuite/icons/Page';
import Container from '../UI/Container';
import Datagrid from '../UI/Datagrid';
import BasicButton from '../UI/Buttons/BasicButton';
import { FaFileAlt } from "react-icons/fa";
import Modal from '../UI/Modal';
import toast from 'react-hot-toast';
import { grid_columns_renteros, grid_columns_equipos_rentero, grid_columns_rentas_recientes } from '../helpers/grid_columns';
import { merent_instance } from '../helpers/axios_helper';

import StepWizard from "react-step-wizard";
import Step from '../UI/Wizard/Step';
import Nav from '../UI/Wizard/Nav';
import FileInput from '../UI/Inputs/FileInput';
import BasicInput from '../UI/Inputs/BasicInput';
import { FaSpinner } from 'react-icons/fa';
import { RegExpEmail, RegExpCelular, RegExpPassword } from '../helpers/constants';


function Renteros() {
    const [modalEditRentero, setModalEditRentero] = useState(false);
    const [modalDeleteRentero, setModalDeleteRentero] = useState(false);
    const [modalDetalle, setModalDetalle] = useState(false);
    const [modalDocumentos, setModalDocumentos] = useState(false);
    const [modalPlanGratuito, setModalPlanGratuito] = useState(false);
    const [gridDetalle, setGridDetalle] = useState({header: null, rows: null});
    const [renteros, setRenteros] = useState([]);
    const [documentosRegistro, setDocumentosRegistro] = useState([]);
    const [infoPlanGratuito, setInfoPlanGratuito] = useState(null);

    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [selectedFileIFE, setSelectedFileIFE] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({
        "Nombre": "",
        "Apellidos": "",
        "NombreEmpresa": "",
        "RazonSocial": "",
        "Descripcion": "",
        "Clabe": "0",
        "RFC": "",
        "SituacionFiscal": "",
        "PlanRenteroRef": 1
    });
    const [formDataDireccion, setFormDataDireccion] = useState({
        "Nombre": "PRINCIPAL",
        "Calle": "",
        "NumeroInterior": "",
        "NumeroExterior": "",
        "Colonia": "",
        "CiudadRef": "",
        "CodigoPostal": "",
        "Estado": "",
        "Latitud": 0,
        "Longitud": 0
    });
    const [formDataUsuario, setFormDataUsuario] = useState({
        "Nombre": "",
        "Email": "",
        "Telefono": "",
        "Password": "",
        "ConfirmaPassword": ""
    });

    useEffect(() => {
        obtenerEstados();
        obtenerRenteros();
    }, []); 

    const obtenerRenteros = () => {
        merent_instance().get("api/renteros").then(({data}) => {
            setRenteros(data);
        }).catch((e) => {
            console.log(e);
        });
    }

    function allPropsEmptyString(object) {
        return Object.values(object).filter(value => value === undefined || value === null || value === "");
    }

    const newRentero = () => {
        setFormData({
            "Nombre": "",
            "Apellidos": "",
            "NombreEmpresa": "",
            "RazonSocial": "",
            "Descripcion": "",
            "Clabe": "0",
            "RFC": "",
            "SituacionFiscal": "",
            "PlanRenteroRef": 1
        });

        setFormDataDireccion({
            "Nombre": "PRINCIPAL",
            "Calle": "",
            "NumeroInterior": "",
            "NumeroExterior": "",
            "Colonia": "",
            "CiudadRef": "",
            "CodigoPostal": "",
            "Estado": "",
            "Latitud": 0,
            "Longitud": 0
        });

        setFormDataUsuario({
            "Nombre": "",
            "Email": "",
            "Telefono": "",
            "Password": "",
            "ConfirmaPassword": ""
        })
        setModalEditRentero(true);
    }

    const editRentero = (item) => {
        merent_instance().get(`api/renteros/${item.id}`).then(({data}) => {
            console.log(data);
            obtenerCiudades(data.direccion.ciudad.estadoRef)
            setFormData({
                "Nombre": data.nombre,
                "Apellidos": data.apellidos,
                "NombreEmpresa": data.nombreEmpresa,
                "RazonSocial": data.razonSocial,
                "Descripcion": data.descripcion,
                "Clabe": "0",
                "RFC": data.rfc,
                "SituacionFiscal": data.situacionFiscal,
                "PlanRenteroRef": 1
            });

            setFormDataUsuario({
                "Nombre": data.usuario.userName,
                "Email": data.usuario.email,
                "Telefono": data.usuario.phoneNumber,
                "Password": "",
                "ConfirmaPassword": ""
            })

            setFormDataDireccion({
                "Nombre": "PRINCIPAL",
                "Calle": data.direccion.calle,
                "NumeroInterior": data.direccion.numeroInterior,
                "NumeroExterior": data.direccion.numeroExterior,
                "Colonia": data.direccion.colonia,
                "CiudadRef": data.direccion.ciudadRef,
                "CodigoPostal": data.direccion.codigoPostal,
                "Estado": data.direccion.ciudad.estadoRef,
                "Latitud": 0,
                "Longitud": 0
            })
            setModalEditRentero(true);

        }).catch(error => {
            console.log("Error al consultar información del rentero")
        })
    }

    const deleteRentero = (item) => {
        // setRenteroFields(item);
        setModalDeleteRentero(true)
    }

    const confirmDeleteRentero = (item) => {
        toast.success("¡Rentero Eliminado");
        setModalDeleteRentero(false);
    }

    function validaPaso1(){
        formDataUsuario.Nombre = formDataUsuario.Email;
        if(allPropsEmptyString(formDataUsuario).length > 0 || formData.Nombre === "" || formData.Apellidos === ""){
            toast.error("Todos los campos son obligatorios");
            return false;
        }
        if(!formDataUsuario.Email.match(RegExpEmail))
        {
            toast.error("Ingresa una direccion de correo valida");
            return false;
        }
        if(!formDataUsuario.Telefono.match(RegExpCelular))
        {
            toast.error("El número de teléfono no es valido");
            return false;
        }
        if(!formDataUsuario.Password.match(RegExpPassword)){
            toast.error("La contraseña debe tener mínimo 8 caracteres, una minúscula, una mayúscula y un número");
            return false;
        }
        if(formDataUsuario.Password !== formDataUsuario.ConfirmaPassword){
            toast.error("Las contraseñas no coinciden");
            return false;
        }
        return true;
    }

    function validaPaso2(){
        if(allPropsEmptyString(formData).length > 0 || allPropsEmptyString(formDataDireccion).length > 0){
            toast.error("Todos los campos son obligatorios");
            console.log(allPropsEmptyString(formData), allPropsEmptyString(formDataDireccion))
            return false;
        }
        return true;
    }

    const handleSave = () => {
        setIsCreating(true);

        var direccionCompleta = formDataDireccion.Calle.trim() + " Número " + formDataDireccion.NumeroExterior + ", Colonia " + formDataDireccion.Colonia + ",  " + formDataDireccion.CiudadRef;

        const body_data = formData;
        body_data.Direccion = formDataDireccion;
        body_data.Direccion.DireccionCompleta = direccionCompleta;
        body_data.Usuario = formDataUsuario;


        merent_instance().post("api/usuarios/rentero", body_data).then(({data}) => {
            setIsCreating(false);
            toast.success(data);
            setModalEditRentero(false);
            obtenerRenteros();
        }).catch((error) => {
            setIsCreating(false);
            if(error.response.status >= 500)
                toast.error("Hubo un error al crear el registro, porfavor intenta nuevamente más tarde");
            else
                toast.error(error.response.data);
        });
    }

    const obtenerEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const obtenerCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const seleccionaEstado = (value) => {
        setFormDataDireccion({...formDataDireccion, Estado: value});
        obtenerCiudades(value);
    }

    const verDetalle = (item) => {
        console.log(item);
        merent_instance().get(`api/renteros/${item.id}/equipos`).then(({data}) => {
            setModalDetalle(true);
            setGridDetalle({title: 'Detalle de equipos', header: grid_columns_equipos_rentero, rows: data});
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al cargar la información");
        })
    }

    const verDetalleRentas = (item) => {
        merent_instance().get(`api/renteros/${item.id}/rentas/recientes`).then(({data}) => {
            setModalDetalle(true);
            setGridDetalle({title: 'Detalle de rentas', header: grid_columns_rentas_recientes, rows: data});
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al cargar la información");
        });

        // setModalDetalle(true);
        // setGridDetalle({title: 'Detalle de rentas', header: grid_columns_rentas_recientes, rows: dummy_data_rentas});
    }

    const verDocumentos = (item) => {
        merent_instance().get(`api/renteros/${item.id}/documentos`).then(({data}) => {
            setModalDocumentos(true);
            setDocumentosRegistro(data);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al cargar los documentos del rentero");
            /* setModalDocumentos(true);
            setDocumentosRegistro(['https://www.google.com.mx/', 'https://web.whatsapp.com/', 'https://www.youtube.com/']); */
        });
    }

    const options_dropdown = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editRentero
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteRentero
        },
        {
            label: 'Ver rentas recientes',
            icon: <ListIcon/>,
            action: (item) => {verDetalleRentas(item)}
        },
        {
            label: 'Ver sus equipos',
            icon: <ListIcon/>,
            action: (item) => {verDetalle(item)}
        },
        {
            label: 'Asignar/Quitar plan gratuito',
            icon: <ListIcon/>,
            action: (item) => {asignarPlanGratuito(item)}
        },
        {
            label: 'Ver documentos de registro',
            icon: <PageIcon/>,
            action: (item) => {verDocumentos(item)}
        }
    ];

    const showDetail = (data) => {
    }

    const asignarPlanGratuito = (item) => {
        setInfoPlanGratuito(item);
        setModalPlanGratuito(true);
    }

    const confirmAsignarPlanGratuito = () => {
        merent_instance().patch(`api/renteros/${infoPlanGratuito.id}/planes/asignar/gratuito`).then(({data}) => {
            toast.success(data);
            setModalPlanGratuito(false);
            setInfoPlanGratuito(null);
            obtenerRenteros();
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error con tu solicitud");
        });
    }

    const confirmRemoverPlanGratuito = () => {
        merent_instance().patch(`api/renteros/${infoPlanGratuito.id}/planes/remover/gratuito`).then(({data}) => {
            toast.success(data);
            setModalPlanGratuito(false);
            setInfoPlanGratuito(null);
            obtenerRenteros();
        }).catch((e) => {
            console.log(e);
        });
    }

    const cancelAsignarPlanGratuito = () => {
        setModalPlanGratuito(false);
        setInfoPlanGratuito(null);
    }

    return (
        <Container>
            <Datagrid 
                title="Renteros"
                header={grid_columns_renteros(editRentero)}
                data={renteros}
                height={500}
                headerHeight={40}
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar nuevo" type="button" clickFn={newRentero}/>}
            />
            <Modal show={modalEditRentero} setShow={setModalEditRentero} title="Rentero" className="max-w-5xl">
                {/* <Form 
                    formFields={form_fields_renteros} 
                    formState={renteroFields} 
                    formModifyState={setRenteroFields}
                    formSave={saveRentero}
                /> */}
                <StepWizard
                        className='w-full h-full'
                        nav={<Nav/>}>
                        <Step validaPaso={validaPaso1}>
                            <p className="text-lg text-gray-500 font-semibold">Información de contacto</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa tu información personal para iniciar tu registro</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 mb-3 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Nombre(s)" defaultValue={formData.Nombre} setValue={(e)=> setFormData({...formData, Nombre: e.target.value})}/>
                                <BasicInput type="text" placeholder="Apellido(s)" defaultValue={formData.Apellidos} setValue={(e)=> setFormData({...formData, Apellidos: e.target.value})}/>
                                <BasicInput type="text" placeholder="Correo electronico" defaultValue={formDataUsuario.Email} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Email: e.target.value})}/>
                                <BasicInput type="text" placeholder="Telefono de contacto" defaultValue={formDataUsuario.Telefono} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Telefono: e.target.value})}/>
                            </section>
                            <p className="text-sm text-gray-600 font-semibold">Información de la cuenta</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 mb-12 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="password" placeholder="Contraseña" defaultValue={formDataUsuario.Password} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Password: e.target.value})}/>
                                <BasicInput type="password" placeholder="Confirmar contraseña" defaultValue={formDataUsuario.ConfirmaPassword} setValue={(e)=> setFormDataUsuario({...formDataUsuario, ConfirmaPassword: e.target.value})}/>
                            </section>
                        </Step>
                        <Step validaPaso={validaPaso2}>
                            <p className="text-lg text-gray-500 font-semibold">Información de tu negocio</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa la información de tu negocio</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Nombre de la empresa o negocio" defaultValue={formData.NombreEmpresa} setValue={(e)=> setFormData({...formData, NombreEmpresa: e.target.value})}/>
                                <BasicInput type="text" placeholder="Razón social" defaultValue={formData.RazonSocial} setValue={(e)=> setFormData({...formData, RazonSocial: e.target.value})}/>
                                <BasicInput type="text" placeholder="RFC" defaultValue={formData.RFC} setValue={(e)=> setFormData({...formData, RFC: e.target.value})}/>
                                <BasicInput type="text" placeholder="Situación Fiscal" defaultValue={formData.SituacionFiscal} setValue={(e)=> setFormData({...formData, SituacionFiscal: e.target.value})}/>
                            </section>
                            <section className="grid grid-cols-1 grid-rows-1 gap-x-2 gap-y-0.5">
                                <BasicInput type="text" placeholder="Breve descripcion de tu negocio" defaultValue={formData.Descripcion} setValue={(e)=> setFormData({...formData, Descripcion: e.target.value})}/>
                            </section>
                            <p className="text-sm text-gray-600 font-semibold">Dirección principal</p>
                            <section className="grid grid-cols-3 grid-rows-2 gap-x-2 gap-y-0.5 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Calle" defaultValue={formDataDireccion.Calle} setValue={(e)=> setFormDataDireccion({...formDataDireccion, Calle: e.target.value})}/>
                                <BasicInput type="text" placeholder="Numero Interior" defaultValue={formDataDireccion.NumeroInterior} setValue={(e)=> setFormDataDireccion({...formDataDireccion, NumeroInterior: e.target.value})}/>
                                <BasicInput type="text" placeholder="Numero Exterior" defaultValue={formDataDireccion.NumeroExterior} setValue={(e)=> setFormDataDireccion({...formDataDireccion, NumeroExterior: e.target.value})}/>
                                <BasicInput type="text" placeholder="Colonia" defaultValue={formDataDireccion.Colonia} setValue={(e)=> setFormDataDireccion({...formDataDireccion, Colonia: e.target.value})}/>
                                
                                <BasicInput type="select" placeholder="Estado"
                                        options={estados}
                                        defaultValue={formDataDireccion.Estado} 
                                        setValue={(e)=> seleccionaEstado(e.target.value)}/>
                                <BasicInput type="select" placeholder="Ciudad" 
                                    options={ciudades}
                                    defaultValue={formDataDireccion.CiudadRef} 
                                    setValue={(e)=> setFormDataDireccion({...formDataDireccion, CiudadRef: e.target.value})}/>
                                <BasicInput type="text" placeholder="Codigo Postal" defaultValue={formDataDireccion.CodigoPostal} setValue={(e)=> setFormDataDireccion({...formDataDireccion, CodigoPostal: e.target.value})}/>

                            </section>
                        </Step>
                        <Step save={handleSave}>
                            <p className="text-lg text-gray-500 font-semibold">Documentos</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, adjunta los siguientes documentos para poder comprobar la veracidad de tu negocio</p>
                            <div className='m-0 max-h-[250px] overflow-auto'>
                                <FileInput 
                                onFileSelectSuccess={(file) => setSelectedFileIFE(file)}
                                onFileSelectError={({ error }) => alert(error)}
                                amount={2}
                                filesText="Adjunta hasta 2 imagenes de tu INE"
                                />
                            </div>
                            
                            {
                                isCreating ?
                                <center>
                                    <p className="bg-white text-gray-700 py-0 m-0 rounded relative">
                                        <FaSpinner icon="spinner" className="text-primary-400 animate-spin text-3xl m-4" />
                                        Registro en proceso
                                    </p>
                                </center>
                                :
                                <></>
                            }
                        </Step>
                    </StepWizard>
            </Modal>
            <Modal show={modalDeleteRentero} setShow={setModalDeleteRentero} title="Rentero" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el rentero?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteRentero(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteRentero}/>
                </section>
            </Modal>
            <Modal show={modalDetalle} setShow={setModalDetalle} title={gridDetalle.title} className="max-w-5xl">
                <Datagrid 
                    header={gridDetalle.header}
                    data={gridDetalle.rows}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>

            <Modal show={modalDocumentos} setShow={setModalDocumentos} title="Documentos de Registro" className="max-w-xl">
                {
                    documentosRegistro !== undefined && documentosRegistro !== null && documentosRegistro.length > 0 ?
                        documentosRegistro.map(({nombre, url}) => {
                            return  <div className='text-base my-2 text-primary-300 grid grid-cols-12 gap-x-2 gap-y-1'>
                                <div className='col-span-1 text-center px-2 py-1'>
                                    <FaFileAlt />
                                </div>
                                <div className='col-span-11'>
                                    <a href={url} rel="noreferrer" target="_blank"><span> {nombre}</span></a>
                                </div>
                            </div>
                        })
                    :
                    'No hay documentos'
                }
            </Modal>

            <Modal show={modalPlanGratuito} setShow={setModalPlanGratuito} title="Asignación de plan gratuito" className="max-w-xl">
                {
                    infoPlanGratuito !== null && infoPlanGratuito !== undefined ?
                    <div>
                        {
                            infoPlanGratuito.esquemaId !== 1 && infoPlanGratuito.esquemaId !== 5 ?
                            <p className='text-red-400'>Actualmente el rentero <b>{infoPlanGratuito.nombreEmpresa}</b> cuenta con el plan <b>{infoPlanGratuito.esquema}</b>, por lo que no es necesario asignar plan gratuito</p>
                            :
                            <></>
                        }

                        {
                            infoPlanGratuito.esquemaId == 5 ?
                            <div>
                                <header className="w-full">
                                    <p>¿Seguro que deseas quitar el plan gratuito al rentero <b>{infoPlanGratuito.nombreEmpresa}</b>?</p>
                                </header>
                                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-gray-400" type="button" text="Cancelar" clickFn={cancelAsignarPlanGratuito}/>
                                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-400" type="button" text="Quitar" disabled={infoPlanGratuito.esquemaId !== 1 && infoPlanGratuito.esquemaId !== 5} clickFn={confirmRemoverPlanGratuito}/>
                                </section>
                            </div>
                            :
                            <div>
                                <header className="w-full">
                                    <p>¿Seguro que deseas asignar un plan gratuito al rentero <b>{infoPlanGratuito.nombreEmpresa}</b>?</p>
                                </header>
                                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-gray-400" type="button" text="Cancelar" clickFn={cancelAsignarPlanGratuito}/>
                                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-400" type="button" text="Asignar" disabled={infoPlanGratuito.esquemaId !== 1} clickFn={confirmAsignarPlanGratuito}/>
                                </section>
                            </div>
                        }
                    </div>
                    :
                    <></>
                }
            </Modal>
        </Container>
    )
}

export default Renteros;