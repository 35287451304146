import React, {useState, useEffect} from 'react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import Container from '../UI/Container';
import Card from '../UI/Approval/Card';
import CardPlan from '../UI/Card/CardPlan';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import { merent_instance } from '../helpers/axios_helper';
import { form_fields_renteros_perfil_empresa, form_fields_renteros_perfil_direccion, form_fileds_tipos_planes } from '../helpers/form_fields';

function Perfil() {
    const [informacionPerfil, setInformacionPerfil] = useState({});
    const [perfilFields, setPerfilFields] = useState({});
    const [perfilFieldsDireccion, setPerfilFieldsDireccion] = useState({});
    const [modalEditEsquema, setModalEditEsquema] = useState(false);
    const [modalRegistrarTarjeta, setModalRegistrarTarjeta] = useState(false);
    const [modalCancelarSuscripcion, setModalCancelarSuscripcion] = useState(false);
    const [modalEquipos, setModalEquipos] = useState(false);
    const [planActual, setPlanActual] = useState({});
    const [showLoading, setShowloading] = useState('');

    const [equiposRentaAbierta, setEquiposRentaAbierta] = useState([]);


    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    const [planes, setPlanes] = useState([]);

    useEffect(() => {
        cargaInformacionRentero();
        cargaEstados();
        cargaPlanes();
    }, []);

    const cargaInformacionRentero = () => {
        const usr_id = localStorage.getItem("usr_id");
        merent_instance().get(`/api/renteros/${usr_id}`).then(({data}) => {
            console.log(data);
            setInformacionPerfil(data);
            setPerfilFields({
                id: data.id,
                nombre: data.nombre,
                email: data.usuario.email,
                phoneNumber: data.usuario.phoneNumber,
                nombreEmpresa: data.nombreEmpresa,
                descripcion: data.descripcion,
                rfc: data.rfc,
            });

            setPerfilFieldsDireccion({
                id: data.direccion.id,
                calle: data.direccion.calle,
                ciudadRef: data.direccion.ciudadRef,
                colonia: data.direccion.colonia,
                codigoPostal: data.direccion.codigoPostal,
                nombre: data.direccion.nombre,
                numeroInterior: data.direccion.numeroInterior,
                numeroExterior: data.direccion.numeroExterior,
                estadoRef: data.direccion.ciudad.estadoRef,
                ciudadRef: data.direccion.ciudadRef,
                direccionCompleta: data.direccion.direccionCompleta
            });

            cargaCiudades(data.direccion.ciudad.estadoRef);

        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar la información de tu perfil, intenta nuevamente más tarde");
        });
    }

    const cargaEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const cargaCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const cargaPlanes = () => {
        merent_instance().get(`/api/renteros/planes`).then(({data}) => {
            setPlanes(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar la información de tu perfil, intenta nuevamente más tarde");
        });
    }

    const modificarPerfilDireccion = (estado_actual) => {
        setPerfilFieldsDireccion(estado_actual);
        if(estado_actual.estadoRef != 0) {
            cargaCiudades(estado_actual.estadoRef);
        }
    }

    const savePerfil = () => {
        toast.success("Cambios guardados");
    }

    const savePerfilDireccion = () => {
        merent_instance().put(`/api/direcciones/${perfilFieldsDireccion.id}`, perfilFieldsDireccion).then(({data}) => {
            console.log(data);
            toast.success("La dirección ha sido editada");
            cargaInformacionRentero();
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al actualizar su direccion, porfavor intente nuevamente más tarde");
        })
    }

    const seleccionaEsquema = (plan) => {
        setPlanActual(plan);
        setModalEditEsquema(true);
    }

    const confirmaCompraPlan = (item) => {
        const usr_id = localStorage.getItem("usr_id");

        const body = {
            "planRenteroRef": planActual.id,
            "renteroRef": usr_id
        };
        merent_instance().post(`/api/renteros/planes`, body).then(({data}) => {
            console.log(data);
            toast.success(data);
            setModalEditEsquema(false);
            cargaInformacionRentero();
        }).catch((error) => {
            console.log(error);
            if(error.response){
                if(error.response.status == 413){
                    toast.error(error.response.data)
                    setShowloading('Vinculando a conekta...');
                    //Mostrar modal para registrar tarjeta
                    setModalRegistrarTarjeta(true);
                    setTimeout(() => {generarFormularioTarjeta()}, "3000");
                }

                if(error.response.status == 438) {
                    toast.error(error.response.data);
                    console.log("Error por creditos disponibles");
                    setModalEditEsquema(false);
                    cargaEquiposRentaAbierta();
                }
                else{
                    toast.error(error.response.data);
                }
            }
            else
                toast.error("Hubo un error al contratar tu plan, por favor intenta más tarde");
        })
    }

    const generarFormularioTarjeta = () => {

        merent_instance().get(`/api/renteros/planes/token`).then(({data}) => {
            console.log(data);
            setShowloading('');
            window.ConektaCheckoutComponents.Card({
                targetIFrame: "#conektaIframeContainer", 
                allowTokenization: true, //Permite personalizar el tokenizador, por lo que su valor siempre será TRUE
                checkoutRequestId: data, //Checkout request ID, es el mismo ID generado en el paso 1
                publicKey: "key_M7DvGxvZeUhpQdVBOyThYHP", //Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
                options: {
                    styles: {
                        inputType: 'basic', //Modifica el diseño del Web Checkout Tokenizer: 'basic' // 'basic' | 'rounded' | 'line'
                        buttonType: 'basic', //Modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer:  'basic' // 'basic' | 'rounded' | 'sharp'        
                        states: { //Elemento que personaliza el borde de color de los elementos    
                            empty: {
                                borderColor: '#d4d6de' // Código de color hexadecimal para campos vacíos
                            },
                            invalid: {
                                borderColor: '#ff6666' // Código de color hexadecimal para campos inválidos
                            },
                            valid: {
                                borderColor: '#00b359' // Código de color hexadecimal para campos llenos y válidos
                            }
                        }
                    },
                    languaje: 'es', //Idioma: 'es' Español | 'en' Ingles
                    button: { //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
                        colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
                        text: 'Registrar Tarjeta', //Nombre de la acción en el botón ***Se puede personalizar
                        backgroundColor: '#FE6830' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
                    },
                    iframe: { //Elemento que personaliza el diseño del iframe
                        colorText: '#2D303A',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
                        backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
                    }
                },
                onCreateTokenSucceeded: function(token) {
                    console.log(token);
                    setShowloading('Registrando tarjeta...');
                    registrarTarjeta(token.id);
                },
                onCreateTokenError: function(error) {
                    toast.error("Hubo un error al registrar tu tarjeta, por favor intenta más tarde");
                }
            })
        }).catch((error) => {
            setShowloading('');
            setModalRegistrarTarjeta(false);
            console.log(error);
            if(error.response){
                toast.error(error.response.data);
            }
            else
                toast.error("Error al conectarse a conekta: Token no generado.");
        })
    }

    const registrarTarjeta = (token) => {
        const usr_id = localStorage.getItem("usr_id");

        const body = {
            "tokenId": token,
            "renteroRef": usr_id,
            "planRenteroRef": planActual.id
        };
        merent_instance().post(`/api/renteros/planes/registrar`, body).then(({data}) => {
            console.log(data);
            toast.success(data);
            setModalRegistrarTarjeta(false);
            setModalEditEsquema(false);
            cargaInformacionRentero();
        }).catch((error) => {
            console.log(error);
            if(error.response){
                toast.error(error.response.data);
            }
            else
                toast.error("Método de pago no válido, intenta con otro.");
        })
    }

    const confirmarCancelarSuscripcion = (token) => {
        const usr_id = localStorage.getItem("usr_id");

        const body = {
            "tokenId": token,
            "renteroRef": usr_id
        };
        merent_instance().post(`/api/renteros/planes/cancelar_suscripcion`, body).then(({data}) => {
            console.log(data);
            toast.success(data);
            setModalCancelarSuscripcion(false);
        }).catch((error) => {
            console.log(error);
            if(error.response){
                toast.error(error.response.data);
            }
            else
                toast.error("No es posible hacer la cancelación en este momento, intenta más tarde.");
        })
    }

    const cambiarCheck = (index) => {
        let equipos = [...equiposRentaAbierta];
        let equipo = {...equipos[index]};
        equipo.checked = !equipo.checked;
        equipos[index] = equipo;
        setEquiposRentaAbierta(equipos);
    }

    const cargaEquiposRentaAbierta = () => {
        const usr_id = localStorage.getItem("usr_id");
        merent_instance().get(`api/renteros/${usr_id}/equipos/abierta`).then(({data}) => {
            const data_final = data.map(x => {
                x.checked = true;
                return x;
            });

            console.log(data_final);
            setEquiposRentaAbierta(data_final);
            setModalEquipos(true);
        });
    }

    const confirmaCambioEquipos = () => {

    }

    return(
        <Container>
            <div className="p-3">
                <Card 
                    title={informacionPerfil.nombreEmpresa} 
                    subTitle={informacionPerfil.direccion ? informacionPerfil.direccion.direccionCompleta : ""} 
                    description={informacionPerfil.descripcion}
                    >
                    <section className="w-full p-2">
                    <Disclosure c>
                        {({open}) => (
                            <>
                                <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                    <span>Datos de la empresa</span>
                                    <FaChevronDown className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-primary-500`}/>
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                    <Form 
                                        formFields={form_fields_renteros_perfil_empresa} 
                                        formState={perfilFields} 
                                        formModifyState={setPerfilFields}
                                        formSave={savePerfil}
                                    />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    <Disclosure c>
                        {({open}) => (
                            <>
                                <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                    <span>Datos de la direccion</span>
                                    <FaChevronDown className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-primary-500`}/>
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                    <Form 
                                        formFields={form_fields_renteros_perfil_direccion(estados, ciudades)} 
                                        formState={perfilFieldsDireccion} 
                                        formModifyState={modificarPerfilDireccion}
                                        formSave={savePerfilDireccion}
                                    />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    </section>
                    <p className="text-2xl mt-5 mx-5 text-secondary-500">
                        Esquema
                    </p>
                    <div className='m-5 text-secondary-300'>
                        <p>Consige aquí créditos para poder registrar tus equipos con el esquema de Renta Abierta, cada equipo te consume un crédito y las rentas de estos no generarán comisión, aunque se hará el seguimiento</p>
                        <h2 className='mt-3 text-lg text-center'>Tu plan contratado actualmente es: <strong className='text-primary-400'>{informacionPerfil.planRentero ? informacionPerfil.planRentero.nombrePlan : ""}</strong></h2>
                        <h3 className='mt-3 text-lg text-center'>Tienes <strong className='text-primary-400'>{informacionPerfil.creditosDisponibles}/{informacionPerfil.planRentero ? informacionPerfil.planRentero.cantidadEquipos : ""}</strong> créditos/equipos disponibles</h3>
                        <p className='text-center'>Mejora tu plan de suscripción para obtener más créditos y poder guardar más equipos para Renta Abierta.</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 px-4 gap-x-4 gap-y-1">
                        {
                            planes.map((plan) => {
                                return (
                                    <CardPlan 
                                        title={plan.nombrePlan} 
                                        costo={plan.costo} 
                                        cantidad={plan.cantidadEquipos} 
                                        highlight={informacionPerfil.planRenteroRef == plan.id} 
                                        onClick={ () => { seleccionaEsquema(plan) } }
                                    />
                                )
                            })
                        }
                    {/*                         
                        {
                            informacionPerfil.planRenteroRef !== esquema.id ? 
                                <BasicButton className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3" type="button" text="Cambiar Esquema de Renta" clickFn={editEsquema}/>
                            :
                                <BasicButton className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3" type="button" text="Cambiar Esquema de Renta" clickFn={editEsquema} disabled={true}/>
                        } */}
                    </div>
                    <section className="grid grid-cols-1 gap-x-8 gap-y-1 mb-5">
                        <BasicButton className="bg-secondary-100" type="button" text="Cancelar mi suscripción" clickFn={() => {setModalCancelarSuscripcion(true)}}/>
                    </section>
                </Card>
            </div>

            <Modal show={modalEditEsquema} setShow={setModalEditEsquema} title="Cambiar esquema" className="max-w-5xl">
                <header className="w-full text-center">
                    <p>¿Seguro que deseas cambiar tu esquema a <strong className="text-primary-400">'{planActual ? planActual.nombrePlan : ""}'</strong>?<br/> Esto te dará un total de <strong className='text-primary-400'>{planActual ? planActual.cantidadEquipos : 0}</strong> creditos para utilizar en el esquema de renta abierta</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalEditEsquema(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Sí, cambiar mi esquema" clickFn={confirmaCompraPlan}/>
                </section>
            </Modal>
            <Modal show={modalEquipos} setShow={setModalEquipos} title="Selecciona los equipos que cambiarán de esquema para continuar" className="max-w-5xl">
                <header className="w-full text-center">
                    <p>El plan <strong className="text-primary-400">'{planActual ? planActual.nombrePlan : ""}'</strong> tiene <strong className='text-primary-400'>{planActual ? planActual.cantidadEquipos : 0}</strong> creditos</p>
                </header>
                <section className='gird grid-cols-1 my-4'>
                    {
                        equiposRentaAbierta.map((equipo, idx) => {
                            return (
                                <div className="flex flex-row justify-between">
                                    <span className="text-primary-400 font-bold">{equipo.nombre} <span className="text-xs font-semibold text-primary-300">({equipo.categoriaEquipo.nombre})</span></span> 
                                    <input 
                                        type="checkbox" 
                                        key={"key_"+idx}
                                        checked={equipo.checked}
                                        onChange={() => cambiarCheck(idx)}
                                        />
                                </div>
                            )
                        })
                    }
                </section>
                <footer className="w-full text-center">
                    <p className="">Necesitas deseleccionar <strong className='text-primary-400'>{equiposRentaAbierta.filter(x => x.checked).length}</strong> equipo(s) más para continuar</p>
                </footer>
                
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalEquipos(false)}}/>
                    <BasicButton 
                        className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" 
                        type="button" 
                        text="Confirmar selección" 
                        clickFn={confirmaCambioEquipos}
                        disabled={equiposRentaAbierta.filter(x => x.checked).length != 0}/>
                </section>
            </Modal>
            <Modal show={modalRegistrarTarjeta} setShow={setModalRegistrarTarjeta} title="Registra tu tarjeta" className="max-w-3xl">
                <header className="w-full text-center">
                    <p>Parece ser que no has registrado ninguna tarjeta a la cual podamos cargar los pagos de tu suscripción. <br/> Favor de registrar una tarjeta para hacer el cambio al plan <strong className="text-primary-400">'{planActual ? planActual.nombrePlan : ""}'</strong></p>
                    {
                        showLoading !== "" ? 
                        <p>{showLoading}</p>
                        :
                        ''
                    }
                    <div id="conektaIframeContainer" className="flex w-full items-center justify-center h-[30rem]">
                        
                    </div>
                </header>
            </Modal>
            <Modal show={modalCancelarSuscripcion} setShow={setModalCancelarSuscripcion} title="Cancelar suscripción" className="max-w-2xl">
                <header className="w-full text-center">
                    <p>¿Seguro que deseas cancelar tu suscripción en MeRent?<br/> Se perderán tus créditos disponibles al terminar el periodo actual.</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalEditEsquema(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Sí, cancelar mi suscripción" clickFn={confirmarCancelarSuscripcion}/>
                </section>
            </Modal>
        </Container>
    )
}

export default Perfil;