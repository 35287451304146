import React from 'react';

const Card = ({text, icon, qty}) => {
    return(
        <div className="h-30 flex-col text-2xl rounded-xl bg-white my-4 shadow-lg">
            <div className="flex justify-center text-center text-5xl">
                {icon}
            </div>
            <div className="flex justify-center font-semibold">
                {text}
            </div>
            <div className="flex justify-center bg-gray-100 rounded-b-xl py-2">
                {qty}
            </div>
        </div>
        
    )
}

export default Card;