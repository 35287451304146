import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import BasicInput from '../UI/Inputs/BasicInput';
import BasicButton from '../UI/Buttons/BasicButton';
import Logo from '../../assets/merent-logo.png';

import { merent_instance } from '../helpers/axios_helper';

import { RegExpEmail, RegExpPassword } from '../helpers/constants';

function Login() {
    const [in_email, setIn_Email] = useState("");
    const [in_token, setIn_Token] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    let { email, token } = useParams();
    const navigation = useNavigate();

    useEffect(() => {
        setIn_Email(email);
        setIn_Token(token);
    }, []);
    
    const sendRecover = () => {
        console.log(password)
        console.log(RegExpPassword)
        if(password == ''){
            toast.error("Ingresa tu nueva contraseña")
            return;
        }
        if(!RegExpPassword.test(password)){
            toast.error("La contraseña debe tener 8 caracteres, una minuscula, una mayuscula y un numero");
            return;
        }

        if(password != confirmPassword){
            toast.error("La contraseña no coincide")
            return;
        }
        const obj = {
            email: in_email,
            token: in_token,
            password: password,
            confirmaPassword: confirmPassword
        }
        merent_instance().post("restaurar", obj).then(({data}) => {
            toast.success(data);
            setTimeout(() => {
                navigation("/");
            }, 3000);
        }).catch((error) => {
            console.log(error);
            toast.error("El token no es valido, redireccionando a sitio principal");
            setTimeout(() => {
                navigation("/");
            }, 3000);
        })
    }

    return(
        <>
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-construction-pattern bg-contain bg-origin-content drop-shadow-sm ">
            <div className="relative h-[35rem] mx-5 md:w-1/2 lg:w-1/3 xl:w-1/3 xs:w-full sm:w-full">
                <div className="absolute w-full h-full bg-white flex justify-center items-center rounded-lg border-small border-primary-200 shadow-md shadow-primary-100">
                    <form className="flex items-center content-center justify-center flex-col px-10 w-full h-full text-center">
                        <img src={Logo} alt="Logo" className="h-28"/>
                        <h1>¡Ingresa los siguientes datos para recuperar tu contraseña!</h1>
                        <BasicInput type="email" placeholder="Correo" defaultValue={in_email} setValue={(e) => setIn_Email(e.target.value)}/>
                        <BasicInput type="password" placeholder="Contraseña" defaultValue={password} setValue={(e) => setPassword(e.target.value)}/>
                        <BasicInput type="password" placeholder="Confirma tu contraseña" defaultValue={confirmPassword} setValue={(e) => setConfirmPassword(e.target.value)}/>

                        <BasicButton type="button" clickFn={sendRecover} text={"Restaurar contraseña"}/>
                    </form>
                </div>
            </div>
        </div> 
        </>
    );
}

export default Login;