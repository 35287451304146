import React from 'react';

const Nav = (props) => {
    const dots = [];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        dots.push((
            <span
                key={`step-${i}`}
                className={`cursor-pointer mb-2 text-4xl  ${isActive ? 'text-primary-400 opacity-100 shadow-primary-200' : 'text-black opacity-40 shadow-none'}`}
                //onClick={() => props.goToStep(i)}
            >&bull;</span>
        ));
    }

    return (
        <div className="mb-10 text-center">{dots}</div>
    );
};

export default Nav;