import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import ListIcon from '@rsuite/icons/List';
import { FaTruckMoving, FaFileSignature, FaCalendarPlus, FaCalendarCheck, FaListUl } from 'react-icons/fa';
import Datagrid from '../UI/Datagrid'
import Container from '../UI/Container';
import Modal from '../UI/Modal';
import BasicInput from '../UI/Inputs/BasicInput';
import BasicButton from '../UI/Buttons/BasicButton';
import GridButton from '../UI/Buttons/GridButton';
import { merent_instance } from '../helpers/axios_helper';
import { grid_columns_rentas, grid_columns_rentas_detalle } from '../helpers/grid_columns';
import toast from 'react-hot-toast';
import Form from '../UI/Form';
import { form_fields_contrato } from '../helpers/form_fields';

function Rentas() {
    const [modalDetalle, setModalDetalle] = useState(false);
    const [toggled, setToggled, profile] = useOutletContext();
    const [rentas, setRentas] = useState([]);
    const [detalleActual, setDetalleActual] = useState([]);
    const [rentaActual, setRentaActual] = useState(null);
    const [precioFlete, setPrecioFlete] = useState("");
    const [modalAceptarRenta, setModalAceptarRenta] = useState(false);
    const [modalPrecioFlete, setModalPrecioFlete] = useState(false);

    const fechaActual = new Date().toLocaleDateString("en-CA");
    const oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
    ).toLocaleDateString("en-CA");
    
    const [filters, setFilters] = useState({
        fechaInicio: oneMonthAgo,
        fechaFin: fechaActual
    })

    const [modalContrato, setModalContrato] = useState(false);
    const [contratoFirmado, setContratoFirmado] = useState("");

    const [modalExtenderRenta, setModalExtenderRenta] = useState(false);

    const [precioOriginal, setPrecioOriginal] = useState(0);
    const [modalAlertaCambioPrecio, setModalAlertaCambioPrecio] = useState(false);
    
    const [optionsFilter, setOptionsFilter] = useState([
        {value: 'Programadas', label: 'Programadas'},
        {value: 'Pendientes', label: 'Pendientes'},
        {value: 'Historial', label: 'Historial'}
    ]);

    const contratoBody = {
        "nombreArrendador": "",
        "apoderadoLegalArrendador": "",
        "polizaConstitucionArrendadora": "",
        "polizaFacultadesArrendadora": "",
        "nacionalidadArrendador": "",
        "lugarNacimientoArrendador": "",
        "fechaNacimientoArrendador": "",
        "curpArrendador": "",
        "rfcArrendador": "",
        "estadoCivilArrendador": "",
        "domicilioArrendador": "",
        "correoElectronicoArrendador": "",
        "clienteRef": 0,
        "cliente": null,
        "nombreArrendatario": "",
        "apoderadoLegalArrendatario": "",
        "polizaConstitucionArrendataria": "",
        "polizaFacultadesApoderado": "",
        "nacionalidadArrendatario": "",
        "lugarNacimientoArrendatario": "",
        "fechaNacimientoArrendatario": "",
        "curpArrendatario": "",
        "rfcArrendatario": "",
        "estadoCivilArrendatario": "",
        "domicilioArrendatario": "",
        "correoElectronicoArrendatario": "",
        "renteroRef": 0,
        "rentero": null,
        "importeRenta": 0,
        "periodicidad": "",
        "vigenciaRenta": "",
        "fechaPago": ""
    };

    const [contratoFields, setContratoFields] = useState(contratoBody);

    useEffect(() => {
        /* if(profile === 1){
            setOptionsFilter([{value: 'Historial', label: 'Historial'}]);
            setFilterRenta("Historial");
        } */
        cargarRentas();
    }, [])

    useEffect(() => {
        if(modalContrato == false)
            setContratoFirmado("");
    }, [modalContrato])
    
    const cargarRentas = () => {
        const usr_id = localStorage.getItem("usr_id");
        const usr_role = localStorage.getItem("usr_role");

        if(usr_role === "Rentero")
            merent_instance().get(`api/rentas/rentero/${usr_id}?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                console.log(data);
                setRentas(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
        if(usr_role === "Admin")
            merent_instance().get(`api/rentas/admin?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                setRentas(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
    }

    const showDetail = (data) => {
        console.log(data.equipos);
        setDetalleActual(data.equipos);
        setModalDetalle(true);
    }

    const aceptarSolicitudRenta = (data) => {
        if(data.estadoRentaRef !== 1){
            toast.error("La solicitud de renta no se encuentra pendiente de aceptación");
            return;
        }
        setRentaActual(data);
        setModalAceptarRenta(true);
    }

    const confirmarSolicitudRenta = (flag) => {
        console.log(rentaActual);
        if(flag) {
            //Aceptar renta
            merent_instance().patch(`/api/rentas/${rentaActual.id}/1`)
            .then(({data}) => {
                toast.success("Renta aceptada");
                setModalAceptarRenta(false);
                cargarRentas();
            })
            .catch((error) => {
                console.log(error);
                toast.error("Hubo un error al aceptar la renta, favor de intentar nuevamente más tarde");
            })
        } else {
            //Rechazar renta
            merent_instance().patch(`/api/rentas/${rentaActual.id}/8`)
            .then(({data}) => {
                toast.success("Renta aceptada");
                setModalAceptarRenta(false);
                cargarRentas();
            })
            .catch((error) => {
                console.log(error);
                toast.error("Hubo un error al aceptar la renta, favor de intentar nuevamente más tarde");
            })
        }
    }

    const asignarPrecioFlete = (data) => {
        if(data.estadoRentaRef !== 2){
            toast.error("La solicitud de renta no se encuentra pendiente por precio de flete");
            return;
        }
        setRentaActual(data);
        setModalPrecioFlete(true);
    }

    const confirmarPrecioFlete = () => {
        if(precioFlete == 0 && precioFlete == "") {
            toast.error("Precio invalido");
            return;
        } 

        merent_instance().patch(`/api/rentas/${rentaActual.id}/precioflete/${parseFloat(precioFlete).toFixed(2)}`)
        .then(({data}) => {
            merent_instance().patch(`/api/rentas/${rentaActual.id}/2`)
            .then(({data}) => {
                toast.success(data);
                setModalPrecioFlete(false);
                cargarRentas();
                setPrecioFlete("");
            })
            .catch((error) => {
                console.log(error);
                toast.error("Error al asignar precio de flete ida y vuelta, intenta nuevamente más tarde");
            })
        })
        .catch((error) => {
            console.log(error);
            toast.error("Error al asignar precio de flete ida y vuelta, intenta nuevamente más tarde");
        })

    }
    
    const administrarFirmaContrato = (data) => {
        if(data.estadoRentaRef !== 4){
            toast.error("La solicitud de renta no se encuentra pendiente por firma de contrato");
            return;
        }
        setRentaActual(data);

        merent_instance().get(`api/informacionlegal/${data.id}`).then(({data}) => {

            const data_renta = {...data.info_cliente, ...data.info_rentero, ...data.info_renta};
            setPrecioOriginal(data.info_renta.importeRenta);

            setContratoFields(data_renta);
            setModalContrato(true);
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }

    const confirmarDocumentosFirmados = () => {
        if(contratoFirmado == null){
            toast.error("Adjunta el documento firmado para poder continuar");
            return;
        }

        merent_instance().patch(`/api/rentas/${rentaActual.id}/4`)
        .then(({data}) => {
            toast.success(data);
            setModalContrato(false);
            setContratoFirmado("");
            cargarRentas();

        })
        .catch((error) => {
            console.log(error);
            toast.error("Ha habido un error al adjuntar el documento, intenta nuevamente más tarde");
        })
    }

    const extenderFechasRenta = (data) => {
        setRentaActual(data);
        setModalExtenderRenta(false);
    }

    const options_dropdown_rentero = [
        {
            label: 'Ver equipos de la renta',
            icon: <FaListUl/>,
            action: showDetail
        }
    ];
    const options_dropdown_admin = [
        {
            label: 'Ver equipos de la renta',
            icon: <FaListUl/>,
            action: showDetail
        },
        {
            label: 'Asignar precio de flete de ida y vuelta',
            icon: <FaTruckMoving/>,
            action: asignarPrecioFlete
        },
        {
            label: 'Administrar firma de contrato',
            icon: <FaFileSignature/>,
            action: administrarFirmaContrato
        },
        {
            label: 'Extender fechas de renta',
            icon: <FaCalendarPlus/>,
            action: extenderFechasRenta
        },
    ];

    const filtrar = () => {
        console.log(filters);
    }

    const validateContratoFields = () => {
        if(precioOriginal != contratoFields.importeRenta){
            setModalAlertaCambioPrecio(true);
        }
        else{
            saveContratoFields();
        }
    }
    
    const saveContratoFields = () => {
        
        var obj_to_save = {'info_cliente': {}, 'info_rentero': {}, 'info_renta': {}, };
        obj_to_save['info_cliente'] = {
            "nombreArrendatario": contratoFields.nombreArrendatario,
            "apoderadoLegalArrendatario": contratoFields.apoderadoLegalArrendatario,
            "polizaConstitucionArrendataria": contratoFields.polizaConstitucionArrendataria,
            "polizaFacultadesApoderado": contratoFields.polizaFacultadesApoderado,
            "nacionalidadArrendatario": contratoFields.nacionalidadArrendatario,
            "lugarNacimientoArrendatario": contratoFields.lugarNacimientoArrendatario,
            "fechaNacimientoArrendatario": contratoFields.fechaNacimientoArrendatario,
            "curpArrendatario": contratoFields.curpArrendatario,
            "rfcArrendatario": contratoFields.rfcArrendatario,
            "estadoCivilArrendatario": contratoFields.estadoCivilArrendatario,
            "domicilioArrendatario": contratoFields.domicilioArrendatario,
            "correoElectronicoArrendatario": contratoFields.correoElectronicoArrendatario
        };

        obj_to_save['info_rentero'] = {
            "nombreArrendador": contratoFields.nombreArrendador,
            "apoderadoLegalArrendador": contratoFields.apoderadoLegalArrendador,
            "polizaConstitucionArrendadora": contratoFields.polizaConstitucionArrendadora,
            "polizaFacultadesArrendadora": contratoFields.polizaFacultadesArrendadora,
            "nacionalidadArrendador": contratoFields.nacionalidadArrendador,
            "lugarNacimientoArrendador": contratoFields.lugarNacimientoArrendador,
            "fechaNacimientoArrendador": contratoFields.fechaNacimientoArrendador,
            "curpArrendador": contratoFields.curpArrendador,
            "rfcArrendador": contratoFields.rfcArrendador,
            "estadoCivilArrendador": contratoFields.estadoCivilArrendador,
            "domicilioArrendador": contratoFields.domicilioArrendador,
            "correoElectronicoArrendador": contratoFields.correoElectronicoArrendador
        };

        obj_to_save['info_renta'] = {
            "importeRenta": contratoFields.importeRenta,
            "periodicidad": contratoFields.periodicidad,
            "vigenciaRenta": contratoFields.vigenciaRenta,
            "fechaPago": contratoFields.fechaPago
        };
        console.log(obj_to_save);
        merent_instance().put(`/api/informacionlegal/${rentaActual.id}`, obj_to_save).then(({data}) => {
            console.log(data);
            toast.success("Los datos de contrato han sido guardados con éxito");
            descargarContrato(data);
            setModalAlertaCambioPrecio(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al actualizar los datos de contrato, porfavor intente nuevamente más tarde");
        })
    };

    const descargarContrato = (url) => {
        window.open(url, "_blank", "noreferrer");

    }

    return (
        <Container>
            {/* <header className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-8 xl:grid-cols-6 gap-x-8 p-2">
                <p className="col-span-1 lg:col-span-2 xl:col-span-1 text-3xl font-semibold my-3 text-center">
                    Rentas
                </p>
                <section className="col-span-1 lg:col-span-5 xl:col-span-4 rounded-lg bg-secondary-50 opacity-70 flex justify-center align-center">
                    <BasicInput type="select" defaultValue={filterRenta} setValue={(e)=>{setFilterRenta(e.target.value)}} options={optionsFilter}/>
                    {
                        filterRenta !== undefined && filterRenta !== null && filterRenta === "Historial" ? 
                            <>
                                <BasicInput type="date" placeholder="Fecha inicio" defaultValue={fechaInicio} setValue={(e) => setFechaInicio(e.target.value)}/>
                                <BasicInput type="date" placeholder="Fecha fin" defaultValue={fechaFin} setValue={(e) => setFechaFin(e.target.value)}/>
                            </>
                        :
                            ''
                    }
                    <GridButton className="bg-secondary-200 m-2" text="Filtrar" clickFn={filtrar}/>
                </section>
            </header> */}
            <Datagrid 
                header={grid_columns_rentas(showDetail)}
                data={rentas}
                height={520}
                options_dropdown={localStorage.getItem("usr_role") == "Admin" ? options_dropdown_admin : options_dropdown_rentero}
                headerHeight={60}
                showFilters={true}
                filters={{...filters, changeFechaInicio: (val) => setFilters({...filters, fechaInicio: val}), changeFechaFin: (val) => setFilters({...filters, fechaFin: val}), applyFilter: () => cargarRentas()}}
                title="Rentas"
            />
            <Modal show={modalDetalle} setShow={setModalDetalle} title="Detalle de equipos" className="max-w-5xl">
                <Datagrid 
                    header={grid_columns_rentas_detalle}
                    data={detalleActual}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>
            <Modal show={modalAceptarRenta} setShow={setModalAceptarRenta} title="¿Quieres aceptar esta renta?" className="max-w-5xl">
                <p className="text-md text-secondary mb-2 text-center">
                    Al dar click en <b className="text-primary-200">"Si, acepto renta"</b> aceptas la solicitud y se iniciará el proceso por lo qué se bloquearán los días del equipo en el calendario.
                </p>
                <p className="text-sm text-secondary mb-2 text-center">
                    Al dar click en <b className="text-primary-200">"No, cancelar solicitud de renta"</b> se cancelará la solicitud, y se dará aviso al cliente que no se llevará acabo la renta de su equipo.
                </p>
                <div className="grid grid-cols-2 grid-rows-1 gap-16">
                    <BasicButton clickFn={() => confirmarSolicitudRenta(false)} type="button" className="bg-secondary-300" text="No, cancelar solicitud de renta"/>
                    <BasicButton clickFn={() => confirmarSolicitudRenta(true)} type="button" text="Si, acepto renta"/>
                </div>
            </Modal>
            <Modal show={modalPrecioFlete} setShow={setModalPrecioFlete} title="Agregar precio de flete ida y vuelta" className="max-w-5xl">
                <p className="text-sm text-secondary mb-1">
                    Dirección del cliente: {rentaActual !== null ? rentaActual.direccionEntrega : ''}
                </p>
                <p className="text-sm  border-b-2 mb-1">
                    <a href={`https://www.google.com/maps/search/?api=1&query=${rentaActual !== null ? encodeURIComponent(rentaActual.direccionEntrega) : ''}`} target="_blank" rel="noopener noreferrer">Ver en google maps</a>
                </p>

                <p className="text-sm mb-1">
                    Dirección del rentero: {rentaActual !== null ? rentaActual.direccionRentero : ''}
                </p>      
                <p className="text-sm border-b-2 mb-1">
                    <a href={`https://www.google.com/maps/search/?api=1&query=${rentaActual !== null ? encodeURIComponent(rentaActual.direccionRentero) : ''}`}  target="_blank" rel="noopener noreferrer">Ver en google maps</a>
                </p>
                
                <div className="flex flex-col text-center w-full">
                    <span>Precio de flete: </span>
                    <BasicInput type="number" placeholder="Precio de flete ida y vuelta" defaultValue={precioFlete} setValue={(e) => setPrecioFlete(e.target.value)}/>
                </div>
                <BasicButton clickFn={confirmarPrecioFlete} type="button" text="Mandar precio de flete ida y vuelta al cliente"/>
            </Modal>
            <Modal show={modalContrato} setShow={setModalContrato} title="Administración de firma de contrato" className="max-w-6xl">
                <p className="text-md text-secondary mb-4 text-center">
                    Esta renta se encuentra pendiente de confirmación por firma de contratos.
                    <br/>
                    Continua el flujo en la aplicación de firmas y una ves tengas el documento firmado adjuntalo aquí para poder continuar con el proceso.
                </p>
                <Form 
                    formFields={form_fields_contrato} 
                    formState={contratoFields} 
                    formModifyState={setContratoFields}
                    formSave={validateContratoFields}
                    hidePlaceHolders={true}
                    buttonText="Descargar contrato"
                    separarSeccionPorObjetos={true}
                ></Form>
                {/* <p className="text-md text-secondary mb-2 text-center">
                    Puedes descargar el documento <a className="text-blue-400 underline" href="https://drive.google.com/uc?export=download&id=1iPcFbKNxwePT8ODy4FfNsq2PNQl0o3Nx" target="_blank" rel="noopener noreferrer">aquí</a>.
                </p> */}
                <div className="grid grid-cols-1 grid-rows-2 ">
                    <BasicInput type="file" accept="application/pdf" maxFilesQty={1} placeholder="Documento firmado" defaultValue={contratoFirmado} setValue={(e) => setContratoFirmado(e.target.value)}/>
                    <BasicButton clickFn={confirmarDocumentosFirmados} type="button" text="Confirmar Recepcion de documentos firmados"/>
                </div>
            </Modal>
            <Modal show={modalExtenderRenta} setShow={setModalExtenderRenta} title="Extensión de renta" className="max-w-5xl">
                <p className="text-md text-secondary mb-2 text-center">
                    Al dar click en <b className="text-primary-200">"Si, acepto renta"</b> aceptas la solicitud y se iniciará el proceso por lo qué se bloquearán los días del equipo en el calendario.
                </p>
                <p className="text-sm text-secondary mb-2 text-center">
                    Al dar click en <b className="text-primary-200">"No, cancelar solicitud de renta"</b> se cancelará la solicitud, y se dará aviso al cliente que no se llevará acabo la renta de su equipo.
                </p>
                <div className="grid grid-cols-2 grid-rows-1 gap-16">
                    <BasicButton clickFn={() => confirmarSolicitudRenta(false)} type="button" className="bg-secondary-300" text="No, cancelar solicitud de renta"/>
                    <BasicButton clickFn={() => confirmarSolicitudRenta(true)} type="button" text="Si, acepto renta"/>
                </div>
            </Modal>
            <Modal show={modalAlertaCambioPrecio} setShow={setModalAlertaCambioPrecio} title="Cambio de importe" className="max-w-lg">
                <p className="text-base text-secondary-500 mb-2 text-center">
                    <b>El importe total de la renta va a cambiar </b> <br/>
                    <b className="text-primary-200">Precio anterior: </b> $ {precioOriginal} MXN <br/>
                    <b className="text-primary-200">Precio nuevo: </b> $ {contratoFields.importeRenta} MXN
                </p>
                <p className="text-sm text-secondary-300 mb-2 text-center">
                    * Incluye el precio de flete ida y vuelta en el costo
                </p>
                <p className="text-base text-secondary-500 mb-2 text-center">
                    ¿Estás seguro que deseas continuar?
                </p>
                <div className="grid grid-cols-2 grid-rows-1 gap-16">
                    <BasicButton clickFn={() => setModalAlertaCambioPrecio(false)} type="button" className="bg-secondary-300" text="Cancelar"/>
                    <BasicButton clickFn={() => saveContratoFields(true)} type="button" text="Continuar"/>
                </div>
            </Modal>
        </Container>
    )
}

export default Rentas;