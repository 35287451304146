import React from 'react';
import { useOutletContext } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import Logo from '../../../assets/merent-logo.png'

function ToggleButton() {
    const [toggled, setToggled] = useOutletContext();
    return(
        <button className={`flex items-center justify-start w-full h-8 bg-white text-primary-700 text-center rounded-b-md pl-2 cursor-pointer text-base visible md:hidden`}
                onClick={() => setToggled(true)}>
            <FaBars />
            <span className="flex items-center justify-center w-full text-center "><img src={Logo} className="h-24 " alt="Logo"/></span>
        </button>
    )
}

export default ToggleButton;