import React from 'react';
import { MdCardMembership, MdTurnedIn, MdVerifiedUser, MdVerified } from "react-icons/md";

const CardPlanOperador = ({title, costo, highlight, onClick}) => {
    return(
        <div onClick={onClick} className={`cursor-pointer h-30 flex-col text-2xl rounded-xl bg-gray-100 my-4 shadow-lg ${highlight ? 'border-4 border-primary-100' : ''}`}>
            <div className="flex justify-center text-center text-5xl mt-5 bg-gray-100">
                <MdTurnedIn/>
            </div>
            <div className="flex justify-center font-semibold bg-gray-100">
                {title}
            </div>
            <div className="flex justify-center bg-primary-300 text-white text-2xl font-bold py-2">
                <div>
                    <p className="text-center text-white font-bold text-xlg">${costo}.00</p>
                    <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
                </div>
            </div>
            <div className="flex justify-center  bg-gray-100 text-base rounded-b-xl p-3">
                <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
                    <li>Promociona tus servicios como operador</li>
                    <li>Administra tus habilidades</li>
                    <li>Administra tus intenciones de contacto</li>
                    <li>Trato directo con el cliente</li>
                </ul>
            </div>
        </div>
        
    )
}

export default CardPlanOperador;