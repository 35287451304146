import React, {useState, useEffect} from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import TagAuthorize from '@rsuite/icons/TagAuthorize';
import Container from '../UI/Container';
import Datagrid from '../UI/Datagrid';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { grid_columns_operadores } from '../helpers/grid_columns';
import { form_fields_operadores_admin } from '../helpers/form_fields';

import { merent_instance } from '../helpers/axios_helper';

function Operadores() {
    const [modalEditOperador, setModalEditOperador] = useState(false);
    const [modalDeleteOperador, setModalDeleteOperador] = useState(false);
    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    const [operadorFields, setOperadorFields] = useState({
        id: 0,
        nombresOperador: '',
        apellidosOperador: '',
        correoElectronico: '',
        telefono: '',
        estadoRef: '',
        ciudadRef: '',
    });
    const [operadores, setOperadores] = useState([]);

    useEffect(() => {
        cargaOperadores();
        cargaEstados();
    }, []);

    const cargaOperadores = () => {
        merent_instance().get("/api/operador").then(({data}) => {
            setOperadores(data);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al consultar la información");
        })
    }

    const cargaEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const cargaCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const newOperador = () => {
        setOperadorFields({
            id: 0,
            nombresOperador: '',
            apellidosOperador: '',
            correoElectronico: '',
            telefono: '',
            estado: '',
            ciudad: '',
        });
        setModalEditOperador(true);
    }

    const editOperador = (item) => {
        console.log(item);
        cargaCiudades(item.estadoRef);
        setOperadorFields(item);
        setModalEditOperador(true)
    }

    const deleteOperador = (item) => {
        setOperadorFields(item);
        setModalDeleteOperador(true)
    }

    const confirmDeleteOperador = (item) => {
        toast.success("Operador Eliminado");
        setOperadorFields({
            id: 0,
            nombresOperador: '',
            apellidosOperador: '',
            correoElectronico: '',
            telefono: '',
            estado: '',
            ciudad: '',
        });
        setModalDeleteOperador(false);
    }
    
    const saveOperador = () => {
        const informacion_operador = {
            nombre: operadorFields.nombresOperador,
            apellidos: operadorFields.apellidosOperador,
            email: operadorFields.correoElectronico,
            password: "Operador2023",
            telefono: operadorFields.telefono,
            categoriaOperador: 1,
            descripcion: "Ingrese aquí su descripción",
            estado: operadorFields.estadoRef,
            ciudad: operadorFields.ciudadRef,
            imagenPerfil: 'aaa'
        }

        if(operadorFields.id === 0){
            merent_instance().post("api/operador", informacion_operador).then(({data, status}) => {
                saveImageOperador(data);
            }).catch(({response}) => {
                toast.error("Hubo un error con su registro, intente nuevamente más tarde");
            });
        } else {

        }
    }

    const saveImageOperador = (user_id) => {
        let form_data = new FormData();
        form_data.append("imagen", operadorFields.imagenPerfil[0]);
        merent_instance().post(`api/usuarios/${user_id}/imagen`,form_data, {"Content-Type": "multipart/form-data",})
        .then(({data}) => {
            toast.success("Registro exitoso");
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar su imagen de perfil, guardala en tu perfil más adelante");
        }).finally(() => {
            setOperadorFields({
                id: 0,
                nombresOperador: '',
                apellidosOperador: '',
                correoElectronico: '',
                telefono: '',
                estado: '',
                ciudad: '',
            });
            setModalEditOperador(false);
        });
    }

    const solicitarPago = (item) => {
        console.log(item.id);

        merent_instance().get(`api/operador/${item.id}/solicitarpago`)
        .then(({data}) => {
            toast.success("Se va a solicitar el pago de su membresía la próxima ves que entre a su perfil");
        }).catch(({response}) => {
            toast.error(response.data);
        }).finally(() => {
            cargaOperadores();
        });
    }

    const options_dropdown = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editOperador
        },
        {
            label: 'Empezar a solicitar pago',
            icon: <TagAuthorize/>,
            action: solicitarPago
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteOperador
        }
    ];

    const modificarEstado = (estado) => {
        setOperadorFields(estado);
        if(estado.estadoRef != 0)
            cargaCiudades(estado.estadoRef);
    }

    return (
        <Container>
            <Datagrid 
                title="Operadores"
                header={grid_columns_operadores(editOperador)}
                data={operadores}
                height={500}
                headerHeight={40}
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar nuevo" type="button" clickFn={newOperador}/>}
            />
            <Modal show={modalEditOperador} setShow={setModalEditOperador} title="Operador" className="max-w-5xl">
                <Form 
                    formFields={form_fields_operadores_admin(estados, ciudades)} 
                    formState={operadorFields} 
                    formModifyState={modificarEstado}
                    formSave={saveOperador}
                />
            </Modal>
            <Modal show={modalDeleteOperador} setShow={setModalDeleteOperador} title="Operador" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el Operador {operadorFields.nombresOperador} {operadorFields.apellidosOperador}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteOperador(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteOperador}/>
                </section>
            </Modal>
        </Container>
    )
}

export default Operadores;