import React, { useState, useEffect } from 'react';
import ListIcon from '@rsuite/icons/List';
import Container from '../UI/Container';
import Card from '../UI/Card';
import Modal from '../UI/Modal';
import Datagrid from '../UI/Datagrid';
import BasicButton from '../UI/Buttons/BasicButton';
import { Rate } from 'rsuite';
import { Link } from 'react-router-dom';
import { grid_columns_comentarios, grid_columns_rentas_detalle } from '../helpers/grid_columns';
import { dummy_data_rentas_detalle } from '../helpers/dummy_data';
import { MdProductionQuantityLimits, MdAttachMoney } from 'react-icons/md';
import { merent_instance } from '../helpers/axios_helper';
import toast from 'react-hot-toast';

function Dashboard() {
    const [modalDetalle, setModalDetalle] = useState(false);
    const [cantidadRentas, setCantidadRentas] = useState("0");
    const [montoRentado, setMontoRentado] = useState("$0.00");
    const [calificacionP, setCalificacionP] = useState(0);
    const [cantidadEquipos, setCantidadEquipos] = useState(0);

    const showDetail = () => {
        setModalDetalle(true);
    }

    useEffect(() => {
        consultaDashboard();
    }, []);

    const consultaDashboard = () => {
        const usr_id = localStorage.getItem("usr_id");

        merent_instance().get(`/api/renteros/${usr_id}/dashboard`).then(({data}) => {
            const {calificacion, cantidadRentas, montoTotal, equipos} = data;
            setCalificacionP(calificacion);
            setMontoRentado(montoTotal);
            setCantidadRentas(cantidadRentas);
            setCantidadEquipos(equipos)
        }).catch((error) => {
            console.log(error);
            toast.error("Error al consultar información del dashboard");
        })
    }
    const options_dropdown = [
        {
            label: 'Ver detalles de renta',
            icon: <ListIcon/>,
            action: showDetail
        },
    ];

    return(
        <Container>
            <div className="px-4">                    
                <Card text="Mi calificación" 
                    qty={calificacionP} 
                    icon={
                        <Rate value={calificacionP} size="lg" allowHalf readOnly />
                    }
                />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 px-4 gap-x-4 gap-y-1">         
                <Card   text= {
                            <div>
                                <h1 className='text-center m-0'>Cantidad de Rentas</h1>
                                <h6 className='text-center text-xs font-light -mt-5'>En esquema de Renta Segura</h6>
                            </div>
                            }
                        icon= {<MdProductionQuantityLimits className="text-primary-400 mx-auto my-2"/>}
                        qty= {cantidadRentas}>
                </Card>            
                <Card   text={
                            <div>
                                <h1 className='text-center m-0'>Monto total rentado</h1>
                                <h6 className='text-center text-xs font-light -mt-5'>En esquema de Renta Segura</h6>
                            </div>
                            }
                        icon= {<MdAttachMoney className="text-primary-400 mx-auto my-2"/>}
                        qty= {montoRentado}>
                </Card>    
            </div>
            
            {
                cantidadEquipos === 0 ?

                <section className='bg-white m-5 p-20 rounded-xl'>
                    <p className='text-xl text-ellipsis text-center text-secondary-700 mb-2'>
                        Bienvenido a tu cuenta de MERENT. Aún no cuentas con ningún equipo disponible para rentar. <br/> Da click en el siguiente botón para dar de alta tus equipos y poder rentarlos en nuestro sitio web.
                    </p>
                    <Link to={'/equipos/nuevo_equipo'} >
                        <BasicButton text="Dar de alta un equipo" type="button"/>
                    </Link>
                </section>

                :

                <Datagrid 
                    title="Comentarios"
                    header={grid_columns_comentarios(showDetail)}
                    data={[]}
                    height={520}
                    headerHeight={60}
                    showFilters={true}
                    options_dropdown={options_dropdown}
                />
            }
            <Modal show={modalDetalle} setShow={setModalDetalle} title="Detalle de equipos" className="max-w-5xl">
                <Datagrid 
                    header={grid_columns_rentas_detalle}
                    data={dummy_data_rentas_detalle}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>
        </Container>
    )
}

export default Dashboard;