import React, { useState, useRef, Fragment, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import BasicInput from '../UI/Inputs/BasicInput';
import BasicButton from '../UI/Buttons/BasicButton';
import Logo from '../../assets/merent-logo.png';
import Modal from '../UI/Modal';

import ReCAPTCHA from "react-google-recaptcha";

import ProfileContext from '../Contexts/ProfileContext';

import { merent_instance } from '../helpers/axios_helper';

import { RegExpEmail } from '../helpers/constants';

function Login() {
    const [captchaPass, setCaptchaPass] = useState(false);
    const [showRecover, setShowRecover] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [recoverEmail, setRecoverEmail] = useState("");

    const navigation = useNavigate();
    const captchaRef = useRef(null);
    const profileContext = useContext(ProfileContext);

    const handleLogin = () => {
        if(!captchaPass) {
            toast.error("Por favor, acepta el captcha para continuar")
            return;
        }
        if(!email.match(RegExpEmail)){
            toast.error("Ingresa una direccion de correo valida");
            return;
        }

        if(password == '' || password.length < 8){
            toast.error("La contraseña no es valida");
            return;
        }

        const body_login = {
            email:email,
            password: password,
            source: 2
        };

        merent_instance().post("login", body_login).then(({data}) => {
            const {aprobada, email, esValido, id, mensaje, nombre, roles, token, usuario} = data;
            if(esValido) {
                if(roles.includes('Cliente') && roles.length == 1){
                    toast.error("Este sitio solamente es de administración, si deseas rentar equipos dirigete al sitio de clientes");
                }
                else{
                    const user_role = roles.filter((r) => r != "Cliente")[0];
                    localStorage.setItem("tktwj", token);
                    localStorage.setItem("usr_id", id);
                    localStorage.setItem("usr_email", email);
                    localStorage.setItem("usr_name", nombre);
                    localStorage.setItem("usr_role", user_role);
                    localStorage.setItem("usr", usuario);
                    
                    switch(user_role){
                        case 'Admin': 
                                profileContext.setProfile(1);
                                navigation("/home");
                            break;
                        case 'Rentero':
                                profileContext.setProfile(2);
                                navigation("/dashboard");
                            break;
                        case 'Operador':
                                profileContext.setProfile(3);
                                navigation("/dashboard-operador")
                            break;
                        default:
                                toast.error("Hubo un problema, favor de intentarlo más tarde")
                            break;
                    }
                }
            }else {
                toast.error(mensaje);
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en tu solicitud, intentalo nuevamente más tarde");
        });
    }

    const onCaptchaPress = () => {
        setCaptchaPass(captchaRef.current.getValue());
    }

    const handleRecover = () => {
        merent_instance().get("recuperar/" + encodeURIComponent(recoverEmail)).then((data) => {
            console.log(data);
            toast.success("Se ha mandado un correo electronico con las instrucciones, por favor, continua en el");
            setShowRecover(false);
            setRecoverEmail("");
        }).catch(({response}) => {
            toast.error(response.data);
        })
    }

    return(
        <>
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-construction-pattern bg-contain bg-origin-content drop-shadow-sm ">
            <div className="relative h-[35rem] mx-5 md:w-1/2 lg:w-1/3 xl:w-1/3 xs:w-full sm:w-full">
                <div className="absolute w-full h-full bg-white flex justify-center items-center rounded-lg border-small border-primary-200 shadow-md shadow-primary-100">
                    <form className="flex items-center content-center justify-center flex-col px-10 w-full h-full text-center">
                        <img src={Logo} alt="Logo" className="h-28"/>
                        <h1>¡Bienvenido!</h1>
                        <BasicInput type="email" placeholder="Correo" defaultValue={email} setValue={(e) => setEmail(e.target.value)}/>
                        <BasicInput type="password" placeholder="Contraseña" defaultValue={password} setValue={(e) => setPassword(e.target.value)}/>
                        <ReCAPTCHA
                            className="m-1"
                            ref={captchaRef}
                            sitekey="6LfhSBslAAAAAMQgeGvz3qrWfcWoWbTT_MeUQL4d"
                            onChange={onCaptchaPress}
                        />

                        <BasicButton type="button" clickFn={handleLogin} text={"Iniciar Sesión"}/>

                        <h6 className="mt-4 mb-1 text-sm">¿Olvidaste tu contraseña? 
                            <b onClick={() => setShowRecover(true)} className="underline text-blue-400 cursor-pointer font-bold"> Presiona aquí</b>
                        </h6>
                        <h6 className="mb-2 text-sm">¿Quieres rentar tus equipos? 
                            <Link to={'/registrar'} className="underline text-blue-400 cursor-pointer font-bold"> Registrate aquí</Link> 
                        </h6>
                        <h6 className="mb-2 text-sm">¿Quieres ofrecer tus servicios como operador?
                            <Link to={'/registrar-operador'} className="underline text-blue-400 cursor-pointer font-bold"> Registrate aquí</Link> 
                        </h6>
                    </form>
                </div>
            </div>
        </div> 

        <Modal className="max-w-lg" title="Recuperar contraseña" show={showRecover} setShow={setShowRecover}>
            <div className="mt-2">
                <p className="text-sm text-gray-500">
                    Ingrese su correo y recibirá las instrucciones a seguir.
                </p>
                <BasicInput type="email" placeholder="Correo" defaultValue={recoverEmail} setValue={(e) => setRecoverEmail(e.target.value)}></BasicInput>
            </div>

            <div className="mt-4 flex">
                <BasicButton type="button" clickFn={handleRecover} text={"Enviar"}/>
            </div>
        </Modal>
        </>
    );
}

export default Login;