import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigation = useNavigate();
    useEffect(() => {
        localStorage.removeItem("tktwj");
        localStorage.removeItem("usr_id");
        localStorage.removeItem("usr_email");
        localStorage.removeItem("usr_name");
        localStorage.removeItem("usr_role");
        localStorage.removeItem("usr");
        navigation("/login");
    }, [])

    return(
        <>
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-construction-pattern bg-contain bg-origin-content drop-shadow-sm ">
            <div className="relative h-[35rem] mx-5 md:w-1/2 lg:w-1/3 xl:w-1/3 xs:w-full sm:w-full">
                <div className="absolute w-full h-full bg-white flex justify-center items-center rounded-lg border-small border-primary-200 shadow-md shadow-primary-100">
                    Redireccionando al Login...
                </div>
            </div>
        </div> 
        </>
    );
}

export default Logout;