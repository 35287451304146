import React, { useEffect, useState, Fragment } from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import ListIcon from '@rsuite/icons/List';
import Datagrid from '../UI/Datagrid';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import ItemList from '../UI/Form/ItemList';
import { merent_instance } from '../helpers/axios_helper';
import { grid_columns_equipos, grid_columns_equipos_servicios, grid_columns_equipos_aditamentos } from '../helpers/grid_columns';
import { form_fields_equipos, form_fields_equipos_servicio, form_fields_equipos_aditamentos } from '../helpers/form_fields';
import { useParams } from 'react-router-dom';

function Equipos() {
    const params = useParams();
    const [modalEquipo, setModalEquipo] = useState(false);
    const [modalEquipoAlert, setModalEquipoAlert] = useState(false);
    const [modalServicio, setModalServicio] = useState(false);
    const [modalAditamento, setModalAditamento] = useState(false);

    const [modalDetalleServicios, setModalDetalleServicios] = useState(false);
    const [modalDetalleAditamento, setModalDetalleAditamento] = useState(false);

    const [modalDeleteEquipo, setModalDeleteEquipo] = useState(false);
    const [modalDeleteServicio, setModalDeleteServicio] = useState(false);
    const [modalDeleteAditamento, setModalDeleteAditamento] = useState(false);

    const [categorias, setCategorias] = useState([]);
    const [categoriasMaestras, setCategoriasMaestras] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [aditamentos, setAditamentos] = useState([]);

    const [serviciosAAgregar, setServiciosAAgregar] = useState([]);
    const [aditamentosAAgregar, setAditamentosAAgregar] = useState([]);

    const [creditos, setCreditos] = useState(0);
    const [disabledOperadores, setDisabledOperadores] = useState(true);

    const equipoBody = {
        "id": 0,
        "alerta": false,
        "motivoRechazo": "",
        "nombre": "",
        "descripcion": "",
        "modelo": "",
        "horometro": "",
        "precioDia": "",
        "precioSemana": "",
        "precioQuincena": "",
        "precioMes": "",
        "descuento": true,
        "necesitaOperador": true,
        "precioOperadorDia": "0",
        "renteroRef": localStorage.getItem("usr_id"),
        "categoriaEquipoRef": 0,
        "esquemaRef": 2,
        "facturaPedimento": "",
    };

    const servicioBody = {
        "id": 0,
        "nombre": "",
        "frecuencia": 0,
        "disponible": true,
        "equipoRef": 0
    };

    const aditamentoBody = {
        "id": 0,
        "nombre": "",
        "frecuencia": 0,
        "disponible": true,
        "precio": 0,
        "equipoRef": 0
    };

    const [equipoFields, setEquipoFields] = useState(equipoBody);
    
    const [servicioFields, setServicioFields] = useState(servicioBody);

    const [aditamentoFields, setAditamentoFields] = useState(aditamentoBody);

    useEffect(() => {
        getCategoriasEquipo();
        getCategoriasMaestrasEquipo();
        getEquipos();
        getCreditosDisponibles();
        if(params.nuevo === "nuevo_equipo"){
            setTimeout(() => {
                setModalEquipo(true);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        let descuentoLocal;
        if(equipoFields.esquemaRef == 2){
            descuentoLocal = true;
            setDisabledOperadores(true);
        }
        else{
            descuentoLocal = false;
            setDisabledOperadores(false);
        }
        setEquipoFields({...equipoFields, descuento: descuentoLocal})
    }, [equipoFields.esquemaRef])

    useEffect(() => {
        if(equipoFields.categoriaMaestraEquipoRef !== undefined || equipoFields.categoriaEquipoRef !== 0)
        {
            getCagegoriasPorMaestra(equipoFields.categoriaMaestraEquipoRef);
        }
    }, [equipoFields.categoriaMaestraEquipoRef])

    const getCreditosDisponibles = () => {
        merent_instance().get(`api/renteros/${localStorage.getItem("usr_id")}/creditos`).then(({data}) => {
            setCreditos(data);
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }

    const getCategoriasMaestrasEquipo = () => {
        merent_instance().get("api/categoriaequipo/maestra").then(({data}) => {
            setCategoriasMaestras(data.map((cat) => {
                return {value: cat.id, label: cat.nombre}
            }));
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }

    const getCagegoriasPorMaestra = (id) => {
        merent_instance().get(`api/categoriaequipo/maestra/${id}`).then(({data}) => {
            setCategorias(data.map((cat) => {
                return {value: cat.id, label: cat.nombre}
            }));
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }
    
    const getCategoriasEquipo = () => {
        merent_instance().get("api/categoriaequipo").then(({data}) => {
            setCategorias(data.map((cat) => {
                return {value: cat.id, label: cat.nombre}
            }));
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }
    
    /* Equipos */
    const getEquipos = () => {
        merent_instance().get(`api/renteros/${localStorage.getItem("usr_id")}/equipos`).then(({data}) => {
            setEquipos(data);
            setModalEquipo(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }

    const newEquipo = () => {
        setEquipoFields(equipoBody);
        setModalEquipo(true);
    }

    const editEquipo = (item) =>  {
        setEquipoFields(item);
        setModalEquipo(true);
    };

    const saveEquipo = () => {
        var id = equipoFields.id;
        if(equipoFields.esquemaRef == 1 && creditos <= 0){
            toast((t) => 
                (<span>
                    No cuentas con créditos disponibles para poder guardar un equipo de Renta Abierta
                    <button className="underline text-blue-500 text-bold" type="button" onClick={agregarPublicaciones}> Haz click aquí </button>
                    para comprar
                </span>),
                {
                    duration:15000,
                }
                );
            return;
        }
        const body_data = {
            "id": equipoFields.id,
            "alerta": false,
            "motivoRechazo": "",
            "nombre": equipoFields.nombre,
            "descripcion": equipoFields.descripcion,
            "modelo": equipoFields.modelo,
            "horometro": equipoFields.horometro,
            "precioDia": equipoFields.precioDia,
            "precioSemana": equipoFields.precioSemana,
            "precioQuincena": equipoFields.precioQuincena,
            "precioMes": equipoFields.precioMes,
            "descuento": equipoFields.descuento,
            "necesitaOperador": equipoFields.necesitaOperador,
            "precioOperadorDia": "0",
            "renteroRef": parseInt(localStorage.getItem("usr_id")),
            "categoriaEquipoRef": parseInt(equipoFields.categoriaEquipoRef),
            "esquemaRef": parseInt(equipoFields.esquemaRef),
            "imagenPrincipal": equipoFields.imagenPrincipal,
            "imagenes": equipoFields.imagenes
        };
        if(id === 0){
            merent_instance().post("api/Equipos", body_data).then(({data}) => {
                console.log(data);
                const new_equipo_id = data.id;
                saveImagen(new_equipo_id, equipoFields.imagenPrincipal[0], true);

                console.log(equipoFields.imagenes)
                if(equipoFields.imagenes)
                    for(let i = 0; i < equipoFields.imagenes.length; i++) {
                        saveImagen(new_equipo_id, equipoFields.imagenes[i], false);
                    }

                if(serviciosAAgregar && serviciosAAgregar.length > 0){
                    for(let i = 0; i < serviciosAAgregar.length; i++) {
                        saveServicioFromEquipo(new_equipo_id, serviciosAAgregar[i]);
                    }
                }

                if(aditamentosAAgregar && aditamentosAAgregar.length > 0){
                    for(let i = 0; i < aditamentosAAgregar.length; i++) {
                        saveAditamentoFromEquipo(new_equipo_id, aditamentosAAgregar[i]);
                    }
                }

                if(equipoFields.facturaPedimento != "")
                    saveFacturaPedimento(new_equipo_id, equipoFields.facturaPedimento[0]);

                toast.success("Equipo creado");
                setCreditos(creditos - 1);
                getEquipos();
    
            }).catch((error) => {
                console.log(error);
                switch(error.request.status) {
                    case 412: 
                        toast.error("No cuentas con créditos disponibles para poder guardar un equipo de Renta Abierta, dirigete a 'Mi cuenta' para cambiar tu plan");
                    break;
                    default:
                        toast.error("Hubo un error, intenta mas tarde");

                }
            });
        }
        else{
            if(!window.confirm("Esta acción volverá a mandar a aprobación el equipo, ¿Seguro que deseas continuar?"))
                return;

            merent_instance().patch("api/Equipos/"+id, body_data).then(({data}) => {
                toast.success(data);
                getEquipos();
    
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error, intenta mas tarde");
            });
        }        
    };

    const saveImagen = (equipo_id, imagen, esPrincipal) => {
        let form_data = new FormData();
        form_data.append("imagen", imagen);
        merent_instance().post(`api/equipos/${equipo_id}/imagen`, form_data, {
            "Content-Type": "multipart/form-data",
        }).then(({data}) => {
            console.log(data);
            if(esPrincipal)
                saveImagenPrincipal(equipo_id, data);
        }).catch(error => {
            console.log(error);
            toast.error("Error al subir las imagenes, intenta nuevamente más tarde")
        })
    }

    const saveFacturaPedimento = (equipoId, facturaPedimento) => {
        let form_data = new FormData();
        form_data.append("facturaPedimento", facturaPedimento);
        merent_instance().post(`api/equipos/${equipoId}/facturaPedimento`, form_data, {
            "Content-Type": "multipart/form-data",
        }).then(({data}) => {

        }).catch(error => {
            console.log(error);
            toast.error("Error al subir la factura o pedimento de la maquina, porfavor intente más tarde");
        })
    }

    const saveServicioFromEquipo = (equipo_id, servicio) => {
        const body_data = servicio;
        body_data.equipoRef = equipo_id;

        merent_instance().post("api/Equipos/"+equipo_id+"/servicio", body_data).then(({data}) => {
            console.log(data);

        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar los servicios, intenta mas tarde");
        });
    }

    const saveAditamentoFromEquipo = (equipo_id, aditamento) => {
        const body_data = aditamento;
        body_data.equipoRef = equipo_id;

        merent_instance().post("api/Equipos/"+equipo_id+"/aditamento", body_data).then(({data}) => {
            console.log(data);

        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar los aditamentos, intenta mas tarde");
        });
    }

    const saveImagenPrincipal = (equipo_id, imagen_nueva) => {
        console.log(imagen_nueva)
        merent_instance().post(`api/equipos/${equipo_id}/imagen/principal/${imagen_nueva.id}`)
        .then(data => {
            console.log(data);
        }).catch(error => {
            console.log(error);
            toast.error("Error al subir las imagenes, intenta nuevamente más tarde")
        })
    }   

    const deleteEquipo = (item) => {
        var active = equipoFields.disponible;
        setModalDeleteEquipo(true);
    }

    const confirmDeleteEquipo = (item) => {
        toast.success("Equipo Eliminado");
        setEquipoFields(equipoBody);
        setModalDeleteEquipo(false);
    }

    const alertEquipo = (item) => {
        setEquipoFields(item);
        setModalEquipoAlert(true);
    }

    const validaCreditos = () => {
        if(creditos <= 0 && equipoFields.tipoEsquema == 1){
            toast.error(`No tienes créditos para guardar este equipo en el esquema de Renta Abierta. Cambia tu plan de suscripción para obtener más créditos.`);
            return false;
        }
        else{
            return true;
        }
    }

    const options_dropdown = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editEquipo
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteEquipo
        },
        {
            label: 'Ver Servicios',
            icon: <ListIcon/>,
            action: (item) => { getServicios(item) }
        },
        {
            label: 'Ver Aditamentos',
            icon: <ListIcon/>,
            action: (item) => { getAditamentos(item) }
        }
    ];

    /* Servicios */
    const getServicios = (item) => {
        if(item !== undefined && item !== null){
            setEquipoFields(item);
        }
         merent_instance().get("api/equipos/"+item.id+"/servicios").then(({data}) => {
            setServicios(data);
            setModalDetalleServicios(true);
            setModalServicio(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        }); 
        setModalDetalleServicios(true);
        setModalServicio(false);
    }

    const newServicio = () => {
        setServicioFields(servicioBody);
        setModalServicio(true);
    }

    const editServicio = (item) =>  {
        setServicioFields(item);
        setModalServicio(true);
    };
    
    const saveServicio = () => {
        var {id} = servicioFields;
        var equipoRef = equipoFields.id;
        const body_data = servicioFields;
        body_data.equipoRef = equipoRef;

        if(id === 0){
            merent_instance().post("api/Equipos/"+equipoRef+"/servicio", body_data).then(({data}) => {
                toast.success(data);
                getServicios(equipoFields);
    
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error, intenta mas tarde");
            });
        }
        else{
            merent_instance().patch("api/Equipos/"+equipoRef+"/servicio/"+id, body_data).then(({data}) => {
                toast.success(data);
                getServicios(equipoFields);
    
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error, intenta mas tarde");
            });
        }    
    };

    const deleteServicio = (item) => {
        setModalDeleteServicio(true);
    }

    const confirmDeleteServicio = (item) => {
        toast.success("Servicio Eliminado");
        setServicioFields(aditamentoBody);
        setModalDeleteServicio(false);
    }

    const options_dropdown_servicio = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editServicio
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteServicio
        }
    ];

    /* Aditamentos */
    const getAditamentos = (item) => {
        if(item !== undefined && item !== null){
            setEquipoFields(item);
        }
        console.log(item)
        merent_instance().get("api/equipos/"+item.id+"/aditamentos").then(({data}) => {
            console.log(data);
            setAditamentos(data);
            setModalDetalleAditamento(true);
            setModalAditamento(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
        setModalDetalleAditamento(true);
        setModalAditamento(false);
    }

    const newAditamento = () => {
        setAditamentoFields(aditamentoBody);
        setModalAditamento(true);
    }

    const editAditamento = (item) =>  {
        setAditamentoFields(item);
        setModalAditamento(true);
    };
    
    const saveAditamento = () => {
        var {id} = aditamentoFields;
        var equipoRef = equipoFields.id;
        const body_data = aditamentoFields;
        body_data.equipoRef = equipoRef;
        if(id === 0){
            merent_instance().post("api/Equipos/"+equipoRef+"/aditamento", body_data).then(({data}) => {
                toast.success(data);
                getAditamentos(equipoFields);
    
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error, intenta mas tarde");
            });
        }
        else{
            merent_instance().patch("api/Equipos/"+equipoRef+"/aditamento/"+id, body_data).then(({data}) => {
                toast.success(data);
                getAditamentos(equipoFields);
    
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error, intenta mas tarde");
            });
        }    
    };

    const deleteAditamento = (item) => {
        setModalDeleteAditamento(true);
    }

    const confirmDeleteAditamento = (item) => {
        toast.success("Aditamento Eliminado");
        setAditamentoFields(aditamentoBody);
        setModalDeleteAditamento(false);
    }

    const agregarPublicaciones = () => {
        window.open('../perfil', '_blank', 'noopener,noreferrer');
    }

    const options_dropdown_aditamento = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editAditamento
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteAditamento
        }
    ];

    return (
        <Container>
            {/* Equipos */}
            <Datagrid 
                title="Equipos"
                header={grid_columns_equipos(editEquipo, alertEquipo)}
                data={equipos}
                height={500}
                headerHeight={40}
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar equipo" type="button" clickFn={newEquipo}/>}
            />
            <Modal show={modalEquipo} setShow={setModalEquipo} title="Agregar Equipo" className="max-w-5xl ">
                <div className='overflow-y-auto h-[700px]'>
                    <Form 
                        formFields={form_fields_equipos(categorias, creditos, agregarPublicaciones, disabledOperadores, categoriasMaestras)} 
                        formState={equipoFields} 
                        formModifyState={setEquipoFields}
                        formSave={saveEquipo}
                        preValidation={validaCreditos}
                        buttonText={"Agregar Equipo"}
                    >
                        {
                            equipoFields.id === 0 ?
                            <Fragment>
                                <section className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3">
                                    <ItemList
                                        formFields={form_fields_equipos_servicio} 
                                        formState={servicioFields}
                                        formModifyState={setServicioFields} 
                                        title='Agrega los servicios que necesita este equipo (Opcional)'
                                        itemsList={serviciosAAgregar}
                                        setItemsList={setServiciosAAgregar}
                                        lineFileds={1}
                                        textAdd={"Agregar servicio"}
                                    />
                                </section>
                                <section className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3">
                                    <ItemList
                                        formFields={form_fields_equipos_aditamentos} 
                                        formState={aditamentoFields}
                                        formModifyState={setAditamentoFields} 
                                        title='Agrega los aditamentos que se ofrecerán para este equipo (Opcional)'
                                        itemsList={aditamentosAAgregar}
                                        setItemsList={setAditamentosAAgregar}
                                        lineFileds={1}
                                        textAdd={"Agregar aditamento"}

                                    />
                                </section>
                            </Fragment>
                            :
                                ''
                        }
                        
                    </Form>
                </div>
            </Modal>
            <Modal show={modalDeleteEquipo} setShow={setModalDeleteEquipo} title="Equipo" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el equipo {equipoFields.nombreEquipo}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteEquipo(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteEquipo}/>
                </section>
            </Modal>
            <Modal show={modalEquipoAlert} setShow={setModalEquipoAlert} title="Alerta de Equipo" className="max-w-2xl">
                <header className="w-full">
                    <p className='text-lg'>Este equipo fue rechazado por el Administrador.</p>
                    <p>Motivo del rechazo:</p>
                    <p className='bg-gray-100 text-lg italic p-8'>"{equipoFields.motivoRechazo !== undefined && equipoFields.motivoRechazo !== null ? equipoFields.motivoRechazo : ''}"</p>
                    <p>Edita la información del equipo para enviarla de nuevo a aprobación del Administrador.</p>
                </header>
                <section className="grid grid-cols-1 gap-x-8 gap-y-1">
                    <BasicButton type="button" text="Editar equipo" clickFn={() => {setModalEquipoAlert(false); editEquipo(equipoFields)}}/>
                </section>
            </Modal>

            {/* Servicios */}
            <Modal show={modalDetalleServicios} setShow={setModalDetalleServicios} className="max-w-6xl">
                <Datagrid 
                    title="Servicios"
                    header={grid_columns_equipos_servicios(editServicio)}
                    data={servicios}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                    options_dropdown={options_dropdown_servicio}
                    buttonAdd={<BasicButton text="Nuevo" type="button" clickFn={newServicio}/>}
                />
            </Modal>
            <Modal show={modalServicio} setShow={setModalServicio} title="Agregar Servicio" className="max-w-5xl">
                <Form 
                    formFields={form_fields_equipos_servicio} 
                    formState={servicioFields} 
                    formModifyState={setServicioFields}
                    formSave={saveServicio}
                    />
            </Modal>
            <Modal show={modalDeleteServicio} setShow={setModalDeleteServicio} title="Servicio" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el servicio {servicioFields.nombreServicio}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteServicio(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteServicio}/>
                </section>
            </Modal>

            {/* Aditamentos */}
            <Modal show={modalDetalleAditamento} setShow={setModalDetalleAditamento} className="max-w-6xl">
                <Datagrid 
                    title="Aditamentos"
                    header={grid_columns_equipos_aditamentos(editAditamento)}
                    data={aditamentos}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                    options_dropdown={options_dropdown_aditamento}
                    buttonAdd={<BasicButton text="Nuevo" type="button" clickFn={newAditamento}/>}
                />
            </Modal>
            <Modal show={modalAditamento} setShow={setModalAditamento} title="Agregar Aditamento" className="max-w-5xl">
                <Form 
                    formFields={form_fields_equipos_aditamentos} 
                    formState={aditamentoFields} 
                    formModifyState={setAditamentoFields}
                    formSave={saveAditamento}
                    />
            </Modal>
            <Modal show={modalDeleteAditamento} setShow={setModalDeleteAditamento} title="Aditamento" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el aditamento {aditamentoFields.nombreAditamento}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteAditamento(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteAditamento}/>
                </section>
            </Modal>
        </Container>
    )
}

export default Equipos;