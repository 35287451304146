import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import Container from '../UI/Container';
import Modal from '../UI/Modal';
import Card from '../UI/Approval/Card';
import BasicButton from '../UI/Buttons/BasicButton';
import toast from 'react-hot-toast';
import BasicInput from '../UI/Inputs/BasicInput';
import { dummy_data_aprobacion } from '../helpers/dummy_data';
import { merent_instance } from '../helpers/axios_helper';

function Aprobacion() {
    const [showModalRechazo, setShowModalRechazo] = useState(false);
    const [motivoRechazo, setMotivoRechazo] = useState("");
    const [equipos, setEquipos] = useState([]);
    const [idEquipoRechazo, setIdEquipoRechazo] = useState(0);

    useEffect(() => {
        cargarEquipos();
    }, [])

    const cargarEquipos = () => {
        merent_instance().get("api/equipos/aprobacion").then(({data}) => {
            setEquipos(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }
    

    const rechazarEquipo = (id) => {
        setShowModalRechazo(true);
        setIdEquipoRechazo(id);
    }

    const aprobarEquipo = (id) => {
        merent_instance().get(`api/equipos/${id}/aprobar`)
        .then(({data}) => {
            cargarEquipos();
            toast.success("Equipo Aprobado");
        })
        .catch(error => {
            console.log(error);
            toast.error("Error al aprobar equipo, porfavor intenta nuevamente más tarde");
        })
    }

    const mandarRechazo = () => {
        merent_instance().post(`api/equipos/${idEquipoRechazo}/rechazar`, {
            motivoRechazo: motivoRechazo
        })
        .then(({data}) => {
            setShowModalRechazo(false);
            setMotivoRechazo("");
            cargarEquipos();
            toast.success("Equipo Rechazado");
        })
        .catch(error => {
            console.log(error);
            toast.error("Error al rechazar equipo, porfavor intenta nuevamente más tarde");
        })
    }

    
    return (
        <Container>
            <section className="flex flex-col w-full h-full p-4">
                <header className="w-full text-2xl text-center">Aprobación de equipos</header>
                <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-3 gap-x-4 gap-y-4">
                    {
                        equipos.map((item, index) => {
                            console.log(item);
                            const {nombreEmpresa} = item.rentero;
                            const {id, nombre, descripcion, modelo, horometro, precioDia, precioSemana, precioQuincena, precioMes, imagenEquipos, categoriaEquipo, serviciosEquipos, aditamentosEquipos, facturaEquipo} = item;
                            const nombreCategoria = categoriaEquipo.nombre;
                            return(
                                <Card key={index} header={"Empresa: " + nombreEmpresa} title={nombre} subTitle={"Categoria: " + nombreCategoria} description={descripcion} images={imagenEquipos}>
                                    <section className="w-full p-2">
                                        <p className="mx-4"><b>Propietario:</b> {nombreEmpresa}</p>
                                        <p className="mx-4"><b>Esquema:</b> {item.esquema.nombre}</p>
                                        <p className="mx-4"><b>Horometro:</b> {horometro}</p>
                                        <p className="mx-4"><b>Modelo:</b> {modelo}</p>
                                        <p className="bg-secondary-300 p-1 text-white text-lg rounded font-bold text-center mb-1">Precio por Dia <p>{Intl.NumberFormat('es-MX', { style: 'currency', currency:'MXN'}).format(precioDia)}</p></p>
                                        <p className="bg-secondary-300 p-1 text-white text-lg rounded font-bold text-center mb-1">Precio por Semana <p>{Intl.NumberFormat('es-MX', { style: 'currency', currency:'MXN'}).format(precioSemana)}</p></p>
                                        <p className="bg-secondary-300 p-1 text-white text-lg rounded font-bold text-center mb-1">Precio por Quincena <p>{Intl.NumberFormat('es-MX', { style: 'currency', currency:'MXN'}).format(precioQuincena)}</p></p>
                                        <p className="bg-secondary-300 p-1 text-white text-lg rounded font-bold text-center mb-1">Precio por Mes <p>{Intl.NumberFormat('es-MX', { style: 'currency', currency:'MXN'}).format(precioMes)}</p></p>
                                        <Disclosure>
                                            {({open}) => (
                                                <>
                                                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                                        <span>Servicios</span>
                                                        <FaChevronDown className={`${
                                                            open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-primary-500`}/>
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul>
                                                            {
                                                                serviciosEquipos.length == 0 ?
                                                                <><p>No hay Servicios en este equipo</p></>
                                                                : <></>
                                                            }
                                                            {
                                                                serviciosEquipos.map((item, index) => {
                                                                    const {nombre, frecuencia} = item;
                                                                    return(
                                                                        <li key={index} className="text-sm text-left font-bold text-secondary">{nombre} - Frecuencia: {frecuencia}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Disclosure.Panel>

                                                </>
                                            )}
                                        </Disclosure>
                                        <Disclosure>
                                            {({open}) => (
                                                <>
                                                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                                        <span>Aditamentos</span>
                                                        <FaChevronDown className={`${
                                                            open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-primary-500`}/>
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul>

                                                            {
                                                                aditamentosEquipos.length == 0 ?
                                                                <><p>No hay Aditamentos en este equipo</p></>
                                                                : <></>
                                                            }
                                                            {
                                                                aditamentosEquipos.map((item, index) => {
                                                                    const {nombre, descripcion, precio} = item;
                                                                    return(
                                                                        <li key={index}>
                                                                            <div className='flex flex-col'>
                                                                                <p className='w-full text-xl text-center font-bold text-secondary'>{nombre}</p>
                                                                                <span className="w-full text-xs text-left text-secondary">{descripcion}</span>
                                                                                <span className="w-full text-lg text-right text-secondary font-bold">$ {precio}.00</span>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                        <Disclosure>
                                        {({open}) => (
                                                <>
                                                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                                        <span>Facturas o pedimentos</span>
                                                        <FaChevronDown className={`${
                                                            open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-primary-500`}/>
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                        <ul>
                                                            {
                                                                facturaEquipo.length == 0 ?
                                                                <><p>No hay Facturas Registradas en este equipo</p></>
                                                                : <></>
                                                            }
                                                            {
                                                                facturaEquipo.map((itemFactura, index) => {
                                                                    const {url} = itemFactura;
                                                                    return(
                                                                        <li key={index} className="text-sm text-left font-bold text-secondary"><a target="_blank" href={url} className='text-blue-500 underline font-bold'>Factura de equipo</a></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                        <BasicButton clickFn={() => rechazarEquipo(id)} type="button" text="Rechazar" className="!bg-secondary-200 hover:!bg-secondary-100"/>
                                        <BasicButton clickFn={() => aprobarEquipo(id)} type="button" text="Aprobar" className="bg-primary-300 hover:bg-primary-200"/>
                                    </section>
                                </Card>
                            );
                        })
                    }
                </main>
            </section>
            <Modal show={showModalRechazo} setShow={setShowModalRechazo} title="Motivo de rechazo" className="max-w-5xl">
                <p className="text-xl text-secondary border-b-4 mb-2">
                    Motivo de rechazo
                </p>
                <BasicInput type="text" placeholder="Motivo de rechazo" defaultValue={motivoRechazo} setValue={(e) => setMotivoRechazo(e.target.value)} />
                <BasicButton clickFn={mandarRechazo} type="button" text="Mandar rechazo"/>
            </Modal>
        </Container>
    )
}

export default Aprobacion;