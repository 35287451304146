import React, { useState, useEffect } from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import ListIcon from '@rsuite/icons/List';
import Datagrid from '../UI/Datagrid';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { grid_columns_clientes, grid_columns_rentas_recientes } from '../helpers/grid_columns';
import { form_fields_clientes } from '../helpers/form_fields';
import { merent_instance } from '../helpers/axios_helper';

function Clientes() {
    const [modalCliente, setModalCliente] = useState(false);
    const [modalDeleteCliente, setModalDeleteCliente] = useState(false);
    const [modalDetalle, setModalDetalle] = useState(false);
    const [rentasCliente, setRentasCliente] = useState({});
    const [clienteFields, setClienteFields] = useState({
        id: 0,
        nombreCliente: '',
        email: '',
        telefono: '',
        password: '',
        confirmaPassword: '',
    });
    const [clientes, setClientes] = useState([]);

    useEffect(() => {
        obtenerClientes();
    }, []); 

    const obtenerClientes = () => {
        merent_instance().get("api/clientes").then(({data}) => {
            setClientes(data);
        }).catch((e) => {
            toast.error("Error al consultar el listado de clientes")
            console.log(e);
        });
    }

    const editCliente = (item) =>  {
        setClienteFields(item);
        setModalCliente(true);
    };

    const newCliente = () => {
        setClienteFields({
            id: 0,
            nombreCliente: '',
            email: '',
            telefono: '',
            password: '',
            confirmaPassword: '',
        });
        setModalCliente(true);
    }

    const saveCliente = () => {
        merent_instance().post("api/usuarios/cliente", clienteFields).then(({data}) => {
            console.log(data);
            toast.success("Cliente registrado");
            obtenerClientes();
            setModalCliente(false);
        })
        .catch(error => {
            console.log(error);
            toast.error("Error al crear cliente");
        })
    };

    const deleteCliente = (item) => {
        setModalDeleteCliente(true);
    }

    const confirmDeleteCliente = (item) => {
        toast.success("Cliente Eliminado");
        setClienteFields({
            id: 0,
            nombre: '',
            email: '',
            telefono: '',
            password: '',
            confirmaPassword: '',
        });
        setModalDeleteCliente(false);
    }

    const verDetalle = (item) => {
        merent_instance().get(`api/clientes/${item.id}/rentas/recientes`).then(({data}) => {
            console.log(data);
            setRentasCliente(data);
            setModalDetalle(true);
        }).catch((error) => {
            console.log(error);
            toast.error("Error al cargar las rentas del cliente");
        })
    }

    const options_dropdown = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editCliente
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteCliente
        },
        {
            label: 'Ver rentas recientes',
            icon: <ListIcon/>,
            action: (item) => {verDetalle(item)}
        },
    ];

    return (
        <Container>
            <Datagrid 
                title="Clientes"
                header={grid_columns_clientes(editCliente)}
                data={clientes}
                height={500}
                headerHeight={40}
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar nuevo" type="button" clickFn={newCliente}/>}
            />

            <Modal show={modalCliente} setShow={setModalCliente} title="Agregar cliente" className="max-w-5xl">
                <Form 
                    formFields={form_fields_clientes} 
                    formState={clienteFields} 
                    formModifyState={setClienteFields}
                    formSave={saveCliente}
                    />
            </Modal>
            <Modal show={modalDeleteCliente} setShow={setModalDeleteCliente} title="Cliente" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar el cliente {clienteFields.nombre}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteCliente(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteCliente}/>
                </section>
            </Modal>
            <Modal show={modalDetalle} setShow={setModalDetalle} title="Detalle de rentas recientes" className="max-w-5xl">
                <Datagrid 
                    header={grid_columns_rentas_recientes}
                    data={rentasCliente}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>
        </Container>
    )
}

export default Clientes;