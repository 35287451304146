import React, {useEffect, useState} from 'react';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import { form_fields_horarios } from '../helpers/form_fields';
import { merent_instance } from '../helpers/axios_helper';

function Horarios() {
    const [horarios, setHorarios] = useState([]);
    const [modalHorario, setModalHorario] = useState(false);
    const [currentDate, setCurrentDate] = useState(null);
    const [modalDeleteHorario, setModalDeleteHorario] = useState(false);
    const [equipos, setEquipos] = useState([]);
    const [horarioFields, setHorarioFields] = useState({
        id: 0,
        equipoRef: 0,
        fechaInicio: '',
        fechaFin: '',
        tipoIndisponibilidad: 2
    });

    useEffect(() => {
        obtenerEquipos();
        obtenerHorarios();
    }, []);     

    const obtenerHorarios = () => {
        merent_instance().get("api/renteros/"+localStorage.getItem("usr_id")+"/calendario").then(({data}) => {
            setHorarios(data);
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }

    const obtenerEquipos = () => {
        merent_instance().get("api/renteros/"+localStorage.getItem("usr_id")+"/equipos").then(({data}) => {
            setEquipos(data.map((eq) => {
                return {value: eq.id, label: eq.nombre}
            }));
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data);
        });
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
 
    function getBlockedDays(date) {
        return horarios.filter(e => e.fecha === formatDate(date));
    }

    function renderCell(date) {
        const list = getBlockedDays(date);
        const displayList = list.filter((item, index) => index < 1);
        if (list.length) {
            const moreCount = list.length - displayList.length;
            const moreItem = (
              <li>
                <Whisper
                  placement="top"
                  trigger="click"
                  speaker={
                    <Popover>
                      {list.map((item, index) => (
                        <p key={index}>
                            { item.tipo === 'bloqueado' ?
                                <Badge />
                                :
                                <Badge color="blue"/>
                            } <b>{item.equipo}</b> - {item.descripcion}
                        </p>
                      ))}
                    </Popover>
                  }
                >
                  <a>{moreCount} más</a>
                </Whisper>
              </li>
            );
      
            return (
              <ul className="calendar-todo-list">
                {displayList.map((item, index) => (
                  <li key={index} className="text-ellipsis">
                    { item.tipo === 'bloqueado' ?
                        <Badge />
                        :
                        <Badge color="blue"/>
                    } <b>{item.equipo}</b> 
                    {
                        moreCount == 0 ? 
                        " - " + item.descripcion
                        :
                        ""
                    }
                  </li>
                ))}
                {moreCount ? moreItem : null}
              </ul>
            );
          }
      
    
        return null;
    }

    const newHorario = () => {
        setHorarioFields({
            id: 0,
            equipoRef: 0,
            fechaInicio: '',
            fechaFin: '',
            tipoIndisponibilidad: 2
        });
        setModalHorario(true);
    }

    const deleteHorario = (date) => {
        if(horarios.includes(formatDate(date))){
            setCurrentDate(formatDate(date));
            setModalDeleteHorario(true)
        }
    }

    const confirmDeleteHorario = (item) => {
        toast.success("El día ha sido habilitado de nuevo");
        setHorarioFields({
            id: 0,
            equipoRef: 0,
            fechaInicio: '',
            fechaFin: '',
            tipoIndisponibilidad: 2
        });
        setModalDeleteHorario(false);
    }
    
    const saveHorario = () => {
        const body_data = horarioFields;
        merent_instance().post("api/Equipos/"+horarioFields.equipoRef+"/indisponibilidad", body_data).then(({data}) => {
            toast.success(data);
            obtenerHorarios();
            setModalHorario(false);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error, intenta mas tarde");
        });
    }

    return (
        <Container>
            <header className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-6 gap-x-8 p-4">
                <p className="col-span-1 md:col-span-5 lg:col-span-2 xl:col-span-5 text-3xl font-semibold text-left m-2">
                    Horarios
                </p>
                <BasicButton text="Bloquear días" type="button" clickFn={newHorario}/>
            </header>
            <section className='mx-5 text-secondary-400'>
                <label>* Por default todos los días se encuentran habilitados para recibir solicitudes de renta</label>
                <br/>
                <Badge/> Equipo bloqueado
                <br/>
                <Badge color="blue"/> Equipo ocupado por una renta
            </section>
            <Calendar className="bg-white rounded-lg m-3" bordered renderCell={renderCell} onSelect={deleteHorario}/>

            <Modal show={modalHorario} setShow={setModalHorario} title="Bloquear Horario" className="max-w-5xl">
                <Form 
                    formFields={form_fields_horarios(equipos)} 
                    formState={horarioFields} 
                    formModifyState={setHorarioFields}
                    formSave={saveHorario}
                />
            </Modal>
            <Modal show={modalDeleteHorario} setShow={setModalDeleteHorario} title="Habilitar Horario" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas borrar el bloqueo del día {currentDate !== null ? currentDate : ''}? <br/> Se habilitará de nuevo para recibir solicitudes de renta</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteHorario(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Habilitar" clickFn={confirmDeleteHorario}/>
                </section>
            </Modal>
        </Container>
    )
}

export default Horarios;