import { RegExpEmail, RegExpCelular, RegExpPassword } from '../helpers/constants';
import { MdCardMembership, MdTurnedIn, MdVerifiedUser, MdVerified } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import BasicButton from '../UI/Buttons/BasicButton';

export const form_fields_renteros = [
    {
        title: 'Información de contacto',
        fields: [
            {
                key: 'nombreEncargado',
                label: 'Nombre del encargado',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'correoElectronico',
                label: 'Correo electrónico',
                type: 'text',
                isRequired: true,
                regExp: RegExpEmail
            },
            {
                key: 'telefonoCelular',
                label: 'Teléfono celular',
                type: 'text',
                isRequired: true,
                regExp: RegExpCelular
            }
        ]
    },
    {
        title: 'Información de la empresa',
        fields: [
            {
                key: 'nombreEmpresa',
                label: 'Nombre',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'direccionEmpresa',
                label: 'Dirección',
                type: 'text',
                isRequired: false,
            },
            {
                key: 'ciudadEmpresa',
                label: 'Ciudad',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'estadoEmpresa',
                label: 'Estado',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'descripcionEquipos',
                label: 'Descripción general del negocio',
                type: 'text',
                isRequired: false,
            },
            {
                key: 'rfc',
                label: 'RFC',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'constanciaSituacionFiscal',
                label: 'Constancia de situación fiscal',
                type: 'file',
                isRequired: false,
                isFile: true,
                accept: "image/png, image/jpeg, application/pdf"
            }
        ]
    }    
];

export const form_fields_clientes = [
    {
        title: 'Información de cliente',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre y Apellidos',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'email',
                label: 'Correo electónico',
                type: 'text',
                isRequired: true,
                regExp: RegExpEmail
            },
            {
                key: 'telefono',
                label: 'Teléfono',
                type: 'text',
                isRequired: true,
                regExp: RegExpCelular
            },
            {
                key: 'password',
                label: 'Password',
                type: 'password',
                isRequired: true,
                regExp: RegExpPassword
            },
            {
                key: 'confirmaPassword',
                label: 'Confirma Password',
                type: 'password',
                isRequired: true,
                regExp: RegExpPassword
            },
        ]
    }
];

export const form_fields_pagos = [
    {
        title: 'Información del pago',
        fields: [
            {
                key: 'monto',
                label: 'Monto del pago',
                type: 'number',
                isRequired: true,
            },
            {
                key: 'cuentaOrigen',
                label: 'Cuenta de origen',
                type: 'text',
                isRequired: true
            },
            {
                key: 'concepto',
                label: 'Concepto de pago',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'file',
                label: 'Comprobante de pago',
                type: 'file',
                isRequired: true,
                accept: "image/png, image/jpeg, application/pdf"
            }
        ]
    }
];

export const form_fields_renteros_perfil_empresa = [
    {
        title: 'Información de contacto',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre del encargado',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'email',
                label: 'Correo electrónico',
                type: 'text',
                isRequired: true,
                regExp: RegExpEmail,
                disabled: true
            },
            {
                key: 'phoneNumber',
                label: 'Teléfono celular',
                type: 'text',
                isRequired: true,
                regExp: RegExpCelular
            },
            {
                key: 'cuenta',
                label: 'Cuenta bancaria a la que se pagará',
                type: 'text',
                isRequired: true
            },
        ]
    },
    {
        title: 'Información de la empresa',
        fields: [
            {
                key: 'nombreEmpresa',
                label: 'Nombre',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'descripcion',
                label: 'Descripción general del negocio',
                type: 'text',
                isRequired: true,
            },
            {
                key: 'rfc',
                label: 'RFC',
                type: 'text',
                isRequired: true,
            }
        ]
    }
];

export const form_fields_renteros_perfil_cuenta = [
    {
        title: 'Información de la cuenta',
        fields: [
        ]
    }  
];

export const form_fileds_tipos_esquemas = [
    {
        id: 1,
        nombreEsquema: 'Renta Abierta',
        icon: <MdCardMembership/>,
        important_text: 
        <div>
            <p className="text-center text-white font-bold text-xlg">$99.00</p>
            <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Promociona tus equipos sin límite</li>
            <li>Paga por mes sin dar comisión por tus rentas</li>
            <li>Trato directo con el cliente</li>
        </ul>
    },
    {
        id: 2,
        nombreEsquema: 'Renta Segura',
        icon: <RiSecurePaymentFill/>,
        important_text: 
        <div className="items-center justify-center">
            <p className="text-center text-white font-bold text-xlg">2%</p>
            <p className='text-center text-secondary-400 text-sm'>De comisión por renta</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Pago asegurado del flete ida y vuelta </li>
            <li>Asesoría en el proceso de renta </li>
            <li>Historial detallado de rentas </li>
            <li>Alertas de seguimiento automáticas </li>
            <li>Programación calendarizada de rentas </li>
            <li>Opción para ofrecer descuento por equipo </li> 
            <li>Tickets de soporte y su seguimiento automático </li>
            <li>Contrato </li>
            <li>Monitoreo de renta </li>
            <li>Aumentar el tiempo de renta segura </li>
            <li>Promoción SEO de su equipo dentro del Portal e Internet</li>
        </ul>
    }
];

export const form_fileds_tipos_planes = [
    {
        id: 1,
        nombreEsquema: 'Renta Abierta 1',
        icon: <MdTurnedIn/>,
        important_text: 
        <div>
            <p className="text-center text-white font-bold text-xlg">$99.00</p>
            <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Promociona hasta 5 de tus equipos</li>
            <li>Paga por mes sin dar comisión por tus rentas</li>
            <li>Trato directo con el cliente</li>
        </ul>
    },
    {
        id: 2,
        nombreEsquema: 'Renta Abierta 2',
        icon: <MdVerifiedUser/>,
        important_text: 
        <div>
            <p className="text-center text-white font-bold text-xlg">$149.00</p>
            <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Promociona hasta 10 de tus equipos</li>
            <li>Paga por mes sin dar comisión por tus rentas</li>
            <li>Trato directo con el cliente</li>
        </ul>
    },
    {
        id: 3,
        nombreEsquema: 'Renta Abierta 3',
        icon: <MdVerified/>,
        important_text: 
        <div>
            <p className="text-center text-white font-bold text-xlg">$199.00</p>
            <p className='text-center text-secondary-400 text-sm'>Mensuales</p>
        </div>,
        beneficios:
        <ul className='marker:text-primary-400 list-disc pl-5 space-y-2 text-slate-400'>
            <li>Promociona más de 15 de tus equipos</li>
            <li>Paga por mes sin dar comisión por tus rentas</li>
            <li>Trato directo con el cliente</li>
        </ul>
    }
];

export const form_fields_categorias = [
    {
        title: 'Información de la categoría',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre de la categoría',
                type: 'text',
                isRequired: true
            },
            {
                key: 'descripcion',
                label: 'Descripción',
                type: 'text',
                isRequired: false
            },
            {
                key: 'keywords',
                label: 'Keywords',
                type: 'text',
                isRequired: false
            }
        ]
    }
];

export const form_fields_equipos = (categorias, publicaciones, comprarPublicaciones, disabledOperadores, categoriasMaestras) => {
    return [
        {
            title:"Selecciona el esquema del equipo",
            fields:[
                {
                    key: 'esquemaRef',
                    label: 'Esquema',
                    type: 'select',
                    isRequired: true,
                    options: [
                                {value: 2, label: 'Renta Segura'},
                                {value: 1, label: 'Renta Abierta'},
                                ]
                }
            ],
            schema_text: [
                "",
                <p className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 text-sm bg-primary-50 p-4 rounded-lg flex flex-col items-center ">
                    <label className='font-bold text-primary-500 '>Renta Abierta</label> 
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Mostramos la información de contacto</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Las condiciones de la renta las pactarán directamente con el interesado</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Se cobra una membresía mensual</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Actualmente cuentas con {publicaciones} publicaciones disponibles, puedes comprar más <button className="underline text-blue-500 text-bold" type="button" onClick={comprarPublicaciones}>aquí</button></label>
                </p>,
                <p className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 text-sm bg-primary-50 p-4 rounded-lg flex flex-col items-center">
                    <label className='font-bold text-primary-500'>Renta Segura</label> 
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Trato directamente con el equipo de MeRent </label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Nosotros administramos el proceso de renta desde la contratación hasta su termino</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Nuestro equipo se pondrá en contacto contigo y te acompañará en el proceso de renta</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Renta Segura siempre incluye operador y servicios, así como reemplazo de piezas de desgaste</label>
                    <label className="w-full sm:pl-2 md:pl-12 lg:pl-40 xl:pl-40">Se cobra una pequeña comisión</label>
                </p>
            ]
        },{
            title: 'Información general del equipo',
            fields: [
                {
                    key: 'nombre',
                    label: 'Nombre del equipo',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'categoriaMaestraEquipoRef',
                    label: 'Categoria',
                    type: 'select',
                    isRequired: true,
                    options: categoriasMaestras
                },
                {
                    key: 'categoriaEquipoRef',
                    label: 'Sub-Categoria',
                    type: 'select',
                    isRequired: true,
                    options: categorias
                },
                {
                    key: 'descripcion',
                    label: 'Descripción',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'modelo',
                    label: 'Modelo',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'horometro',
                    label: 'Horometro',
                    type: 'number',
                    isRequired: true
                },
            ],
        },
        {
            title: 'Información para renta del equipo',
            fields: [
                {
                    key: 'precioDia',
                    label: 'Precio por día (MXN)',
                    type: 'number',
                    isRequired: true
                },
                {
                    key: 'precioSemana',
                    label: 'Precio por semana (MXN)',
                    type: 'number',
                    isRequired: true
                },
                {
                    key: 'precioQuincena',
                    label: 'Precio por quincena (MXN)',
                    type: 'number',
                    isRequired: true
                },
                {
                    key: 'precioMes',
                    label: 'Precio por mes (MXN)',
                    type: 'number',
                    isRequired: true
                },
                {
                    key: 'descuento',
                    label: '¿Incluye Operador?',
                    type: 'select',
                    isRequired: true,
                    disabled: disabledOperadores,
                    options: [{value: true, label: 'Sí',}, 
                            {value: false, label: 'No'}]
                }
            ]
        },
        {
            title: 'Imagenes',
            fields: [
                {
                    key: 'imagenPrincipal',
                    label: 'Imagen principal para el equipo',
                    type: 'file',
                    isRequired: true,
                    accept: "image/png, image/jpeg"
                },
                {
                    key: 'imagenes',
                    label: 'Otras imágenes a mostrar (Limite 3)',
                    type: 'file',
                    isRequired: false,
                    maxFilesQty: 3,
                    accept: "image/png, image/jpeg"
                }
            ]
        },
        {
            title: 'Factura o Pedimento de la máquina',
            fields: [
                {
                    key:'facturaPedimento',
                    label: 'Adjunta la fáctura o el pedimento de la máquina en formato PDF',
                    type: 'file',
                    isRequired: false,
                    accept: "application/pdf",
                    maxFilesQty: 1
                }
            ]
        }
    ]
};

export const form_fields_equipos_servicio = [
    {
        title: 'Información del servicio',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre del servicio',
                type: 'text',
                isRequired: true,
                repited: false,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },/* 
            {
                key: 'descripcion',
                label: 'Descripción',
                type: 'text',
                isRequired: true
            }, */
            {
                key: 'frecuencia',
                label: 'Frecuencia en que se debe realizar (Hrs)',
                type: 'number',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'incluye',
                label: '¿Se incluye en el servicio de la renta?',
                type: 'select',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3',
                options: [{value: true, label: 'Sí'}, 
                        {value: false, label: 'No'}]
            }
        ]
    }
];

export const form_fields_equipos_aditamentos = [
    {
        title: 'Información del aditamento',
        fields: [
            {
                key: 'nombre',
                label: 'Nombre del aditamento',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },/* 
            {
                key: 'descripcion',
                label: 'Descripción',
                type: 'text',
                isRequired: true
            }, */
            {
                key: 'frecuencia',
                label: 'Frecuencia (Hrs)',
                type: 'number',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'precio',
                label: 'Precio por día (MXN)',
                type: 'number',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            }
        ]
    }
];

export const form_fields_horarios = (equipos) => {
    return [
        {
            title: 'Seleccione el rango de fechas que desea bloquear',
            fields: [
                {
                    key: 'equipoRef',
                    label: 'Equipo',
                    type: 'select',
                    isRequired: true,
                    options: equipos
                },
                {
                    key: 'fechaInicio',
                    label: 'Fecha de inicio',
                    type: 'date',
                    isRequired: true
                },
                {
                    key: 'fechaFin',
                    label: 'Fecha de termino',
                    type: 'date',
                    isRequired: true
                }
            ]
        }
    ];
};


export const form_fields_operadores_admin = (estados, ciudades) => {
    return [
        {
            title: 'Información de contacto',
            fields: [
                {
                    key: 'nombresOperador',
                    label: 'Nombres',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'apellidosOperador',
                    label: 'Apellidos',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'correoElectronico',
                    label: 'Correo electrónico',
                    type: 'text',
                    isRequired: true,
                    regExp: RegExpEmail,
                },
                {
                    key: 'telefono',
                    label: 'Teléfono',
                    type: 'text',
                    isRequired: true,
                    regExp: RegExpCelular
                },
                {
                    key: 'estadoRef',
                    label: 'Estado',
                    type: 'select',
                    isRequired: true,
                    options: estados
                },
                {
                    key: 'ciudadRef',
                    label: 'Ciudad',
                    type: 'select',
                    isRequired: true,
                    options: ciudades
                },
                {
                    key: 'imagen_perfil',
                    label: 'Cambiar Imagen de Perfil',
                    type: 'file',
                    isRequired: false,
                    isFile: true,
                    accept: "image/png, image/jpeg"
                }
            ]
        }
    ];    
};

export const form_fields_operadores_perfil = (estados, ciudades) => {
    return [
        {
            title: 'Información de contacto',
            fields: [
                {
                    key: 'nombre',
                    label: 'Nombres',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'apellidos',
                    label: 'Apellidos',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'email',
                    label: 'Correo electrónico',
                    type: 'text',
                    isRequired: true,
                    regExp: RegExpEmail,
                },
                {
                    key: 'phoneNumber',
                    label: 'Teléfono',
                    type: 'text',
                    isRequired: true,
                    regExp: RegExpCelular
                },
                {
                    key: 'estado',
                    label: 'Estado',
                    type: 'select',
                    isRequired: true,
                    options: estados
                },
                {
                    key: 'ciudad',
                    label: 'Ciudad',
                    type: 'select',
                    isRequired: true,
                    options: ciudades
                },
                {
                    key: 'imagenPerfil',
                    label: 'Cambiar Imagen de Perfil',
                    type: 'file',
                    isRequired: false,
                    isFile: true,
                    accept: "image/png, image/jpeg"
                }
            ]
        }
    ];    
};

export const form_fields_renteros_perfil_direccion = (estados, ciudades) => {
    return [
        {
            title: 'Información de tu dirección',
            fields: [
                {
                    key: 'nombre',
                    label: 'Nombre de la dirección',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'direccionCompleta',
                    label: 'Dirección completa',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'id',
                    type: 'hidden',
                },
                {
                    key: 'calle',
                    label: 'Calle',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'numeroInterior',
                    label: 'Numero Interior',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'numeroExterior',
                    label: 'Numero Exterior',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'colonia',
                    label: 'Colonia',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'codigoPostal',
                    label: 'Codigo Postal',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'estadoRef',
                    label: 'Estado',
                    type: 'select',
                    isRequired: true,
                    options: estados
                },
                {
                    key: 'ciudadRef',
                    label: 'Ciudad',
                    type: 'select',
                    isRequired: true,
                    options: ciudades
                },
            ]
        }
    ]
}

export const form_fields_operadores_perfil_servicios = (categorias) =>  {
    return [
        {
            title: 'Información general de tus servicios',
            fields: [
                {
                    key: 'categoriaOperadorRef',
                    label: 'Servicio / Categoría',
                    type: 'select',
                    isRequired: true,
                    options: categorias
                },
                {
                    key: 'descripcion',
                    label: 'Descripción',
                    type: 'text',
                    isRequired: true
                }
            ]
        },
        {
            title: 'Precios',
            fields: [
                {
                    key: 'precioDia',
                    label: 'Día',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'precioSemana',
                    label: 'Semana',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'precioQuincena',
                    label: 'Quincena',
                    type: 'text',
                    isRequired: true
                },
                {
                    key: 'precioMes',
                    label: 'Mes',
                    type: 'text',
                    isRequired: true
                }
            ]
        }
    ];
}

export const form_fields_contrato = [
    {
        title: 'Datos arrendador',
        fields: [
            {
                key: 'nombreArrendador',
                label: 'Nombre arrendador',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'apoderadoLegalArrendador',
                label: 'Apoderado(s) o representante(s) legal(es) de la arrendador',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'polizaConstitucionArrendadora',
                label: 'Escritura o póliza en la que consta la constitución la arrendadora',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'polizaFacultadesArrendadora',
                label: 'Escritura o póliza en la que constan las facultades del (los) apoderado(s) o representante(s) legal(es) de la arrendadora',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'nacionalidadArrendador',
                label: 'Nacionalidad',
                type: 'text',
                isRequired: true
            },
            {
                key: 'lugarNacimientoArrendador',
                label: 'Lugar de nacimiento',
                type: 'text',
                isRequired: true
            },
            {
                key: 'fechaNacimientoArrendador',
                label: 'Fecha de nacimiento',
                type: 'date',
                isRequired: true
            },
            {
                key: 'curpArrendador',
                label: 'CURP',
                type: 'text',
                isRequired: true
            },
            {
                key: 'rfcArrendador',
                label: 'RFC',
                type: 'text',
                isRequired: true
            },
            {
                key: 'estadoCivilArrendador',
                label: 'Estado civil',
                type: 'text',
                isRequired: true
            },
            {
                key: 'domicilioArrendador',
                label: 'Domicilio',
                type: 'text',
                isRequired: true
            },
            {
                key: 'correoElectronicoArrendador',
                label: 'Correo electrónico',
                type: 'text',
                isRequired: true
            }
        ]
    },
    {
        title: 'Datos arrendatario',
        fields: [
            {
                key: 'nombreArrendatario',
                label: 'Nombre arrendatario',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'apoderadoLegalArrendatario',
                label: 'Apoderado(s) o representante(s) legal(es) de la arrendataria',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'polizaConstitucionArrendataria',
                label: 'Escritura o póliza en la que consta la constitución la arrendataria',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'polizaFacultadesApoderado',
                label: 'Escritura o póliza en la que constan las facultades del (los) apoderado(s) o representante(s) legal(es) de la arrendataria',
                type: 'text',
                isRequired: true,
                className: 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3'
            },
            {
                key: 'nacionalidadArrendatario',
                label: 'Nacionalidad',
                type: 'text',
                isRequired: true
            },
            {
                key: 'lugarNacimientoArrendatario',
                label: 'Lugar de nacimiento',
                type: 'text',
                isRequired: true
            },
            {
                key: 'fechaNacimientoArrendatario',
                label: 'Fecha de nacimiento',
                type: 'date',
                isRequired: true
            },
            {
                key: 'curpArrendatario',
                label: 'CURP',
                type: 'text',
                isRequired: true
            },
            {
                key: 'rfcArrendatario',
                label: 'RFC',
                type: 'text',
                isRequired: true
            },
            {
                key: 'estadoCivilArrendatario',
                label: 'Estado civil',
                type: 'text',
                isRequired: true
            },
            {
                key: 'domicilioArrendatario',
                label: 'Domicilio',
                type: 'text',
                isRequired: true
            },
            {
                key: 'correoElectronicoArrendatario',
                label: 'Correo electrónico',
                type: 'text',
                isRequired: true
            }
        ]
    },
    {
        title: 'Información de la renta',
        fields: [
            {
                key: 'importeRenta',
                label: 'Importe de la renta',
                type: 'number',
                isRequired: true,
                disabled: false
            },
            {
                key: 'periodicidad',
                label: 'Periodicidad',
                type: 'text',
                isRequired: true,
                disabled: true
            },
            {
                key: 'vigenciaRenta',
                label: 'Vigencia de la renta',
                type: 'text',
                isRequired: true,
                disabled: true
            },
            {
                key: 'fechaPago',
                label: 'Fecha de pago',
                type: 'date',
                isRequired: true,
                disabled: true
            }
        ]
    }
];

export const form_fields_obra = () => {
    return [
        {
            title: 'Información para agregar nueva subcomunidad especial',
            fields: [
                {
                    key: 'nombre',
                    label: 'Nombre subcomunidad especial',
                    type: 'text',
                    isRequired: true,
                },
                {
                    key: 'ubicacion',
                    label: 'Ubicacion de la subcomunidad especial',
                    type: 'text',
                    isRequired: true,
                },
                
            ]
        }
    ];    
};