import React, { useState, useEffect } from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import Datagrid from '../UI/Datagrid';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { grid_columns_estados, grid_columns_ciudades } from '../helpers/grid_columns';
import { merent_instance } from '../helpers/axios_helper';

function EstadosCiudades() {
    const [modalCambiarEstatusEstado, setModalCambiarEstatusEstado] = useState(false);
    const [modalCambiarEstatusCiudad, setModalCambiarEstatusCiudad] = useState(false);
    const [currentEstado, setCurrentEstado] = useState({
        "id": 0,
        "nombre": "",
        "activo": true
    });
    const [currentCiudad, setCurrentCiudad] = useState({
        "id": 0,
        "nombre": "",
        "estado": "",
        "activo": true
    });
    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    useEffect(() => {
        obtenerEstados();
        obtenerCiudades();
    }, []);

    const obtenerEstados = () => {
        setCurrentEstado({
            "id": 0,
            "nombre": "",
            "activo": true
        });
        merent_instance().get("api/direcciones/estados/true").then(({data}) => {
            setEstados(data);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al traer los estados")
        });
    }

    const obtenerCiudades = () => {
        setCurrentCiudad({
            "id": 0,
            "nombre": "",
            "estado": "",
            "activo": true
        });
        merent_instance().get("api/direcciones/ciudades/true").then(({data}) => {
            setCiudades(data);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al traer las ciudades")
        });
    }

    const editEstado = (item) =>  {
        console.log(item);
        setCurrentEstado(item);
        setModalCambiarEstatusEstado(true);
    };

    const editCiudad = (item) =>  {
        setCurrentCiudad(item);
        setModalCambiarEstatusCiudad(true);
    };

    const cambiarEstatusEstado = () => {
        console.log(currentEstado);
        const estado_id = currentEstado.id;
        merent_instance().patch("api/direcciones/estados/"+estado_id+"/status").then(({data}) => {
            console.log(data);
            toast.success("Estatus modificado exitosamente");
            obtenerEstados();
            setModalCambiarEstatusEstado(false);
        }).catch(error => {
            console.log(error)
            toast.error("Hubo un error al guardar el estado")
        })
    };

    const cambiarEstatusCiudad = () => {
        console.log(currentCiudad);
        const ciudad_id = currentCiudad.id;
        merent_instance().patch("api/direcciones/ciudades/"+ciudad_id+"/status").then(({data}) => {
            console.log(data);
            toast.success("Estatus modificado exitosamente");
            obtenerCiudades();
            setModalCambiarEstatusCiudad(false);
        }).catch(error => {
            console.log(error)
            toast.error("Hubo un error al guardar LA CIUDAD")
        })
    };

    return (
        <Container>
            <section className="grid grid-cols-12 gap-x-2 gap-y-1">
                <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 xxl:col-span-5">
                    <Datagrid 
                        title="Estados"
                        header={grid_columns_estados(editEstado)}
                        data={estados}
                        height={700}
                        headerHeight={40}
                        showFilters={false}
                    />
                </div>
                <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 xxl:col-span-7">
                    <Datagrid 
                        title="Ciudades"
                        header={grid_columns_ciudades(editCiudad)}
                        data={ciudades}
                        height={700}
                        headerHeight={40}
                        showFilters={false}
                    />
                </div>
            </section>
            
            <Modal show={modalCambiarEstatusEstado} setShow={setModalCambiarEstatusEstado} title="Cambiar estatus Estado" className="max-w-2xl">
                <header className="w-full py-3 text-center">
                    <p>¿Seguro que deseas cambiar el estatus del Estado '<span className='font-bold'>{currentEstado.nombre}</span>' a <span className={`font-bold ${currentEstado.activo == 'Activo' ? 'text-red-600' : 'text-green-600'}`}>{currentEstado.activo == 'Activo' ? 'Inactivo' : 'Activo'}</span>?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-secondary-400" type="button" text="Cancelar" clickFn={() => {setModalCambiarEstatusEstado(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-400" type="button" text="Cambiar estatus" clickFn={cambiarEstatusEstado}/>
                </section>
            </Modal>

            <Modal show={modalCambiarEstatusCiudad} setShow={setModalCambiarEstatusCiudad} title="Cambiar estatus Ciudad" className="max-w-2xl">
                <header className="w-full py-3 text-center">
                    <p>¿Seguro que deseas cambiar el estatus de la Ciudad '<span className='font-bold'>{currentCiudad.nombre}</span>' a <span className={`font-bold ${currentCiudad.activo == 'Activo' ? 'text-red-600' : 'text-green-600'}`}>{currentCiudad.activo == 'Activo' ? 'Inactivo' : 'Activo'}</span>?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-secondary-400" type="button" text="Cancelar" clickFn={() => {setModalCambiarEstatusCiudad(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-primary-400" type="button" text="Cambiar estatus" clickFn={cambiarEstatusCiudad}/>
                </section>
            </Modal>
        </Container>
    )
}

export default EstadosCiudades;