import React, { useState, useEffect } from 'react';
import BasicButton from '../Buttons/BasicButton';

function Step({
            currentStep,
            firstStep,
            goToStep,
            lastStep,
            nextStep,
            previousStep,
            totalSteps,
            step,
            children,
            save,
            validaPaso,
    }) 
{
    const handlePrevious = () => {
        if(currentStep == firstStep)
            return;
        
        previousStep();
    }

    const handleNext = () => {
        if(currentStep  == lastStep)
            return;

        if(validaPaso)
            if(!validaPaso())
                return;

        nextStep();
    }

    const handleGuardar = () => {
        save();
    }
    return (
<>
            <header className="text-center">
                {children}
            </header>
            {currentStep == 1 ? '' : <BasicButton type="button" clickFn={handlePrevious} text={"Anterior"}/>}
            {currentStep == totalSteps ? <BasicButton type="button" clickFn={() => handleGuardar()} text={"Guardar"}/> : <BasicButton type="button" clickFn={handleNext} text={"Siguiente"}/>}
        </>
    );
}

export default Step;