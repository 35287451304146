import React from 'react'
import Carousel from '../Carousel';

function Card({title, subTitle, description, children, images}) {
    return(
        <div className="rounded-lg bg-white overflow-hidden shadow-lg">
            <div className="px-6 py-4">
                {
                    images !== undefined ? 
                    <Carousel images={images}/>
                    :
                    <></>
                }

                <p className="font-bold text-4xl m-2 text-center">
                    {title} 
                </p>
                <small >{subTitle}</small>
                <p className="text-gray-700 text-xl  ">
                    {description}
                </p>
            </div>
            {children}
        </div>
    )
}

export default Card;