import React, { useEffect, useState } from 'react';

function FilePreview ({files, type}) {
    const [filesPreview, setFilesPreview] = useState(null);
    var valid_images = ["jpg", "jpeg", "png", "image/jpeg", "image/png"];
    var valid_files = ["pdf", "application/pdf"];

    useEffect(() =>{
        if(files !== undefined && files !== null && files.length > 0 && typeof files != 'string'){
            var current_files = Array.from(files).map((file) => {
                var fileType = type === "preview" ? file.type : get_url_extension(file.url);
                var url = type === "preview" ? URL.createObjectURL(file) : file.url;
                return type === "preview" ? {fileType: fileType, fileName: file.name, fileUrl: url}
                : {fileType: fileType, fileName: file.indice, fileUrl: url}
            })
            setFilesPreview(current_files);
        } else {
            if(files !== null && files != undefined)
            {
                var fileType = type === "preview" ? files.type : get_url_extension(files.url);
                var url = type === "preview" ? URL.createObjectURL(files) : files.url;
                var files_new = type === "preview" ? [{fileType: fileType, fileName: files.name, fileUrl: url}]
                : [{fileType: fileType, fileName: files.indice, fileUrl: url}]
                
                if(files_new[0].fileUrl === undefined)
                    files_new[0].fileUrl = files;
                if(files_new[0].fileType === undefined)
                    files_new[0].fileType = "jpg";

                setFilesPreview(files_new);
            }
        }
    }, [files, type]);

    function get_url_extension( url ) {
        if(url)
            return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    return (
        <div className="grid grid-cols-3 gap-3 items-center justify-center align-middle">
            {
                filesPreview !== undefined && filesPreview !== null && filesPreview !== "" ?
                    filesPreview.map((file, index) =>{
                        if (valid_images.indexOf(file.fileType) >= 0){
                            return <img key={index} className='max-h-96' src={file.fileUrl} alt={file.fileName}/>
                        }
                        if (valid_files.indexOf(file.fileType) >= 0){
                            return <embed key={index} className="w-1/2 h-96" src={file.fileUrl}/>
                        }
                        else{
                            return <p key={index} > Archivo no reconocido: {file.fileName} </p>
                        }   
                    })
                :
                ''
            }
        </div>
    );
}

export default FilePreview;