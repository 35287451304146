import React, {useState, useEffect} from 'react'
import useUpdateEffect from '../../Hooks/useUpdate';

const FileInput = ({onFileSelectSuccess, onFileSelectError, amount, filesParameter, filesText}) => {

    const [files, setFiles] = useState([]);
    const [count, setCount] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);

    useUpdateEffect(() => {
      setCount(files.length)
    },[files,count]);

    useEffect(() => {
      setFiles(filesParameter === undefined ? [] : filesParameter);
    }, [filesParameter]);

    const handleFileInput = (e) => {
      // handle validations (validate if the file is pdf and the size of the file, 3MB max)
      if (e.target.files) {
        
        if( (e.target.files.length + files.length) < amount + 1){
          const filesArray = Array.from(e.target.files).map((file) =>{
            if(file.size > 	3145728){
              onFileSelectError({ error: "El tamaño maximo del archivo debe de ser de 3MB" });
              return 'null'  
            }
            else{
              return {'url':URL.createObjectURL(file), 'size':file.size, 'name':file.name, 'file':file }
            }                      
          });
          
          setFiles((prevImages) => prevImages.concat(filesArray));
          
          onFileSelectSuccess(filesArray);
          
          Array.from(e.target.files).map(
            (file) => URL.revokeObjectURL(file) // avoid memory leak
          );
        }
        else{
          onFileSelectError({ error:`Solo pueden haber ${amount} elemento(s)`});
          return 'null'
        }          
      }
    }

    const handleDelete = (e) => {
      files.splice(e.target.dataset.target,1);
      console.log(files);
      setFiles(files);
      onFileSelectSuccess(files);
      setForceUpdate(!forceUpdate)
    }
  
    const renderPhotos = (source) => {
      //en caso de un archivo incorrecto o erroneo eliminarlo o crerar un elemento donde se especifique.
        return source.map((image,i) => {
          return  <div className="flex-1 border-2 mx-2 rounded-md " id={i} key={i} >
                    <ul className="flex flex-1 flex-wrap -m-1">
                      <li className="group h-full w-full text-center flex flex-col items-center justify-center shadow group-hover:overlay-gray-200">
                        <article tabIndex="0" className="group hasImage rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm">
                          <img alt={`upload preview ${image.name}`} src={image.url} key={image.url} className="img-preview w-full h-full max-h-48 sticky object-cover rounded-md bg-fixed" />

                          <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                            <h2 className="flex-1">{image.name}</h2>
                            <div className="flex">
                              <span className="p-1 inline">
                                <i>
                                  <svg className="fill-current w-4 h-4 ml-auto pt-" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
                                  </svg>
                                </i>
                                {image.size}
                              </span>

                              <p className="p-1 size text-xs"></p>
                              <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md" onClick={handleDelete} data-target={i}>
                                <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                  <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                </svg>
                              </button>
                            </div>
                          </section>
                        </article>
                      </li>
                    </ul>                   
                  </div>;
        });
    };

    return (
        <>
            <div className="border-dashed border-2 border-gray-300 rounded bg-gray-200  w-full h-full text-center  items-center justify-center content-center ">
              <h2 className="underline text-black font-semibold text-opacity-50 my-5">{filesText}</h2>
              <div className="mx-auto w-full">
                <label className="flex w-32 py-2 mx-auto items-center justify-center content-center border-primary-400 rounded-md bg-white text-primary-400  shadow-md tracking-wide cursor-pointer hover:bg-primary-400 hover:text-white">
                  <p className=" text-base font-semibold leading-normal">Seleccionar</p>
                  <input type='file' className="hidden px-4 py-5 mt-2 w-full " onChange={handleFileInput} multiple/>
                </label>
              </div>
              
              <input type="file" className="opacity-0" onChange={handleFileInput} multiple/>
              <div className="flex border-2 w-full">
                  {renderPhotos(files)}
              </div>
                
            </div>
        </>
    )
}

export default FileInput;