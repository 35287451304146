import React, { useState, useEffect } from 'react';
import BasicInput from '../UI/Inputs/BasicInput';
import StepWizard from "react-step-wizard";
import Step from '../UI/Wizard/Step';
import Nav from '../UI/Wizard/Nav';
import FileInput from '../UI/Inputs/FileInput';
import { FaSpinner } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { RegExpEmail, RegExpCelular, RegExpPassword } from '../helpers/constants';
import { merent_instance } from '../helpers/axios_helper';

function Register() {
    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [checkAviso, setCheckAviso] = useState(false);
    const [checkTerminos, setCheckTerminos] = useState(false);


    const [formData, setFormData] = useState({
        "Nombre": "",
        "Apellidos": "",
        "NombreEmpresa": "",
        "RazonSocial": "",
        "Descripcion": "",
        "Clabe": "0",
        "RFC": "",
        "SituacionFiscal": "NA",
        "PlanRenteroRef": 1
    });

    const [formDataDireccion, setFormDataDireccion] = useState({
        "Nombre": "PRINCIPAL",
        "Calle": "",
        "NumeroInterior": "",
        "NumeroExterior": "",
        "Colonia": "",
        "CiudadRef": "",
        "CodigoPostal": "",
        "Estado": "",
        "Latitud": 0,
        "Longitud": 0
    });

    const [formDataUsuario, setFormDataUsuario] = useState({
        "Nombre": "",
        "Email": "",
        "Telefono": "",
        "Password": "",
        "ConfirmaPassword": ""
    });
    
    const [selectedFileIFE, setSelectedFileIFE] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [usuarioRef, setUsuarioRef] = useState("");
    const navigation = useNavigate();

    useEffect(() => {
        obtenerEstados();
    }, []);

    function allPropsEmptyString(object) {
        return Object.values(object).filter(value => value === undefined || value === null || value === "");
    }

    function validaPaso1(){
        if(usuarioRef !== "")
            return true;

        formDataUsuario.Nombre = formDataUsuario.Email;
        if(allPropsEmptyString(formDataUsuario).length > 0 || formData.Nombre === "" || formData.Apellidos === ""){
            toast.error("Todos los campos son obligatorios");
            return false;
        }
        if(!formDataUsuario.Email.match(RegExpEmail))
        {
            toast.error("Ingresa una direccion de correo valida");
            return false;
        }
        if(!formDataUsuario.Telefono.match(RegExpCelular) || formDataUsuario.Telefono.length != 10)
        {
            toast.error("El número de teléfono no es valido");
            return false;
        }

        if(!formDataUsuario.Password.match(RegExpPassword)){
            toast.error("La contraseña debe tener mínimo 8 caracteres, una minúscula, una mayúscula y un número");
            return false;
        }
        if(formDataUsuario.Password !== formDataUsuario.ConfirmaPassword){
            toast.error("Las contraseñas no coinciden");
            return false;
        }
        return true;
    }

    function validaPaso2(){
        if(allPropsEmptyString(formData).length > 0 || allPropsEmptyString(formDataDireccion).length > 0){
            toast.error("Todos los campos son obligatorios");
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if(!checkTerminos){
            toast.error("Debes aceptar nuestros terminos y condiciones para continuar");
            return;
        }
        if(!checkAviso){
            toast.error("Debes aceptar nuestros terminos y condiciones para continuar");
            return;
        }
        setIsCreating(true);
        var direccionCompleta = formDataDireccion.Calle.trim() + " Número " + formDataDireccion.NumeroExterior + ", Colonia " + formDataDireccion.Colonia + ",  " + formDataDireccion.CiudadRef;
        if(usuarioRef !== "")
        {
            crearPerfilRentero(direccionCompleta);
            return
        }
        const body_data = formData;
        body_data.SituacionFiscal = "NA";
        body_data.Direccion = formDataDireccion;
        body_data.Direccion.DireccionCompleta = direccionCompleta;
        body_data.Usuario = formDataUsuario;


        merent_instance().post("api/usuarios/rentero", body_data).then(({data}) => {
            setIsCreating(false);
            subirDocumentosRegistro(data);
            toast.success("Registro realizado correctamente, regresando a pagina de Inicio de Sesión", {duration: "5"});
            setTimeout(() => {navigation("/")}, 5000);
        }).catch((error) => {
            setIsCreating(false);
            if(error.response.status >= 500)
                toast.error("Hubo un error al crear el registro, porfavor intenta nuevamente más tarde.");
            else
                toast.error(error.response.data);
        });
    }

    const subirDocumentosRegistro = (id) => {
        let form_data = new FormData();
        form_data.append("imagenes", formData.SituacionFiscal[0]);

        selectedFileIFE.map((e) => {
            form_data.append("imagenes", e.file);
        });

        merent_instance().post(`api/renteros/${id}/documentos`, form_data, {
            "Content-Type": "multipart/form-data",
        }).then(({data}) => {
            console.log("result", data);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const crearPerfilRentero = (direccionCompleta) => {
        const body_data = formData;
        body_data.Direccion = formDataDireccion;
        body_data.Direccion.DireccionCompleta = direccionCompleta;
        body_data.UsuarioRef = usuarioRef;


        merent_instance().post("api/usuarios/rentero/perfil", body_data).then(({data}) => {
            setIsCreating(false);
            toast.success(data);
            setTimeout(() => {navigation("/")}, 2000);
        }).catch((error) => {
            setIsCreating(false);
            if(error.response.status >= 500)
                toast.error("Hubo un error al crear el registro, porfavor intenta nuevamente más tarde");
            else
                toast.error(error.response.data);
        });
    }

    const obtenerEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const obtenerCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const seleccionaEstado = (value) => {
        setFormDataDireccion({...formDataDireccion, Estado: value});
        obtenerCiudades(value);
    }

    const validarUsuario = (email) => {
        if(email !== ""){
            merent_instance().get(`api/usuarios/rentero/valida_cliente/${email}`).then(({data}) => {
                const {existe_cliente, existe_rentero} = data;
                console.log(data);
                if(!existe_cliente) {
                    return;
                }
                
                if(!existe_cliente && !existe_rentero) {
                    return;
                }

                if(existe_cliente && !existe_rentero) {
                    toast.success("Se encontró una cuenta cliente existente, se cargará tu información de contacto para tu perfil de rentero.");
                    setFormDataUsuario({...formDataUsuario, Telefono: data.telefono});
                    setFormData({...formData, Nombre: data.nombre});
                    setUsuarioRef(data.usuarioRef)
                    return;
                }

                if(existe_cliente && existe_rentero) {
                    toast.error("Ya existe una cuenta registrada con este correo electronico, puedes recuperar tu contraseña en el sitio principal");
                    return;
                }

            }).catch((error) => {
                console.log('Error al validar email', error);
            });
        }
    }

    return (
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-construction-pattern bg-contain bg-origin-content drop-shadow-sm ">
            <div className="relative h-[50rem] mx-5 md:w-3/4 lg:w-3/4 xl:w-3/4 xs:w-full sm:w-full">
                <h1 className='text-2xl text-primary-400 font-semibold text-center'>Regístrate como rentero</h1>
                <div className="absolute w-full h-full bg-white flex justify-center items-center rounded-lg border-small border-primary-200 shadow-md shadow-primary-100 sm:overflow-auto xs:overflow-auto">
                    <StepWizard
                        className='w-full h-full p-12'
                        nav={<Nav/>}>
                        <Step validaPaso={validaPaso1}>
                            <p className="text-lg text-gray-500 font-semibold">Información de contacto</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa tu información personal para iniciar tu registro</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 mb-3 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Correo electronico" value={formDataUsuario.Email} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Email: e.target.value})} onBlur={(e)=> validarUsuario(e.target.value)}/>
                                <BasicInput type="number" placeholder="Telefono de contacto" value={formDataUsuario.Telefono} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Telefono: e.target.value})}/>
                                <BasicInput type="text" placeholder="Nombre(s)" value={formData.Nombre} setValue={(e)=> setFormData({...formData, Nombre: e.target.value})}/>
                                <BasicInput type="text" placeholder="Apellido(s)" value={formData.Apellidos} setValue={(e)=> setFormData({...formData, Apellidos: e.target.value})} autoComplete={"new-password"}/>
                            </section>
                            <p className="text-sm text-gray-600 font-semibold">Información de la cuenta</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 mb-12 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="password" placeholder="Contraseña" defaultValue={formDataUsuario.Password} setValue={(e)=> setFormDataUsuario({...formDataUsuario, Password: e.target.value})} autoComplete={"new-password"}/>
                                <BasicInput type="password" placeholder="Confirmar contraseña" defaultValue={formDataUsuario.ConfirmaPassword} setValue={(e)=> setFormDataUsuario({...formDataUsuario, ConfirmaPassword: e.target.value})} autoComplete={"new-password"}/>
                            </section>
                        </Step>
                        <Step validaPaso={validaPaso2}>
                            <p className="text-lg text-gray-500 font-semibold">Información de tu negocio</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa la información de tu negocio</p>
                            <section className="grid grid-cols-2 grid-rows-1 gap-x-2 gap-y-0.5 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Nombre de la empresa o negocio" defaultValue={formData.NombreEmpresa} setValue={(e)=> setFormData({...formData, NombreEmpresa: e.target.value})}/>
                                <BasicInput type="text" placeholder="Razón social" defaultValue={formData.RazonSocial} setValue={(e)=> setFormData({...formData, RazonSocial: e.target.value})}/>
                                <BasicInput type="text" placeholder="RFC" defaultValue={formData.RFC} setValue={(e)=> setFormData({...formData, RFC: e.target.value})}/>
                                <p className="md:col-span-2 lg:col-span-2 xl:col-span-2">
                                    Constancia de situación fiscal
                                    <BasicInput type="file" placeholder="Situación Fiscal" setValue={(e) => setFormData({...formData, SituacionFiscal: e.target.files})}/>
                                </p>
                            </section>
                            <section className="grid grid-cols-1 grid-rows-1 gap-x-2 gap-y-0.5">
                                <BasicInput type="text" placeholder="Breve descripcion de tu negocio" defaultValue={formData.Descripcion} setValue={(e)=> setFormData({...formData, Descripcion: e.target.value})}/>
                            </section>
                            <p className="text-sm text-gray-600 font-semibold">Dirección principal</p>
                            <section className="grid grid-cols-3 grid-rows-2 gap-x-2 gap-y-0.5 sm:grid-cols-1 xs:grid-cols-1">
                                <BasicInput type="text" placeholder="Calle" defaultValue={formDataDireccion.Calle} setValue={(e)=> setFormDataDireccion({...formDataDireccion, Calle: e.target.value})}/>
                                <BasicInput type="text" placeholder="Numero Interior" defaultValue={formDataDireccion.NumeroInterior} setValue={(e)=> setFormDataDireccion({...formDataDireccion, NumeroInterior: e.target.value})}/>
                                <BasicInput type="number" placeholder="Numero Exterior" defaultValue={formDataDireccion.NumeroExterior} setValue={(e)=> setFormDataDireccion({...formDataDireccion, NumeroExterior: e.target.value})}/>
                                <BasicInput type="text" placeholder="Colonia" defaultValue={formDataDireccion.Colonia} setValue={(e)=> setFormDataDireccion({...formDataDireccion, Colonia: e.target.value})}/>
                                
                                <BasicInput type="select" placeholder="Estado"
                                        options={estados}
                                        defaultValue={formDataDireccion.Estado} 
                                        setValue={(e)=> seleccionaEstado(e.target.value)}/>
                                <BasicInput type="select" placeholder="Ciudad" 
                                    options={ciudades}
                                    defaultValue={formDataDireccion.CiudadRef} 
                                    setValue={(e)=> setFormDataDireccion({...formDataDireccion, CiudadRef: e.target.value})}/>
                                <BasicInput type="number" placeholder="Codigo Postal" defaultValue={formDataDireccion.CodigoPostal} setValue={(e)=> setFormDataDireccion({...formDataDireccion, CodigoPostal: e.target.value})}/>

                            </section>
                        </Step>
                        <Step save={handleSave}>
                            <p className="text-lg text-gray-500 font-semibold">Documentos</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, adjunta los siguientes documentos para poder comprobar la veracidad de tu negocio</p>
                            <div className='m-0 max-h-[250px] overflow-auto'>
                                <FileInput 
                                onFileSelectSuccess={(file) => setSelectedFileIFE(file)}
                                onFileSelectError={({ error }) => alert(error)}
                                amount={2}
                                filesText="Adjunta hasta 2 imagenes de tu INE"
                                />
                            </div>
                            
                            <div className="grid grid-cols-1 grid-rows-1 mt-4 ">
                                <p><input value={checkTerminos} onChange={(ev) => setCheckTerminos(ev.target.checked)} type="checkbox"/> Al crear tu cuenta aceptas nuestros <a className="font-bold text-blue-400 underline" target='_blank' href="https://merent.mx/terminos-condiciones">Terminos y condiciones</a></p>
                                <p><input value={checkAviso} onChange={(ev) => setCheckAviso(ev.target.checked)} type="checkbox"/> Como Titular de los Datos Personales proporcionados a la Plataforma MeRent App, manifiesto que he leído el presente <a className="font-bold text-blue-400 underline" target="_blank" href="https://merent.mx/aviso-privacidad">Aviso de Privacidad</a>, y que conozco las finalidades del mismo, por lo cual consiento expresa y libremente el tratamiento de mis Datos Personales en los términos precisados en este <a className="font-bold text-blue-400 underline" target="_blank" href="https://merent.mx/aviso-privacidad">Aviso de Privacidad</a>.</p>
                            </div>
                            {
                                isCreating ?
                                <center>
                                    <p className="bg-white text-gray-700 py-0 m-0 rounded relative">
                                        <FaSpinner icon="spinner" className="text-primary-400 animate-spin text-3xl m-4" />
                                        Registro en proceso
                                    </p>
                                </center>
                                :
                                <></>
                            }
                        </Step>
                    </StepWizard>
                </div>
            </div>
        </div>
    );
}

export default Register;