import React from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent} from 'react-pro-sidebar';
import { FaBars, FaHome, FaUserAlt, FaUsers, FaHandsHelping, FaSignOutAlt, FaCheckSquare, FaMoneyCheckAlt, FaCalendarAlt, FaMapMarkedAlt } from 'react-icons/fa';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { MdSpaceDashboard, MdCategory } from "react-icons/md";
import { MdVerified } from 'react-icons/md';
import { GiForklift } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";
import Logo from '../../../assets/merent-logo.png';
import { ReactComponent as RoboArm } from '../../../assets/robo-arm.svg';

const Aside = ({ handleCollapsedChange, collapsed, toggled, handleToggleSidebar, profile }) => {
    const location = useLocation();

    const sidebarItems = [
        {
            route: '/home',
            text: 'Inicio',
            icon: <FaHome/>,
            profile: 1
        },
        {
            route: '/dashboard',
            text: 'Dashboard',
            icon: <MdSpaceDashboard/>,
            profile: 2
        },
        {
            route: '/dashboard-operador',
            text: 'Dashboard',
            icon: <MdSpaceDashboard/>,
            profile: 3
        },
        {
            route: '/renteros',
            text: 'Renteros',
            icon: <FaUsers/>,
            profile: 1
        },
        {
            route: '/clientes',
            text: 'Clientes',
            icon: <FaUserAlt/>,
            profile: 1
        },
        {
            route: '/operadores',
            text: 'Operadores',
            icon: <GrUserWorker/>,
            profile: 1
        },
        {
            route: '/rentas',
            text: 'Rentas',
            icon: <RoboArm className="h-5 w-5 font-bold fill-current"/>,
            profile: 0
        },
        {
            route: '/aprobar',
            text: 'Aprobación de equipos',
            icon: <FaCheckSquare/>,
            profile: 1
        },
        {
            route: '/admin-equipos',
            text: 'Listado de equipos',
            icon: <GiForklift className="h-5 w-5 font-bold fill-current"/>,
            profile: 1
        },
        {
            route: '/estados-ciudades',
            text: 'Estados/Ciudades',
            icon: <FaMapMarkedAlt/>,
            profile: 1
        },
        {
            route: '/categorias',
            text: 'Categorias',
            icon: <MdCategory/>,
            profile: 1
        },
        {
            route: '/categoriasoperador',
            text: 'Categorias Operador',
            icon: <MdCategory/>,
            profile: 1
        },
        {
            route: '/equipos',
            text: 'Equipos',
            icon: <GiForklift className="h-5 w-5 font-bold fill-current"/>,
            profile: 2
        },
        {
            route: '/horarios',
            text: 'Horarios',
            icon: <FaCalendarAlt/>,
            profile: 2
        },
        {
            route: '/pagos',
            text: 'Pagos',
            icon: <FaMoneyCheckAlt/>,
            profile: 0
        },
        {
            route: '/soporte',
            text: 'Soporte',
            icon: <FaHandsHelping/>,
            profile: -1
        },
        {
            route: '/logout',
            text: 'Salir',
            icon: <FaSignOutAlt/>,
            profile: -1
        },
    ];

    return (
        <ProSidebar
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="md"
            onToggle={handleToggleSidebar}
        >
        <SidebarHeader>
            <div>
                {!collapsed ?
                    <div className="flex flex-col items-center justify-center">
                        <span onClick={() => handleCollapsedChange(!collapsed)} className="w-full mr-10 mt-2 mb-0 px-1 pt-5 text-primary-700 mx-0 my-auto h-4 flex items-end justify-end text-ellipsis overflow-hidden cursor-pointer ">
                            <FaBars />
                        </span>
                        <NavLink to="/home" className="text-white flex items-center px-4 justify-center">
                            <img src={Logo} className="h-24" alt="Logo"/>
                        </NavLink>
                        <p className="text-gray-500 items-center justify-center px-1 mb-1">Bienvenido </p>
                        <p className="text-gray-500 items-center justify-center px-2 mb-4"><MdVerified className="inline mb-1 text-green-400"/> Cuenta verificada</p>
                    </div>
                :
                    <div className="flex items-center justify-center">
                        <span onClick={() => handleCollapsedChange(!collapsed)} className="w-full px-1 py-5 text-primary-700 mx-0 my-auto h-4 flex items-center justify-center text-ellipsis overflow-hidden  cursor-pointer">
                        <FaBars />
                        </span>
                    </div>
                }
            </div>
        </SidebarHeader>

        <SidebarContent>
            <Menu iconShape="none">
                {sidebarItems.map((item, index) => {
                    if((item.profile === profile || item.profile === -1 || item.profile === 0) && (profile === 3 ? item.profile !== 0 : true)){
                        if(!collapsed){
                            return (
                                <MenuItem key={index} onClick={() => {handleToggleSidebar(false)}}>
                                    <Link to={item.route} className={`flex flex-row py-2 px-3 rounded items-center  hover:bg-primary-100 ${location.pathname === item.route ? 'bg-primary-100' : ''}`}>
                                        <span className="text-lg mr-2 mt-1">{item.icon}</span>
                                        <span className="text-md mt-1 text-primary-800">{item.text}</span>
                                    </Link>
                                </MenuItem>            
                            )
                        }
                        else{
                            return (
                                <MenuItem key={index} onClick={() => {handleToggleSidebar(false)}}>
                                    <Link to={item.route} className={`flex ml-0 py-3 px-4 rounded md:items-start md:content-start md:justify-start hover:bg-primary-100 ${location.pathname === item.route ? 'bg-primary-100' : ''}`}>
                                        <span className="text-lg mr-2 mt-1">{item.icon}</span>
                                    </Link>
                                </MenuItem>            
                            )
                        }
                    }
                })}
            </Menu>
        </SidebarContent>
        
        {
            profile === 1 ?
            ''
            :
            <SidebarFooter className="my-3">
                {
                    collapsed ? 
                        <Link to={profile === 2 ? "/perfil" : "/perfil-operador"} className={`flex ml-0 py-3 px-4 rounded justify-center hover:bg-primary-100 hover:text-primary-800 hover:no-underline ${location.pathname === "/perfil" ? 'bg-primary-100' : ''}`}>
                            <span className="text-lg mr-2 mt-1"><FaUserAlt /></span>
                        </Link>
                    :
                        <Link to={profile === 2 ? "/perfil" : "/perfil-operador"} className={`flex py-3 px-3 rounded justify-center hover:bg-primary-100 hover:text-primary-800 hover:no-underline ${location.pathname === "/perfil" ? 'bg-primary-100' : ''}`}>
                            <span className="text-lg mr-2 mt-1"><FaUserAlt /></span>
                            <span className="text-md mt-2 md:mt-0 text-primary-800">Mi cuenta</span>
                        </Link>
                }
            </SidebarFooter>
        }
          
    </ProSidebar>
  );
};

export default Aside;