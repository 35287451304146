import React from 'react';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Datagrid from '../UI/Datagrid';
import { grid_columns_soporte } from '../helpers/grid_columns';
import { dummy_data_soporte } from '../helpers/dummy_data';

function Soporte() {

    const pruebaClick = () => {
        window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaIframeContainer",
      //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
            allowTokenization: true, 
            checkoutRequestId: "d568ada2-96d0-4607-a071-ec84696feba4", // // Checkout request ID, es el mismo ID generado en el paso 1
            publicKey: "key_M7DvGxvZeUhpQdVBOyThYHP", // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
            options: {
              styles: {
      // inputType modifica el diseño del Web Checkout Tokenizer
      //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
                inputType: 'rounded',
      // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
      //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
                buttonType: 'rounded',
      //Elemento que personaliza el borde de color de los elementos            
                states: {
                  empty: {
                    borderColor: '#FFAA00' // Código de color hexadecimal para campos vacíos
                  },
                  invalid: {
                    borderColor: '#FF00E0' // Código de color hexadecimal para campos inválidos
                  },
                  valid: {
                    borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
                  }
                }
              },
              languaje: 'es', // 'es' Español | 'en' Ingles
      //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
              button: {
                colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
                //text: 'Agregar Tarjeta***', //Nombre de la acción en el botón ***Se puede personalizar
                backgroundColor: '#301007' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
              },
      //Elemento que personaliza el diseño del iframe
              iframe: {
                colorText: '#65A39B',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
                backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
              }
            },
            onCreateTokenSucceeded: function(token) {
              console.log(token)
            },
            onCreateTokenError: function(error) {
              console.log(error)
            }
          })
    }
    return (
        <Container>
            <BasicButton clickFn={pruebaClick} text="clickeame"/>
            <div id="conektaIframeContainer" className="flex w-full h-80"></div>
        </Container>
    )
}

export default Soporte;