import React, {useState, useEffect} from 'react';
import Container from '../UI/Container';
import Card from '../UI/Card';
import Datagrid from '../UI/Datagrid';
import { FaUsers } from 'react-icons/fa';
import { Rate } from 'rsuite';
import { grid_columns_intenciones_contratacion } from '../helpers/grid_columns';
import { merent_instance } from '../helpers/axios_helper';
import toast from 'react-hot-toast';

function DashboardOperador() {
    const [calificacion, setCalificacion] = useState(0);
    const [totalIntenciones, setTotalIntenciones] = useState(0);
    const [intenciones, setIntenciones] = useState([]);

    useEffect(() => {
        const id = localStorage.getItem("usr_id");
        merent_instance().get(`api/operador/${id}/dashboard`).then(({data}) => {
            const {calificacion, total} = data;
            setCalificacion(calificacion);
            setTotalIntenciones(total);
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        });
        merent_instance().get(`/api/intencioncontratacion/operador/${id}`).then(({data}) => {
            setIntenciones(data);
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        });
    }, []);

    return(
        <Container>
            <div className="grid grid-cols-1 lg:grid-cols-2 px-4 gap-x-4 gap-y-1">   
                <Card text="Mi calificación" 
                    qty={calificacion} 
                    icon={
                        <Rate value={calificacion} size="lg" allowHalf readOnly className='mt-3 mb-2' />
                    }
                />
                <Card text="Intenciones de Contratación" 
                    qty={totalIntenciones} 
                    icon={
                        <FaUsers className="text-primary-400 mx-auto my-2"/>
                    }
                />
            </div>
            <Datagrid 
                title="Intenciones de Contratación"
                header={grid_columns_intenciones_contratacion}
                data={intenciones}
                height={520}
                headerHeight={60}
            />
        </Container>
    )
}

export default DashboardOperador;