import React, { createContext, useState, useEffect }  from 'react';
import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Home from './pages/Home';
import Renteros from './pages/Renteros';
import Clientes from './pages/Clientes';
import Rentas from './pages/Rentas';
import Pagos from './pages/Pagos';
import Aprobacion from './pages/Aprobacion';
import Soporte from './pages/Soporte';
import Sidebar from './UI/Sidebar';
import Dashboard from './pages/Dashboard';
import Perfil from './pages/Perfil';
import Categorias from './pages/Categorias';
import CategoriasOperador from './pages/CategoriasOperador';
import Equipos from './pages/Equipos';
import Horarios from './pages/Horarios';
import '../styles/SideBar.scss';
import { CustomProvider } from 'rsuite';
import esES from 'rsuite/locales/es_ES';
import ProfileContext from './Contexts/ProfileContext';
import RegisterOperador from './pages/RegisterOperador';
import DashboardOperador from './pages/DashboardOperador';
import PerfilOperador from './pages/PerfilOperador';
import Operadores from './pages/Operadores';
import Recuperar from './pages/Recuperar';
import Prueba from './pages/Prueba';
import EstadosCiudades from './pages/EstadosCiudades';
import AdminEquipos from './pages/AdminEquipos';

function App() {
  const [profile, setProfile] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate();
  
  useEffect(() => {
    const usr_id = searchParams.get("idRentero");
    if(usr_id > 0){
      const tktwj = searchParams.get("tktwj");
      const usr_email = searchParams.get("usr_email");
      const usr_name = searchParams.get("usr_name");
      const usr_role = searchParams.get("usr_role");
      const usr = searchParams.get("usr");
      
      localStorage.setItem("usr_id", usr_id);
      localStorage.setItem("tktwj", tktwj);
      localStorage.setItem("usr_email", usr_email);
      localStorage.setItem("usr_name", usr_name);
      localStorage.setItem("usr_role", usr_role);
      localStorage.setItem("usr", usr);

      setProfile(2);
      navigation("/equipos");
    }
        
  }, []);
  
  return (
    <CustomProvider locale={esES}>
      <ProfileContext.Provider value={{profile, setProfile}}>
        <Routes>
          {/*Rutas Publicas */}
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/registrar" element={<Register/>}></Route>
            <Route path="/registrar-operador" element={<RegisterOperador/>}></Route>
            <Route path="/logout" element={<Logout/>}></Route>
            <Route path="/" element={<Login/>}></Route>
            <Route path="/recuperar/:email/:token" element={<Recuperar/>}></Route>
          {/*Rutas Privadas */}
            <Route element={<Sidebar/>}>
              <Route path="/home" element={<Home/>}></Route>
              <Route path="/dashboard" element={<Dashboard/>}></Route>
              <Route path="/dashboard-operador" element={<DashboardOperador/>}></Route>
              <Route path="/perfil" element={<Perfil/>}></Route>
              <Route path="/perfil-operador" element={<PerfilOperador/>}></Route>
              <Route path="/renteros" element={<Renteros/>}></Route>
              <Route path="/clientes" element={<Clientes/>}></Route>
              <Route path="/operadores" element={<Operadores/>}></Route>
              <Route path="/rentas" element={<Rentas/>}></Route>
              <Route path="/categorias" element={<Categorias/>}></Route>
              <Route path="/categoriasoperador" element={<CategoriasOperador/>}></Route>
              <Route path="/equipos" element={<Equipos/>}></Route>
              <Route path="/equipos/:nuevo" element={<Equipos/>}></Route>
              <Route path="/admin-equipos" element={<AdminEquipos/>}></Route>
              <Route path="/horarios" element={<Horarios/>}></Route>
              <Route path="/pagos" element={<Pagos/>}></Route>
              <Route path="/aprobar" element={<Aprobacion/>}></Route>
              <Route path="/soporte" element={<Soporte/>}></Route>
              <Route path="/prueba" element={<Prueba/>}></Route>
              <Route path='/estados-ciudades' element={<EstadosCiudades/>}></Route>
            </Route>
        </Routes>
      </ProfileContext.Provider>
    </CustomProvider>
  );
}

export default App;
