
import React from 'react';
import { FaUserAlt, FaUsers, FaCheck } from 'react-icons/fa';
import { MdProductionQuantityLimits, MdAttachMoney } from 'react-icons/md';
import { ReactComponent as RoboArm } from '../../assets/robo-arm.svg';

export const dummy_data_cards_home = [
    {
        text: "Renteros activos",
        icon: <FaUsers className="text-primary-400 mx-auto my-2"/>,
        qty: "2"
    },
    {
        text: "Clientes activos",
        icon: <FaUserAlt className="text-primary-400 mx-auto my-2"/>,
        qty: "10"
    },
    {
        text: "Renteros con renta activa",
        icon: <div className='flex items-center'><FaUsers className="text-primary-400 my-2"/><FaCheck className="text-primary-400 my-2 text-xl"/></div>,
        qty: "2"
    },
    {
        text: "Clientes con renta activa",
        icon: <div className='flex items-center'><FaUserAlt className="text-primary-400 my-2"/><FaCheck className="text-primary-400 my-2 text-xl"/></div>,
        qty: "10"
    },
    {
        text: "Rentas activas",
        icon: <RoboArm className="w-1/12 h-1/6 font-bold fill-primary-400 mx-auto my-2"/>,
        qty: "6"
    }
];

export const dummy_data_rentas = [
    {
        id: 1,
        cliente: "Jose Luis Augusto",
        fechaRenta: '2022-03-14 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '3 equipos rentados',
        calificacion: '',
        total: 13400,
        totalMenosDescuento: '12,000.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 2,
        cliente: "Jose A Luis Augusto",
        fechaRenta: '2022-03-19 23:23',
        fechaRentaTerminada: '2022-03-25 23:23',
        fechaProspeccion: '2022-03-25 23:23',
        numeroEquipos: '5 equipos rentados',
        calificacion: '5 / 5',
        total: 13400,
        totalMenosDescuento: '12,000.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 3,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-30 23:23',
        fechaRentaTerminada: '2022-04-05 23:23',
        fechaProspeccion: '2022-04-05 23:23',
        numeroEquipos: '1 equipos rentados',
        calificacion: '2.5 / 5',
        total: 3400,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 4,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '3 equipos rentados',
        calificacion: '',
        total: 1400,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 3,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '3 equipos rentados',
        calificacion: '',
        total: 13200,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 3,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '1 equipos rentados',
        calificacion: '',
        total: 1500,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 5,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '1 equipos rentados',
        calificacion: '',
        total: 13500,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 6,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '1 equipos rentados',
        calificacion: '',
        total: 13400,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 7,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '1 equipos rentados',
        calificacion: '',
        total: 13400,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 8,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '2 equipos rentados',
        calificacion: '',
        total: 100,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 9,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-19 23:23',
        fechaRentaTerminada: '2022-03-25 23:23',
        fechaProspeccion: '2022-03-25 23:23',
        numeroEquipos: '1 equipos rentados',
        calificacion: '3 / 5',
        total: '18,400.00 MXN',
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 10,
        cliente: "Jose A Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '3 equipos rentados',
        calificacion: '',
        total: 130,
        totalMenosDescuento: '12,000.00 MXN',
        detalle: 'Detalle'
    },
    {
        id: 11,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-03-03 13:23',
        fechaRentaTerminada: 'No ha terminado',
        fechaProspeccion: 'No ha terminado',
        numeroEquipos: '6 equipos rentados',
        calificacion: '',
        total: 19000,
        totalMenosDescuento: '12,500.00 MXN',
        detalle: 'Detalle'
    }
];

export const dummy_data_renteros = [
    {
        id: 1,
        nombreEncargado: "Jose Maria Morelos",
        correoElectronico: "jose.maria@consitucion.com",
        telefonoCelular: "8123456789",
        nombreEmpresa: "Constructora NL",
        direccionEmpresa: 'Av. Miguel Alemán',
        ciudadEmpresa: 'Apodaca',
        estadoEmpresa: 'Nuevo Leon',
        descripcionEquipos: 'Te indepediza la conciencia',
        esquema: 'Renta Abierta',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://idaip.org.mx/2021/3de3/docs/candidatos/148/declaracion_fiscal_148.pdf'
    },
    {
        id: 2,
        nombreEncargado: "Jose Maria Morelos",
        correoElectronico: "jose.maria@consitucion.com",
        telefonoCelular: "8123456789",
        nombreEmpresa: "Constructora NL",
        direccionEmpresa: 'Av. Miguel Alemán',
        ciudadEmpresa: 'Apodaca',
        estadoEmpresa: 'Nuevo Leon',
        descripcionEquipos: 'Te indepediza la conciencia',
        esquema: 'Renta Abierta',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    },
    {
        id: 3,
        nombreEncargado: "Jose Maria Morelos",
        correoElectronico: "jose.maria@consitucion.com",
        telefonoCelular: "8123456789",
        nombreEmpresa: "Constructora NL",
        direccionEmpresa: 'Av. Miguel Alemán',
        ciudadEmpresa: 'Apodaca',
        estadoEmpresa: 'Nuevo Leon',
        descripcionEquipos: 'Te indepediza la conciencia',
        esquema: 'Renta Segura',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://idaip.org.mx/2021/3de3/docs/candidatos/148/declaracion_fiscal_148.pdf'
    },
    {
        id: 4,
        nombreEncargado: "Jose Maria Morelos",
        correoElectronico: "jose.maria@consitucion.com",
        telefonoCelular: "8123456789",
        nombreEmpresa: "Constructora NL",
        direccionEmpresa: 'Av. Miguel Alemán',
        ciudadEmpresa: 'Apodaca',
        estadoEmpresa: 'Nuevo Leon',
        descripcionEquipos: 'Te indepediza la conciencia',
        esquema: 'No seleccionado',
        activo: false,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    },
    {
        id: 5,
        nombreEncargado: "Jose Maria Morelos",
        correoElectronico: "jose.maria@consitucion.com",
        telefonoCelular: "8123456789",
        nombreEmpresa: "Constructora NL",
        direccionEmpresa: 'Av. Miguel Alemán',
        ciudadEmpresa: 'Apodaca',
        estadoEmpresa: 'Nuevo Leon',
        descripcionEquipos: 'Te indepediza la conciencia',
        esquema: 'Renta Abierta',
        activo: false,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    }
];

export const dummy_data_clientes = [
    {
        id: 1,
        nombreCliente: 'Vicente Fox Quezada',
        correoElectronico: 'fox@fox.fox',
        telefono: '8123456789',
        direccionPrincipal: 'Palacio de Gobierno',
        ciudadPrincipal: 'Cuauhtémoc',
        estadoPrincipal: 'Ciudad de Mexico',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    },{
        id: 2,
        nombreCliente: 'Vicente Fox Quezada',
        correoElectronico: 'fox@fox.fox',
        telefono: '8123456789',
        direccionPrincipal: 'Palacio de Gobierno',
        ciudadPrincipal: 'Cuauhtémoc',
        estadoPrincipal: 'Ciudad de Mexico',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://idaip.org.mx/2021/3de3/docs/candidatos/148/declaracion_fiscal_148.pdf'
    },{
        id: 3,
        nombreCliente: 'Vicente Fox Quezada',
        correoElectronico: 'fox@fox.fox',
        telefono: '8123456789',
        direccionPrincipal: 'Palacio de Gobierno',
        ciudadPrincipal: 'Cuauhtémoc',
        estadoPrincipal: 'Ciudad de Mexico',
        activo: true,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    },{
        id: 4,
        nombreCliente: 'Vicente Fox Quezada',
        correoElectronico: 'fox@fox.fox',
        telefono: '8123456789',
        direccionPrincipal: 'Palacio de Gobierno',
        ciudadPrincipal: 'Cuauhtémoc',
        estadoPrincipal: 'Ciudad de Mexico',
        activo: false,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    },{
        id: 5,
        nombreCliente: 'Vicente Fox Quezada',
        correoElectronico: 'fox@fox.fox',
        telefono: '8123456789',
        direccionPrincipal: 'Palacio de Gobierno',
        ciudadPrincipal: 'Cuauhtémoc',
        estadoPrincipal: 'Ciudad de Mexico',
        activo: false,
        rfc: 'MMJ767IUDGGHQ978',
        constanciaSituacionFiscal: 'https://i2.wp.com/noticieros.televisa.com/wp-content/uploads/2022/06/sat-cual-es-el-requisito-para-sacar-la-constancia-de-situacion-fiscal-2022-sin-cita-1.jpg?resize=1280%2C1653&ssl=1'
    }
];

export const dummy_data_pagos = [
    {
        'id': 1,
        'cliente': "Jose Luis Augusto",
        'fechaPago': '2022-03-14',
        'horaPago': '13:23:00',
        'horaPagoAceptado': ' 13:24:00',
        'horaPagoTerminado': 'No ha terminado',
        'total': '$ 13,400.00 MXN'
    },
    {
        'id': 2,
        'cliente': "Jose Luis Augusto",
        'fechaPago': '2022-03-14',
        'horaPago': '13:23:00',
        'horaPagoAceptado': ' 13:24:00',
        'horaPagoTerminado': 'No ha terminado',
        'total': '$ 13,400.00 MXN'
    },
    {
        'id': 3,
        'cliente': "Jose Luis Augusto",
        'fechaPago': '2022-03-14',
        'horaPago': '13:23:00',
        'horaPagoAceptado': ' 13:24:00',
        'horaPagoTerminado': 'No ha terminado',
        'total': '$ 13,400.00 MXN'
    }
];

export const dummy_data_aprobacion = [
    {
        id:1,
        nombreEmpresa: 'Equipos de Apodaca NL',
        nombreEquipo: 'Montacargas Reach Slider 7000 Series',
        categoriaEquipo: 'Montacargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        precioRenta: '$ 780.00 MXN',
        servicios: [
            {
                id: 1,
                nombreServicio: "Mantenimiento a llantas",
                frecuencia: 7
            },
            {
                id: 2,
                nombreServicio: "Mantenimiento a pulsometro",
                frecuencia: 7
            }
        ],
        aditamentos: [
            {
                id: 1,
                nombreAditamento: 'Guia Laser',
                descripcionAditamento: 'Ayuda en las operaciones para una carga y descarga de tarimas en altura, dando una guía de laser clara en la tarima.',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 2,
                nombreAditamento: 'Pantalla con Videocámara',
                descripcionAditamento: 'El monitor a color montado en el compartimento del operador ayuda para identificar, poner y tomar tarimas en mayores alturas,',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 3,
                nombreAditamento: 'Sistema thermakit',
                descripcionAditamento: 'Para las bodegas frías, nuestra palanca de control y piso con calefacción mantienen a los operadores protegidos del frio. Manteniendo manos y pies cálidos por mas tiempo, ThermaKit brinda productividad mejorada turno tras turno.',
                costoAdicional: '$ 150.00 MXN',
            },
        ],
        imagenes: [
            {
                indiceImagen: 1,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg",
                alt: "Imagen de montacargas 1"
            },{
                indiceImagen: 2,
                src: "https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg",
                alt: "Imagen de montacargas 2"
            },{
                indiceImagen: 3,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg",
                alt: "Imagen de montacargas 3"
            },
        ]
    },
    {
        id:1,
        nombreEmpresa: 'Equipos de Apodaca NL',
        nombreEquipo: 'Montacargas Reach Slider 7000 Series',
        categoriaEquipo: 'Montacargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        precioRenta: '$ 780.00 MXN',
        servicios: [
            {
                id: 1,
                nombreServicio: "Mantenimiento a llantas",
                frecuencia: 7
            },
            {
                id: 2,
                nombreServicio: "Mantenimiento a pulsometro",
                frecuencia: 7
            }
        ],
        aditamentos: [
            {
                id: 1,
                nombreAditamento: 'Guia Laser',
                descripcionAditamento: 'Ayuda en las operaciones para una carga y descarga de tarimas en altura, dando una guía de laser clara en la tarima.',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 2,
                nombreAditamento: 'Pantalla con Videocámara',
                descripcionAditamento: 'El monitor a color montado en el compartimento del operador ayuda para identificar, poner y tomar tarimas en mayores alturas,',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 3,
                nombreAditamento: 'Sistema thermakit',
                descripcionAditamento: 'Para las bodegas frías, nuestra palanca de control y piso con calefacción mantienen a los operadores protegidos del frio. Manteniendo manos y pies cálidos por mas tiempo, ThermaKit brinda productividad mejorada turno tras turno.',
                costoAdicional: '$ 150.00 MXN',
            },
        ],
        imagenes: [
            {
                indiceImagen: 1,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg",
                alt: "Imagen de montacargas 1"
            },{
                indiceImagen: 2,
                src: "https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg",
                alt: "Imagen de montacargas 2"
            },{
                indiceImagen: 3,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg",
                alt: "Imagen de montacargas 3"
            },
        ]
    },
    {
        id:1,
        nombreEmpresa: 'Equipos de Apodaca NL',
        nombreEquipo: 'Montacargas Reach Slider 7000 Series',
        categoriaEquipo: 'Montacargas',
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        precioRenta: '$ 780.00 MXN',
        servicios: [
            {
                id: 1,
                nombreServicio: "Mantenimiento a llantas",
                frecuencia: 7
            },
            {
                id: 2,
                nombreServicio: "Mantenimiento a pulsometro",
                frecuencia: 7
            }
        ],
        aditamentos: [
            {
                id: 1,
                nombreAditamento: 'Guia Laser',
                descripcionAditamento: 'Ayuda en las operaciones para una carga y descarga de tarimas en altura, dando una guía de laser clara en la tarima.',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 2,
                nombreAditamento: 'Pantalla con Videocámara',
                descripcionAditamento: 'El monitor a color montado en el compartimento del operador ayuda para identificar, poner y tomar tarimas en mayores alturas,',
                costoAdicional: '$ 150.00 MXN',
            },
            {
                id: 3,
                nombreAditamento: 'Sistema thermakit',
                descripcionAditamento: 'Para las bodegas frías, nuestra palanca de control y piso con calefacción mantienen a los operadores protegidos del frio. Manteniendo manos y pies cálidos por mas tiempo, ThermaKit brinda productividad mejorada turno tras turno.',
                costoAdicional: '$ 150.00 MXN',
            },
        ],
        imagenes: [
            {
                indiceImagen: 1,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg",
                alt: "Imagen de montacargas 1"
            },{
                indiceImagen: 2,
                src: "https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg",
                alt: "Imagen de montacargas 2"
            },{
                indiceImagen: 3,
                src: "https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg",
                alt: "Imagen de montacargas 3"
            },
        ]
    },
]

export const dummy_data_soporte = [
    {
        id: 1,
        nombreCliente: 'Mario Roberto Avila',
        nombreEmpresa: 'Equipos Apodaca NL',
        motivo: 'Problema con el equipo',
        fechaEnvio: '2022-08-05',
        horaEnvio: '14:22:58',
        activo: true,
    },
    {
        id: 2,
        nombreCliente: 'Mario Roberto Avila',
        nombreEmpresa: 'Equipos Apodaca NL',
        motivo: 'Problema con el equipo',
        fechaEnvio: '2022-08-05',
        horaEnvio: '14:22:58',
        activo: true,
    },
    {
        id: 3,
        nombreCliente: 'Mario Roberto Avila',
        nombreEmpresa: 'Equipos Apodaca NL',
        motivo: 'Problema con el equipo',
        fechaEnvio: '2022-08-05',
        horaEnvio: '14:22:58',
        activo: false,
    },
    {
        id: 4,
        nombreCliente: 'Mario Roberto Avila',
        nombreEmpresa: 'Equipos Apodaca NL',
        motivo: 'Problema con el equipo',
        fechaEnvio: '2022-08-05',
        horaEnvio: '14:22:58',
        activo: false,
    }
]

export const dummy_data_rentas_detalle = [
    {
        id: 1,
        equipo: "Montacargas Reach Slider 7000 Series",
        costo: '780.00 MXN',
        cantidad: 3,
        total: '2,340 MXN'
    },
    {
        id: 2,
        equipo: "Retroexcavadora Case 580sm 4x2, Extensión, 2007",
        costo: '2,280.00 MXN',
        cantidad: 3,
        total: '6,840 MXN'
    },
    {
        id: 3,
        equipo: "Compactador Neumatico Rosco 915 Año 2004 Meqcer",
        costo: '1,460 MXN',
        cantidad: 3,
        total: '4,380 MXN'
    }
];

export const dummy_data_comentarios = [
    {
        id: 1,
        cliente: "Jose Luis Augusto",
        fechaRenta: '2022-03-14',
        comentario: 'Muy buen equipo, en muy buenas condiciones :)'
    },
    {
        id: 2,
        cliente: "Jose A Luis Perez",
        fechaRenta: '2022-03-19',
        comentario: 'Se nos hizo muy complicado manejar el mantenimiento'
    },
    {
        id: 3,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-04-14',
        comentario: 'Buen equipo, recomendado'
    },
    {
        id: 4,
        cliente: "Carolina Gomez",
        fechaRenta: '2022-04-14',
        comentario: 'Muy buen equipo'
    },
    {
        id: 5,
        cliente: "Juan Luis Augusto",
        fechaRenta: '2022-04-14',
        comentario: 'Se adecua facilmenmte a lo que se necesita'
    }
];

export const dummy_data_edit_perfil =
{
    id:1,
    nombreEmpresa: 'Andamios Amarillos',
    nombreEncargado: "Jose Maria Morelos",
    correoElectronico: "jose.maria@consitucion.com",
    telefonoCelular: "8123456789",
    direccionEmpresa: 'Monterrey NL',
    descripcionEquipos: 'Andamios y cimbras de la mejor calidad. Tenemos todo un equipo de trabajo profesional con experiencia de más de 30 años en el sector.',
    activo: true,
    rfc: 'MMJ767IUDGGHQ978',
    tipoEsquemaId: 1,
    tipoEsquema: 'Renta Abierta',
    cuenta: '4915668965231202',
};

export const dummy_data_categorias = [
    {
        id: 1,
        value: 1, 
        label: "Equipos de construcción",
        nombreCategoria: "Equipos de construcción",
        descripcion: 'Equipos que se rentan para llevar a cabo tareas de construcción',
        keywords: 'construccion, mezcla, albañileria'
    },
    {
        id: 2,
        value: 2, 
        label: "Equipos de alturas",
        nombreCategoria: "Equipos de alturas",
        descripcion: 'Equipos que se rentan para llevar a cabo tareas en las alturas',
        keywords: 'alturas andamios cuerda'
    }
];

export const dummy_data_equipos = [
    {
        id: 1,
        alerta: true,
        motivoRechazo:"Al ser un equipo de la categoría de montacargas, se deben mostrar los servicios necesarios obligatoriamente, favor de cargar esta información.",
        nombre: "Montacargas Reach Slider 7000 Series",
        categoriaEquipo: { id: 1,
                            nombre: 'Montecargas'
                        },
        descripcion: 'Los Montacargas de Alcance Profundo ahorran dos de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '7000 Series',
        horometro: '1405',
        precioDia: 780.00,
        descuento: 1,
        tieneDescuento: 'Sí',
        estatus: true,
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
    {
        id: 2,
        alerta: false,
        nombre: "Montacargas Reach Slider 8000 Series",
        categoriaEquipo: { id: 1,
                            nombre: 'Montecargas'
                        },
        descripcion: 'Los Montacargas de Alcance Profundo ahorran tres de cada cinco pasillos contra el almacenamiento de profundidad única.',
        modelo: '8000 Series',
        horometro: '1205',
        precioDia: 980.00,
        descuento: 2,
        tieneDescuento: 'No',
        estatus: true,
        imagenPrincipal: ['https://montacargas.com/wp-content/uploads/2016/07/7000s-1-200x150.jpg'],
        imagenes: ['https://montacargas.com/wp-content/uploads/2016/07/montacargas7200_B-1-200x150.jpg',
                    'https://montacargas.com/wp-content/uploads/2016/07/7310-1-200x150.jpg']
    },
];

export const dummy_data_equipos_servicios = [
    {
        id: 1,
        nombreServicio: "Mantenimiento a llantas",
        descripcion: 'Se debe hacer empezando por las delanteras y posteriormente las traseras',
        frecuencia: '7 horas'
    },
    {
        id: 1,
        nombreServicio: "Mantenimiento a pulsometro",
        descripcion: 'Se debe hacer de la forma correcta',
        frecuencia: '7 horas'
    }
];

export const dummy_data_equipos_aditamentos = [
    {
        id: 1,
        nombreAditamento: "Guia Laser",
        descripcion: 'Ayuda en las operaciones para una carga y descarga de tarimas en altura, dando una guía de laser clara en la tarima.',
        precio: 150.00
    },
    {
        id: 1,
        nombreAditamento: "Pantalla con Videocámara",
        descripcion: 'El monitor a color montado en el compartimento del operador ayuda para identificar, poner y tomar tarimas en mayores alturas,.',
        precio: 150.00
    },
    {
        id: 1,
        nombreAditamento: "Sistema thermakit",
        descripcion: 'Para las bodegas frías, nuestra palanca de control y piso con calefacción mantienen a los operadores protegidos del frio. Manteniendo manos y pies cálidos por mas tiempo, ThermaKit brinda productividad mejorada turno tras turno.',
        precio: 150.00
    }
];

export const dummy_data_horarios = [
    {fecha: '2022-07-13', tipo: 'bloqueado', equipo: 'Todos', descripcion: 'Bloqueado'},
    {fecha: '2022-07-14', tipo: 'bloqueado', equipo: 'Todos', descripcion: 'Bloqueado'},
    {fecha: '2023-07-15', tipo: 'bloqueado', equipo: 'Todos', descripcion: 'Bloqueado'},
    {fecha: '2023-07-18', tipo: 'bloqueado', equipo: 'Todos', descripcion: 'Bloqueado'},
    {fecha: '2023-07-07', tipo: 'renta', equipo: 'Montacargas Series 3000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-08', tipo: 'renta', equipo: 'Montacargas Series 3000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-09', tipo: 'renta', equipo: 'Montacargas Series 3000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-10', tipo: 'renta', equipo: 'Montacargas Series 3000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-07', tipo: 'renta', equipo: 'Montacargas Series 4000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-08', tipo: 'renta', equipo: 'Montacargas Series 4000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-09', tipo: 'renta', equipo: 'Montacargas Series 4000', descripcion: 'Renta #1009'},
    {fecha: '2023-07-10', tipo: 'renta', equipo: 'Montacargas Series 5000', descripcion: 'Renta #1011'},
    {fecha: '2023-07-07', tipo: 'renta', equipo: 'Montacargas Series 5000', descripcion: 'Renta #1011'},
    {fecha: '2023-07-08', tipo: 'bloqueado', equipo: 'Montacargas Series 5000', descripcion: 'Bloqueado'},
    {fecha: '2023-07-09', tipo: 'renta', equipo: 'Montacargas Series 5000', descripcion: 'Renta #1011'},
    {fecha: '2023-07-10', tipo: 'renta', equipo: 'Montacargas Series 5000', descripcion: 'Renta #1011'},
    {fecha: '2023-08-02', tipo: 'bloqueado', equipo: 'Andamio Amarillo', descripcion: 'Bloqueado'},
    {fecha: '2023-08-03', tipo: 'bloqueado', equipo: 'Andamio Amarillo', descripcion: 'Bloqueado'},
    {fecha: '2023-07-29', tipo: 'renta', equipo: 'Andamio Amarillo', descripcion: 'Renta #1010'},
    {fecha: '2023-07-30', tipo: 'renta', equipo: 'Andamio Amarillo', descripcion: 'Renta #1010'},
    {fecha: '2023-07-31', tipo: 'renta', equipo: 'Andamio Amarillo', descripcion: 'Renta #1010'},
    {fecha: '2023-08-03', tipo: 'renta', equipo: 'Montacargas Series 5000', descripcion: 'Renta #1012'}
];

export const dummy_data_cards_dashboard = [
    {
        text: "Cantidad de Rentas",
        icon: <MdProductionQuantityLimits className="text-primary-400 mx-auto my-2"/>,
        qty: "8"
    },
    {
        text: "Monto total rentado",
        icon: <MdAttachMoney className="text-primary-400 mx-auto my-2"/>,
        qty: "$45,200.00"
    }
];

export const dummy_data_estados = [
    {"value":"1","label":"Jalisco","abreviatura":"Jal."},
    {"value":"2","label":"Nuevo Leon","abreviatura":"NL"},
    {"value":"3","label":"Baja California Sur","abreviatura":"BCS"},
    {"value":"4","label":"Yucatan","abreviatura":"Yuc."},
    {"value":"5","label":"Ciudad de Mexico","abreviatura":"CDMX"}
];

export const dummy_data_ciudades = [
    {"value":"1","label":"Guadalajara","estado":"1"},
    {"value":"2","label":"Zapopan","estado":"1"},
    {"value":"3","label":"Tlaquepaque","estado":"1"},
    {"value":"4","label":"Monterrey","estado":"2"},
    {"value":"5","label":"Apodaca","estado":"2"},
    {"value":"6","label":"Guadalupe","estado":"2"},
    {"value":"7","label":"Mulegé","estado":"3"},
    {"value":"8","label":"La Paz","estado":"3"},
    {"value":"9","label":"Los Cabos","estado":"3"},
    {"value":"10","label":"Mérida","estado":"4"},
    {"value":"11","label":"Abalá","estado":"4"},
    {"value":"12","label":"Acancéh","estado":"4"},
    {"value":"13","label":"Cuauhtémoc","estado":"5"},
    {"value":"14","label":"Gustavo A. Madero","estado":"5"},
    {"value":"15","label":"Iztacalco","estado":"5"}
];

export const dummy_data_intenciones_contratacion = [
    {
        id: 1, 
        nombreCliente: 'José Perez',
        direccion: 'Apodaca, NL',
        telefono: '8116532989',
        fechaContacto: '2022-10-13 15:23',
        calificacion: ''
    },
    {
        id: 2, 
        nombreCliente: 'Carla Perez',
        direccion: 'Apodaca, NL',
        telefono: '8116832989',
        fechaContacto: '2022-10-10 08:28',
        calificacion: '4.0'
    },
    {
        id: 3, 
        nombreCliente: 'Juan Rodriguez',
        direccion: 'Guadalupe, NL',
        telefono: '8123328989',
        fechaContacto: '2022-10-08 19:23',
        calificacion: '5.0'
    },
    {
        id: 4, 
        nombreCliente: 'Marcelo Quiroz',
        direccion: 'Monterrey, NL',
        telefono: '8116989879',
        fechaContacto: '2022-10-05 15:23',
        calificacion: '4.5'
    },
    {
        id: 5, 
        nombreCliente: 'Antonio Rizo',
        direccion: 'Apodaca, NL',
        telefono: '8116342989',
        fechaContacto: '2022-10-01 15:23',
        calificacion: ''
    },
    {
        id: 6, 
        nombreCliente: 'Carlos Hernandez',
        direccion: 'Apodaca, NL',
        telefono: '8116445289',
        fechaContacto: '2022-10-01 10:23',
        calificacion: '5.0'
    },
    {
        id: 7, 
        nombreCliente: 'Mario Gonzalez',
        direccion: 'Apodaca, NL',
        telefono: '8116569989',
        fechaContacto: '2022-09-28 09:59',
        calificacion: '3.5'
    }
];

export const dummy_data_edit_perfil_operador =
{
    id: 1,
    nombresOperador: 'José Antonio',
    apellidosOperador: "Garza Morelos",
    correoElectronico: "jose.garza@construccion.com",
    telefono: "8123456789",
    descripcion: 'Me dedico a manejar equipos de construcción. Desde palas hasta excavadoras.',
    activo: true,
    categoria: 1,
    estado: 2,
    ciudad: 5,
    habilidades: [
        {id: 1, value: "Operar las grúas, montacargas, o de otros equipos de elevación o desplazamiento."},
        {id: 2, value: "El control de equipos medidores o muestra para asegurar el correcto funcionamiento."},
        {id: 3, value: "Mantener el equipo de material que se mueve en buenas condiciones de trabajo. Mover materiales, equipos o suministros."}
    ],
    precioDia: '$ 380.00',
    precioSemana: '$ 2,600.00',
    precioQuincena: '$ 5,000.00',
    precioMes: '$ 9,200.00',
    imagen: "https://i0.wp.com/innovateparaelempleo.es/wp-content/uploads/imagen-peon-albanil-1.jpg?resize=1500%2C1000&ssl=1"
};


export const dummy_data_operadores = [
    {
        id: 1,
        nombresOperador: 'José Antonio',
        apellidosOperador: "Garza Morelos",
        correoElectronico: "jose.garza@construccion.com",
        telefono: "8123456789",
        descripcion: 'Me dedico a manejar equipos de construcción. Desde palas hasta excavadoras.',
        activo: true,
        categoria: 1,
        estado: 2,
        ciudad: 5,
        habilidades: [
            {id: 1, value: "Operar las grúas, montacargas, o de otros equipos de elevación o desplazamiento."},
            {id: 2, value: "El control de equipos medidores o muestra para asegurar el correcto funcionamiento."},
            {id: 3, value: "Mantener el equipo de material que se mueve en buenas condiciones de trabajo. Mover materiales, equipos o suministros."}
        ],
        precioDia: '$ 380.00',
        precioSemana: '$ 2,600.00',
        precioQuincena: '$ 5,000.00',
        precioMes: '$ 9,200.00',
        imagen: "https://i0.wp.com/innovateparaelempleo.es/wp-content/uploads/imagen-peon-albanil-1.jpg?resize=1500%2C1000&ssl=1"
    },
    {
        id: 2,
        nombresOperador: 'Bernardo',
        apellidosOperador: "Reyes",
        correoElectronico: "b.reyes@construccion.com",
        telefono: "812349789",
        descripcion: 'Me dedico a manejar equipos de construcción. Desde palas hasta excavadoras.',
        activo: true,
        categoria: 2,
        estado: 2,
        ciudad: 5,
        habilidades: [
            {id: 1, value: "Operar las grúas, montacargas, o de otros equipos de elevación o desplazamiento."},
            {id: 2, value: "El control de equipos medidores o muestra para asegurar el correcto funcionamiento."},
            {id: 3, value: "Mantener el equipo de material que se mueve en buenas condiciones de trabajo. Mover materiales, equipos o suministros."}
        ],
        precioDia: '$ 380.00',
        precioSemana: '$ 2,600.00',
        precioQuincena: '$ 5,000.00',
        precioMes: '$ 9,200.00',
        imagen: "https://i0.wp.com/innovateparaelempleo.es/wp-content/uploads/imagen-peon-albanil-1.jpg?resize=1500%2C1000&ssl=1"
    }
];
