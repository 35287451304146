import { HiOutlineSwitchHorizontal } from "react-icons/hi";

export const grid_columns_rentas = (click_fn) => {
    return [
        {
            key: 'detalle',
            title: 'Detalle',
            width: 70,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'urlDocumento',
            title: 'URL Documento',
            align: 'center',
            width: 150,
            url_cell: true
        },
        {
            key: 'id',
            title: '# Renta',
            align: 'right',
            width: 90
        },
        {
            key: 'estadoRenta',
            title: 'Estado de la renta',
            align: 'left',
            width: 250,
            custom_cell: true,
            compare_value: "Solicitud cancelada",
            true_class: 'text-red-600',
            false_class: ''
        },
        {
            key: 'cliente.nombre',
            title: 'Cliente',
            width: 180,
            align: 'center'
        },
        {
            key: 'fechaInicio',
            title: 'Fecha de inicio',
            width: 150,
            align: 'center'
        },
        {
            key: 'fechaFin',
            title: 'Fecha terminada',
            width: 150,
            align: 'center'
        },
        {
            key: 'cantidadEquipos',
            title: '# de Equipos',
            width: 120,
            align: 'center'
        },
        {
            key: 'total',
            title: 'Total',
            width: 130,
            align: 'right',
            summary: 0.00
        },
        {
            key: 'totalMenosDescuento',
            title: 'c/descuento',
            width: 130,
            align: 'right'
        },
        {
            key: 'calificacion',
            title: 'Calificación',
            width: 120,
            align: 'center'
        },
        {
            key: 'fechaCreacion',
            title: 'Fecha de creación',
            width: 150,
            align: 'center'
        },
    ];
}

export const grid_columns_renteros = (click_fn) =>{
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Rentero',
            align: 'right',
            width: 110
        },
        {
            key: 'nombreEncargado',
            title: 'Nombre del encargado',
            width: 200,
            align: 'left'
        },
        {
            key: 'correoElectronico',
            title: 'Correo electronico',
            width: 300,
            align: 'left'
        },
        {
            key: 'telefonoCelular',
            title: 'Telefono celular',
            width: 150,
            align: 'left'
        },
        {
            key: 'nombreEmpresa',
            title: 'Nombre de la empresa',
            width: 150,
            align: 'left'
        },
        {
            key: 'direccionEmpresa',
            title: 'Direccion de la empresa',
            width: 250,
            align: 'left'
        },
        {
            key: 'ciudad',
            title: 'Ciudad',
            width: 100,
            align: 'left'
        },
        {
            key: 'estado',
            title: 'Estado',
            width: 100,
            align: 'left'
        },
        {
            key: 'esquema',
            title: 'Tipo de Plan',
            width: 150,
            align: 'left'
        },
        {
            key: 'activo',
            title: 'Estatus',
            width: 120,
            align: 'center',
            custom_cell: true,
            compare_value: "Activo",
            true_class: 'text-green-600',
            false_class: 'text-red-600'
        },
        {
            key: 'rfc',
            title: 'RFC',
            width: 210,
            align: 'left'
        }
    ];
};

export const grid_columns_clientes = (click_fn) => {
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Cliente',
            align: 'right',
            width: 100
        },
        {
            key: 'nombre',
            title: 'Nombre',
            width: 300,
            align: 'left'
        },
        {
            key: 'email',
            title: 'Correo Electronico',
            width: 220,
            align: 'left'
        },
        {
            key: 'telefono',
            title: 'Numero celular',
            width: 150,
            align: 'left'
        },
        {
            key: 'direccionPrincipal',
            title: 'Dirección',
            width: 200,
            align: 'left'
        },
        {
            key: 'ciudadPrincipal',
            title: 'Ciudad',
            width: 110,
            align: 'left'
        },
        {
            key: 'estadoPrincipal',
            title: 'Estado',
            width: 170,
            align: 'left'
        },
        {
            key: 'activo',
            title: 'Estatus',
            width: 150,
            align: 'center',
            custom_cell: true,
            compare_value: true,
            true_class: 'text-green-600',
            false_class: 'text-red-600'
        }
    ];
}

export const grid_columns_pagos = (click_fn) => {
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Pago',
            align: 'right',
            width: 100
        },
        {
            key: 'cliente',
            title: 'Cliente',
            align: 'right',
            width: 150
        },
        {
            key: 'fechaPago',
            title: 'Fecha de pago',
            align: 'right',
            width: 120
        },
        {
            key: 'horaPago',
            title: 'Hora de pago',
            align: 'right',
            width: 100
        },
        {
            key: 'horaPagoAceptado',
            title: 'Hora aceptado',
            align: 'right',
            width: 150
        },
        {
            key: 'horaPagoTerminado',
            title: 'Hora terminación',
            align: 'right',
            width: 150
        },
        {
            key: 'total',
            title: 'Total',
            align: 'right',
            width: 150,
            summary: "$ 0.0"
        }
    ];
} 

export const grid_columns_soporte = [
    {
        key: 'id',
        title: '# Ticket',
        align: 'right',
        width: 100
    },
    {
        key: 'nombreCliente',
        title: 'Cliente',
        align: 'left',
        width: 200
    },
    {
        key: 'nombreEmpresa',
        title: 'Rentero',
        align: 'left',
        width: 200
    },
    {
        key: 'motivo',
        title: 'Motivo de ticket',
        align: 'center',
        width: 200
    },
    {
        key: 'fechaEnvio',
        title: 'Fecha de solicitud',
        align: 'left',
        width: 150
    },
    {
        key: 'horaEnvio',
        title: 'Hora de solicitud',
        align: 'left',
        width: 150
    },
    {
        key: 'activo',
        title: 'Activo',
        align: 'center',
        width: 110,
        custom_cell: true,
        compare_value: true,
        true_class: 'text-green-600',
        false_class: 'text-red-600'
    },
]

export const grid_columns_rentas_detalle = [
    {
        key: 'id',
        title: '# Equipo',
        align: 'right',
        width: 100
    },
    {
        key: 'equipo.categoriaEquipo.nombre',
        title: 'Categoria',
        width: 200,
        align: 'right'
    },
    {
        key: 'equipo.nombre',
        title: 'Equipo',
        width: 200,
        align: 'left'
    },
    {
        key: 'equipo.modelo',
        title: 'Modelo',
        width: 110,
        align: 'left'
    },
    {
        key: 'equipo.horometro',
        title: 'Horometro',
        width: 110,
        align: 'left'
    },
    {
        key: 'precioTotal',
        title: 'Costo Total',
        width: 130,
        align: 'right'
    },
];

export const grid_columns_comentarios = (click_fn) => {
    return [
        {
            key: 'detalle',
            title: 'Detalle',
            width: 70,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Renta',
            align: 'right',
            width: 110
        },
        {
            key: 'cliente',
            title: 'Cliente',
            width: 320,
            align: 'left'
        },
        {
            key: 'fechaRenta',
            title: 'Fecha de renta',
            width: 200,
            align: 'center'
        },
        {
            key: 'comentario',
            title: 'Comentario',
            width: 400,
            align: 'left'
        }
    ];
}

export const grid_columns_rentas_recientes = [
    {
        key: 'id',
        title: '# Renta',
        align: 'right',
        width: 90
    },
    {
        key: 'estadoRenta',
        title: 'Estado de la renta',
        align: 'right',
        width: 220,
        custom_cell: true,
        compare_value: "Solicitud cancelada",
        true_class: 'text-red-600',
        false_class: ''
    },
    {
        key: 'cliente',
        title: 'Cliente',
        width: 180,
        align: 'center'
    },
    {
        key: 'fechaInicio',
        title: 'Fecha de inicio',
        width: 150,
        align: 'center'
    },
    {
        key: 'fechaFin',
        title: 'Fecha terminada',
        width: 150,
        align: 'center'
    },
    {
        key: 'cantidadEquipos',
        title: '# de Equipos',
        width: 120,
        align: 'center'
    },
    {
        key: 'total',
        title: 'Total',
        width: 130,
        align: 'right',
        summary: 0.00
    },
    {
        key: 'totalMenosDescuento',
        title: 'c/descuento',
        width: 130,
        align: 'right'
    },
    {
        key: 'calificacion',
        title: 'Calificación',
        width: 120,
        align: 'center'
    },
    {
        key: 'fechaCreacion',
        title: 'Fecha de creación',
        width: 150,
        align: 'center'
    },
];

export const grid_columns_categorias = (click_fn) => { 
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '#',
            align: 'right',
            width: 50
        },
        {
            key: 'nombre',
            title: 'Nombre Categoria',
            align: 'left',
            width: 200
        },
        {
            key: 'descripcion',
            title: 'Descripción',
            align: 'left',
            width: 500
        },
        {
            key: 'keywords',
            title: 'Keywords',
            align: 'left',
            width: 250
        }
    ]
};

export const grid_columns_estados = (click_fn) => { 
    return [
        {
            key: 'opciones',
            title: 'Activar/Desactivar',
            width: 130,
            align: 'center',
            custom_button_cell: true,
            custom_icon:<HiOutlineSwitchHorizontal/>,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '#',
            align: 'right',
            width: 70
        },
        {
            key: 'nombre',
            title: 'Estado',
            align: 'left',
            width: 200
        },
        {
            key: 'activo',
            title: 'Estatus',
            align: 'center',
            width: 120,
            custom_cell: true,
            compare_value: 'Activo',
            true_class: 'text-green-600 font-bold',
            false_class: 'text-red-400 font-bold'
        }
    ]
};

export const grid_columns_ciudades = (click_fn) => { 
    return [
        {
            key: 'opciones',
            title: 'Activar/Desactivar',
            width: 130,
            align: 'center',
            custom_button_cell: true,
            custom_icon:<HiOutlineSwitchHorizontal/>,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '#',
            align: 'right',
            width: 70
        },
        {
            key: 'nombre',
            title: 'Ciudad',
            align: 'left',
            width: 200
        },
        {
            key: 'estado',
            title: 'Estado',
            align: 'left',
            width: 200
        },
        {
            key: 'activo',
            title: 'Estatus',
            align: 'center',
            width: 120,
            custom_cell: true,
            compare_value: 'Activo',
            true_class: 'text-green-600 font-bold',
            false_class: 'text-red-400 font-bold'
        }
    ]
};

export const grid_columns_equipos = (click_fn, click_fn_alert) => { 
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'alerta',
            title: 'Alerta',
            width: 80,
            align: 'center',
            alert_cell: true,
            click_fn: click_fn_alert
        },
        {
            key: 'estatus',
            title: 'Estatus',
            align: 'center',
            width: 300,
            custom_cell: true,
            compare_value: "Listo para recibir Rentas",
            true_class: 'text-green-600 font-bold',
            false_class: 'text-red-400 font-bold'
        },
        {
            key: 'id',
            title: '# Equipo',
            align: 'right',
            width: 100
        },
        {
            key: 'nombre',
            title: 'Nombre Equipo',
            align: 'left',
            width: 200
        },
        {
            key: 'categoriaEquipo.nombre',
            title: 'Categoría',
            align: 'center',
            width: 150
        },
        {
            key: 'descripcion',
            title: 'Descripción',
            align: 'left',
            width: 300
        },
        {
            key: 'modelo',
            title: 'Modelo',
            align: 'left',
            width: 150
        },
        {
            key: 'horometro',
            title: 'Horometro',
            align: 'right',
            width: 120
        },
        {
            key: 'precioDia',
            title: 'Precio por día',
            align: 'right',
            width: 120
        },
        {
            key: 'esquema.nombre',
            title: 'Esquema',
            align: 'left',
            width: 120
        },
        {
            key: 'tieneDescuento',
            title: 'Incluye Operador',
            align: 'center',
            width: 140
        },
    ]
};

export const grid_columns_equipos_rentero =  [
    {
        key: 'id',
        title: '# Equipo',
        align: 'right',
        width: 100
    },
    {
        key: 'nombre',
        title: 'Nombre Equipo',
        align: 'left',
        width: 200
    },
    {
        key: 'categoriaEquipo.nombre',
        title: 'Categoría',
        align: 'center',
        width: 150
    },
    {
        key: 'descripcion',
        title: 'Descripción',
        align: 'left',
        width: 300
    },
    {
        key: 'modelo',
        title: 'Modelo',
        align: 'left',
        width: 150
    },
    {
        key: 'horometro',
        title: 'Horometro',
        align: 'right',
        width: 100
    },
    {
        key: 'precioDia',
        title: 'Precio por día',
        align: 'right',
        width: 100
    },
    {
        key: 'tieneDescuento',
        title: 'Descuento',
        align: 'center',
        width: 100
    },
    {
        key: 'disponible',
        title: 'Estatus',
        align: 'center',
        width: 100,
        custom_cell: true,
        compare_value: true,
        true_class: 'text-green-600',
        false_class: 'text-red-600'
    }
];

export const grid_columns_equipos_servicios = (click_fn) => { 
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Servicio',
            align: 'right',
            width: 90
        },
        {
            key: 'nombre',
            title: 'Nombre Servicio',
            align: 'left',
            width: 250
        },/* 
        {
            key: 'descripcion',
            title: 'Descripción',
            align: 'left',
            width: 530
        }, */
        {
            key: 'frecuencia',
            title: 'Frecuencia',
            align: 'left',
            width: 100
        }
    ]
};

export const grid_columns_equipos_aditamentos = (click_fn) => { 
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Aditamento',
            align: 'right',
            width: 120
        },
        {
            key: 'nombre',
            title: 'Nombre Aditamento',
            align: 'left',
            width: 300
        },/* 
        {
            key: 'descripcion',
            title: 'Descripción',
            align: 'left',
            width: 590
        }, */
        {
            key: 'frecuencia',
            title: 'Frecuencia',
            align: 'right',
            width: 120
        },
        {
            key: 'precio',
            title: 'Precio',
            align: 'right',
            width: 80
        }
    ]
};

export const grid_columns_intenciones_contratacion = [
    {
        key: 'id',
        title: '#',
        align: 'right',
        width: 50
    },
    {
        key: 'nombreCliente',
        title: 'Cliente',
        align: 'left',
        width: 230
    },
    {
        key: 'direccion',
        title: 'Dirección',
        align: 'left',
        width: 300
    },
    {
        key: 'telefono',
        title: 'Teléfono',
        align: 'center',
        width: 120
    },
    {
        key: 'fechaContacto',
        title: 'Fecha Contacto',
        align: 'center',
        width: 220
    },
    {
        key: 'calificacion',
        title: 'Calificación',
        align: 'center',
        width: 150
    }
];

export const grid_columns_operadores = (click_fn) =>{
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'id',
            title: '# Operador',
            align: 'right',
            width: 110
        },
        {
            key: 'nombresOperador',
            title: 'Nombres',
            width: 120,
            align: 'left'
        },
        {
            key: 'apellidosOperador',
            title: 'Apellidos',
            width: 140,
            align: 'left'
        },
        {
            key: 'correoElectronico',
            title: 'Correo electronico',
            width: 300,
            align: 'left'
        },
        {
            key: 'telefono',
            title: 'Telefono',
            width: 150,
            align: 'left'
        },
        {
            key: 'estado',
            title: 'Estado',
            width: 120,
            align: 'left'
        },
        {
            key: 'ciudad',
            title: 'Ciudad',
            width: 140,
            align: 'left'
        },
        {
            key: 'requierePago',
            title: '¿Requiere Pago?',
            width: 135,
            align: 'center',
            custom_cell: true,
            compare_value: "Si",
            true_class: 'text-green-600',
            false_class: 'text-red-600'
        },
        {
            key: 'listado',
            title: '¿Se encuentra listado?',
            width: 180,
            align: 'center',
            custom_cell: true,
            compare_value: "Si",
            true_class: 'text-green-600',
            false_class: 'text-red-600'
        },
        {
            key: 'activo',
            title: 'Estatus',
            width: 120,
            align: 'center',
            custom_cell: true,
            compare_value: "Activo",
            true_class: 'text-green-600',
            false_class: 'text-red-600'
        },
        {
            key: 'calif',
            title: 'Calificación',
            width: 100,
            align: 'center'
        }
    ];
};

export const grid_columns_equipos_admin = (click_fn) => {
    return [
        {
            key: 'opciones',
            title: 'Opciones',
            width: 80,
            align: 'center',
            button_cell: true,
            click_fn: click_fn
        },
        {
            key: 'facturaEquipo',
            title: 'Factura Equipo',
            width: 150,
            align: 'center',
            url_cell: true,
        },{
            key: 'id',
            title: '# Equipo',
            align: 'right',
            width: 100
        },
        {
            key: 'nombre',
            title: 'Nombre Equipo',
            align: 'left',
            width: 200
        },
        {
            key: 'categoriaEquipo.nombre',
            title: 'Categoría',
            align: 'center',
            width: 300
        },
        {
            key: 'descripcion',
            title: 'Descripción',
            align: 'left',
            width: 300
        },
        {
            key: 'modelo',
            title: 'Modelo',
            align: 'left',
            width: 150
        },
        {
            key: 'horometro',
            title: 'Horometro',
            align: 'right',
            width: 100
        },
        {
            key: 'precioDia',
            title: 'Precio por día',
            align: 'right',
            width: 100
        },
    ];
};

export const grid_columns_obras =
[
    {
        key: 'id',
        title: '# Subcomunidad especial',
        align: 'right',
        width: 220
    },
    {
        key: 'nombre',
        title: 'Nombre Subcomunidad especial',
        align: 'left',
        width: 300
    },
    {
        key: 'ubicacion',
        title: 'Ubicacion',
        align: 'center',
        width: 300
    },
];

export const grid_columns_obras_equipo =
[
    {
        key: 'obra.id',
        title: '# Obra',
        align: 'right',
        width: 100
    },
    {
        key: 'obra.nombre',
        title: 'Nombre Subcomunidad Especial',
        align: 'left',
        width: 300
    },
    {
        key: 'obra.ubicacion',
        title: 'Ubicacion',
        align: 'center',
        width: 300
    },
];