import React, {useState, useEffect} from 'react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown, FaRegTrashAlt, FaPlus } from 'react-icons/fa';
import Container from '../UI/Container';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { form_fields_operadores_perfil, form_fields_operadores_perfil_servicios } from '../helpers/form_fields';
import BasicInput from "../UI/Inputs/BasicInput";
import BasicButton from "../UI/Buttons/BasicButton";
import { merent_instance } from '../helpers/axios_helper';
import { MdOutlineSimCardAlert } from 'react-icons/md';
import Modal from '../UI/Modal';
import CardPlanOperador from '../UI/Card/CardPlanOperador';



function PerfilOperador() {
    const [perfilFieldsCuenta, setPerfilFieldsCuenta] = useState({});
    const [perfilFieldsServicios, setPerfilFieldsServicios] = useState({});
    const [perfilFieldsHabilidades, setPerfilFieldsHabilidades] = useState([]);
    const [categoriasOperador, setCategoriasOperador] = useState([]);
    const [nuevaHabilidad, setNuevaHabilidad] = useState("");
    const [requierePago, setRequierePago] = useState(false);
    const [listado, setListado] = useState(false);

    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    const [modalPagoMensualidad, setModalPagoMensualidad] = useState(false);
    const [modalRegistrarTarjeta, setModalRegistrarTarjeta] = useState(false);
    const [showLoading, setShowloading] = useState('');

    useEffect(() => {
        cargaEstados();
        cargaCategoriasOperador();
        cargaInformacionOperador();
        recargarHabilidades();
    }, []);
    
    const cargaCategoriasOperador = () => {
        merent_instance().get("api/categoriaoperador").then(({data}) => {
            const newCategorias = data.map(categoria => {
                return {
                    id: categoria.id,
                    value: categoria.id,
                    label: categoria.nombre
                }
            });
            setCategoriasOperador(newCategorias);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
        });
    }

    const cargaInformacionOperador = () => {
        const id = localStorage.getItem("usr_id");
        merent_instance().get(`api/operador/${id}`).then(({data}) => {
            const new_fields_cuenta = {
                nombre: data.nombre,
                apellidos: data.apellidos,
                email: data.usuario.email,
                phoneNumber: data.usuario.phoneNumber,
                estado: data.ciudad.estadoRef,
                ciudad: data.ciudadRef,
                imagenPerfil: data.usuario.urlImgPerfil,
            }
            console.log(new_fields_cuenta);
            setPerfilFieldsCuenta(new_fields_cuenta);
            const new_fields_servicios = {
                categoriaOperadorRef: data.categoriaOperadorRef,
                descripcion: data.descripcion,
                precioDia: data.precioDia,
                precioSemana: data.precioSemana,
                precioQuincena: data.precioQuincena,
                precioMes: data.precioMes
            }
            setPerfilFieldsServicios(new_fields_servicios);
            cargaCiudades(new_fields_cuenta.estado);

            setRequierePago(data.requierePago);
            setListado(data.listado);
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        });
    }

    const cargaEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
            }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
            })
    }

    const cargaCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const recargarHabilidades = () => {
        const id = localStorage.getItem("usr_id");

        merent_instance().get(`api/operador/${id}/habilidades`).then(({data}) => {
            setPerfilFieldsHabilidades(data);
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        })
    }

    const savePerfilCuenta = () => {
        toast.success("Cambios guardados");
    }

    const savePerfilServicios = () => {
        const id = localStorage.getItem("usr_id");
        merent_instance().put(`api/operador/${id}`, perfilFieldsServicios)
        .then(({data}) => {
            toast.success("Cambios guardados");
        }).catch(error => {
            console.log(error);
            toast.error("Error al actualizar la información");
        })
    }

    const agregarHabilidad = () => {
        if(nuevaHabilidad == '' || nuevaHabilidad.length < 5){
            toast.error("Ingresa una habilidad valida");
            return
        }
        const id = localStorage.getItem("usr_id");

        const nueva = {
            "nombre": nuevaHabilidad,
            "operadorRef": id
        };

        merent_instance().post("api/operador/habilidad", nueva).then(({data}) => {
            toast.success(data);
            recargarHabilidades();
            setNuevaHabilidad("");
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        })
    }

    const borrarHabilidad = (hab_id) => {
        const id = localStorage.getItem("usr_id");
        merent_instance().delete(`api/operador/${id}/habilidad/${hab_id}`).then(({data}) => {
            toast.success(data);
            recargarHabilidades();
        }).catch((error) => {
            toast.error("Se encontró un error consultado la información, porfavor, intenta nuevamente más tarde")
            console.log(error);
        })
    }

    const generarFormularioTarjeta = () => {
        setShowloading("Cargando formulario");
        setModalRegistrarTarjeta(true);
        setTimeout(() => {}, "3000");
        merent_instance().get(`/api/operador/planes/token`).then(({data}) => {
            console.log(data);
            setShowloading('');
            window.ConektaCheckoutComponents.Card({
                targetIFrame: "#conektaContainerOperadores", 
                allowTokenization: true, //Permite personalizar el tokenizador, por lo que su valor siempre será TRUE
                checkoutRequestId: data, //Checkout request ID, es el mismo ID generado en el paso 1
                publicKey: "key_M7DvGxvZeUhpQdVBOyThYHP", //Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
                options: {
                    styles: {
                        inputType: 'basic', //Modifica el diseño del Web Checkout Tokenizer: 'basic' // 'basic' | 'rounded' | 'line'
                        buttonType: 'basic', //Modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer:  'basic' // 'basic' | 'rounded' | 'sharp'        
                        states: { //Elemento que personaliza el borde de color de los elementos    
                            empty: {
                                borderColor: '#d4d6de' // Código de color hexadecimal para campos vacíos
                            },
                            invalid: {
                                borderColor: '#ff6666' // Código de color hexadecimal para campos inválidos
                            },
                            valid: {
                                borderColor: '#00b359' // Código de color hexadecimal para campos llenos y válidos
                            }
                        }
                    },
                    languaje: 'es', //Idioma: 'es' Español | 'en' Ingles
                    button: { //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
                        colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
                        text: 'Registrar Tarjeta', //Nombre de la acción en el botón ***Se puede personalizar
                        backgroundColor: '#FE6830' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
                    },
                    iframe: { //Elemento que personaliza el diseño del iframe
                        colorText: '#2D303A',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
                        backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
                    }
                },
                onCreateTokenSucceeded: function(token) {
                    setShowloading('Registrando tarjeta...');
                    registrarTarjeta(token.id);
                },
                onCreateTokenError: function(error) {
                    toast.error("Hubo un error al registrar tu tarjeta, por favor intenta más tarde");
                }
            })
        }).catch((error) => {
            setShowloading('');
            setModalRegistrarTarjeta(false);
            console.log(error);
            if(error.response){
                toast.error(error.response.data);
            }
            else
                toast.error("Error al conectarse a conekta: Token no generado.");
        })
    }

    const registrarTarjeta = (token) => {
        const usr_id = localStorage.getItem("usr_id");
        console.log("registrar tarjeta")

        const body = {
            "tokenId": token,
            "operadorRef": usr_id,
        };
        merent_instance().post(`/api/operador/planes/registrar`, body).then(({data}) => {
            console.log(data);
            toast.success(data);
            setModalRegistrarTarjeta(false);
            setModalPagoMensualidad(false);
            cargaInformacionOperador();
        }).catch((error) => {
            console.log(error);
            if(error.response){
                toast.error(error.response.data);
            }
            else
                toast.error("Método de pago no válido, intenta con otro.");
        })
    }

    return(
        <Container>
            <div className="px-3">
                <div className="flex-col text-2xl rounded-xl bg-white my-4 shadow-lg">
                    { requierePago == true ? 
                    <div className="w-full bg-red-400 rounded-t-xl pb-1">
                        <p className="text-lg text-white text-center">
                            <MdOutlineSimCardAlert className="text-white m-1 inline text-4xl"/>
                        </p>
                        <p className="text-lg mt-0 text-white text-center">
                            A partir de este momento, se te cobrará una mensualidad.
                        </p>
                        <p className="text-lg mt-0 text-white text-center">
                            Es necesario que registres un metodo de pago para poder continuar recibiendo ofertas
                        </p>
                        <p className="text-center mb-2">
                            <BasicButton className="text-base bg-primary-600 !text-white !w-1/3" type="button" text="Ir a Pago" clickFn={() => {setModalPagoMensualidad(true)}}/>
                        </p>
                    </div>
                    :
                    <></>
                    }
                    <section className="w-full p-5 flex flex-rows items-end">
                        <img src={perfilFieldsCuenta.imagenPerfil} alt="operador-perfil" className='w-36 h-36 rounded-full border-primary-200 border-4 inline-block'/>
                        <h1 className='p-5 text-4xl font-bold text-primary-300 inline-block'>{perfilFieldsCuenta.nombre} {perfilFieldsCuenta.apellidos}</h1>
                    </section>
                    <section className="w-full p-2 pb-5">
                        <div className="px-1 pt-4 pb-2 text-sm text-gray-500">
                            <Form 
                                formFields={form_fields_operadores_perfil(estados, ciudades)} 
                                formState={perfilFieldsCuenta} 
                                formModifyState={setPerfilFieldsCuenta}
                                formSave={savePerfilCuenta}
                            />
                        </div>
                        <Disclosure c>
                            {({open}) => (
                                <>
                                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                        <span>Datos de servicios</span>
                                        <FaChevronDown className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-primary-500`}/>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-1 pt-4 pb-2 text-sm text-gray-500">
                                        <Form 
                                            formFields={form_fields_operadores_perfil_servicios(categoriasOperador)} 
                                            formState={perfilFieldsServicios} 
                                            formModifyState={setPerfilFieldsServicios}
                                            formSave={savePerfilServicios}
                                        />
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure c>
                            {({open}) => (
                                <>
                                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                                        <span>Habilidades</span>
                                        <FaChevronDown className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-primary-500`}/>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-5 pt-4 pb-2 text-sm text-gray-500">
                                        {
                                            perfilFieldsHabilidades.map((hab, index) => {
                                                return <div className={`grid grid-cols-12 ${index % 2 != 0 ? 'bg-slate-200':''}`} key={hab.id}>
                                                    <div className='col-span-11 text-base text-secondary-700'>
                                                        - {hab.nombre}
                                                    </div>
                                                    <div className={`col-span-1 text-base text-secondary-700 cursor-pointer hover:text-secondary-300 flex justify-center items-center `} onClick={() => {borrarHabilidad(hab.id)}}>
                                                        <FaRegTrashAlt/>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-11 text-sm text-gray-500'>
                                                <BasicInput 
                                                    type="text" 
                                                    placeholder="Ingresa una nueva habilidad" 
                                                    defaultValue={nuevaHabilidad}
                                                    setValue={(e) => {setNuevaHabilidad(e.target.value)}}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <BasicButton type="button" clickFn={() => {agregarHabilidad()}} text="+" className="text-center font-bold"/>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </section>
                </div>
            </div>
            <Modal show={modalPagoMensualidad} setShow={setModalPagoMensualidad} title="Registro de tarjeta" className="max-w-5xl">
                <CardPlanOperador 
                    title={"Plan especial para Operadores"} 
                    costo={99} 
                    highlight={true} 
                />
                <BasicButton text="Ir a pagar" clickFn={generarFormularioTarjeta}/>
            </Modal>
            <Modal show={modalRegistrarTarjeta} setShow={setModalRegistrarTarjeta} title="Registra tu metodo de pago" className="max-w-3xl">
                <header className="w-full text-center">
                        <p>Parece ser que no has registrado ninguna tarjeta a la cual podamos cargar los pagos de tu suscripción. <br/> Favor de registrar una tarjeta para continuar</p>
                        {
                            showLoading !== "" ? 
                            <p>{showLoading}</p>
                            :
                            ''
                        }
                        <div id="conektaContainerOperadores" className="flex w-full items-center justify-center h-[30rem]">
                            
                        </div>
                </header>
            </Modal>
        </Container>
    )
}

export default PerfilOperador;