import React, { useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Aside from './Aside';
import ProfileContext from '../../Contexts/ProfileContext';

function Sidebar() {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const profileContext = useContext(ProfileContext);
    const rol = localStorage.getItem("usr_role");
    const profile = rol == "Admin" ? 1 : rol == "Rentero" ? 2 : 3;
    
    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <>
            <div className='w-full h-full flex fixed'>
                <Aside
                    collapsed={collapsed}
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                    profile={profile}
                />
                <Outlet context={[toggled, setToggled, profile]} />
            </div>
        </>
    );
}

export default Sidebar;