import React, {Fragment, useState} from 'react';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { Pagination, Whisper } from 'rsuite';
import { CSVLink } from 'react-csv';
import 'rsuite-table/dist/css/rsuite-table.css';
import 'rsuite/dist/rsuite.min.css';
import { FaDownload, FaExclamationTriangle } from 'react-icons/fa';
import Modal from '../Modal';
import BasicInput from '../Inputs/BasicInput';
import GridButton from '../../UI/Buttons/GridButton';
import DropdownButton from '../Buttons/DropdownButton';
import BasicButton from '../Buttons/BasicButton';

const Datagrid = ({title = '', header, data, height, headerHeight, handleRowSelection, buttonAdd, options_dropdown, showFilters = false, filters}) => {
    //Sort
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();

    //Pagination
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    //Summary
    const thousands = value => `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, '$1,');
    const NumberCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
    );
    
    //Custom Cell
    const CustomCell = ({ compare_value, true_class, false_class, rowData, dataKey, ...props }) => (
        <Cell className={
            rowData[dataKey] === compare_value ?
                true_class
            :
                false_class
        } {...props}>{rowData[dataKey]}</Cell>
    );
    //Custom Cell
    const CustomURLCell = ({  rowData, dataKey, ...props }) => {
        console.log(dataKey)
        console.log(rowData)
        console.log(rowData[dataKey])

        if(rowData[dataKey])
            if(Array.isArray(rowData[dataKey]))
                return <Cell {...props}><a target="_blank" className="underline text-blue" href={rowData[dataKey][0].url}>Documento</a></Cell>
            else
                return <Cell {...props}><a target="_blank" className="underline text-blue" href={rowData[dataKey]}>Documentos</a></Cell>


        return <Cell {...props}>No tiene documento adjunto</Cell>
    };

    //Custom Button Cell
    const CustomButtonCell = ({ click_fn, custom_icon, rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <div className='text-center m-0 p-0'>
                <button className={`bg-primary-200 px-3 py-1 rounded-md text-white font-semibold m-0 text-center`} type="button" onClick={() => {click_fn(rowData)}}>{custom_icon}</button>
            </div>
        </Cell>
    );
        
    //Button Cell
    const ButtonCell = ({ click_fn, rowData, dataKey, ...props }) => (
        <Cell {...props} className="link-group">
            <Whisper 
                placement="autoVerticalStart" 
                trigger="click" 
                speaker={(props, ref) => {
                    const { className, left, top, onClose } = props;
                    return <DropdownButton style={{ left, top }} onClose={onClose} className={className} ref={ref} options={options_dropdown} row_data={rowData}/>
                }}>
                <span className='bg-primary-300 px-2 py-1 rounded-md text-white cursor-pointer'>...</span>
            </Whisper>
        </Cell>
    );

    const AlertCell = ({ click_fn, rowData, dataKey, ...props }) => (
        <Cell {...props} className="link-group">
            {
                rowData[dataKey] === true ?
                <FaExclamationTriangle className='cursor-pointer inline text-[22px] text-primary-400' onClick={() => {click_fn(rowData)}}></FaExclamationTriangle>
            :
                ''
            }
        </Cell>
    );

    const getData = () => {
        if (sortColumn && sortType) {
            if(sortType !== 'asc'){
                return data.slice().sort((a,b) => (a[sortColumn] > b[sortColumn]) ? 1 : ((b[sortColumn] > a[sortColumn]) ? -1 : 0))
                .filter((v, i) => {
                    const start = limit * (page - 1);
                    const end = start + limit;
                    return i >= start && i < end;
                  });
            }
            else{
                return data.slice().sort((a,b) => (a[sortColumn] > b[sortColumn]) ? 1 : ((b[sortColumn] > a[sortColumn]) ? -1 : 0)).reverse()
                .filter((v, i) => {
                    const start = limit * (page - 1);
                    const end = start + limit;
                    return i >= start && i < end;
                  });
            }
        }
        return data.filter((v, i) => {
            const start = limit * (page - 1);
            const end = start + limit;
            return i >= start && i < end;
          });
    };

    //Sort
    const handleSortColumn = (sortColumn, sortType) => {
        var aux_type = 'asc';
        if (sortType === 'asc') {
            aux_type = 'desc';
        }
        setSortColumn(sortColumn);
        setSortType(aux_type);
    };

    //Paginiation
    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    //Summary
    const HeaderSummary = ({ title, summary }) => (
        <div>
          <label>{title}</label>
          <div className='text-base text-primary-300'>
            {thousands(summary)}
          </div>
        </div>
    );

    return (
        <Fragment>
            <header className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-8 xl:grid-cols-6 gap-x-8 p-2">
                {
                    title !== '' ? 
                        <p className={`${showFilters ? 'col-span-1 lg:col-span-2 xl:col-span-1' : 'col-span-1 lg:col-span-7 xl:col-span-5 '} text-3xl font-semibold my-3 text-left pl-2`}>
                            {title}
                        </p>
                    :
                        ''
                }
                {
                    showFilters ? 
                        <section className="col-span-1 lg:col-span-5 xl:col-span-4 rounded-lg bg-secondary-50 opacity-70 flex justify-center align-center">
                            <BasicInput 
                                type="date" 
                                placeholder="Fecha inicio" 
                                value={filters !== undefined && filters !== null && filters.fechaInicio !== undefined ? filters.fechaInicio : ''} 
                                setValue={filters !== undefined && filters !== null && filters.changeFechaInicio !== undefined ? (e) => filters.changeFechaInicio(e.target.value) : null}
                            />
                            <BasicInput 
                                type="date" 
                                placeholder="Fecha fin" 
                                value={filters !== undefined && filters !== null && filters.fechaFin !== undefined ? filters.fechaFin : ''}
                                setValue={filters !== undefined && filters !== null && filters.changeFechaFin !== undefined ? (e) => filters.changeFechaFin(e.target.value) : null}
                            />
                            <GridButton 
                                className="bg-secondary-200 m-2"
                                text="Filtrar"
                                clickFn={filters !== undefined && filters !== null && filters.applyFilter !== undefined ? () => filters.applyFilter() : null}
                            />
                        </section>
                    :
                        ''
                }
                
                <section className="col-span-1 ">
                    {buttonAdd ? buttonAdd : ''}
                </section>
            </header>
            <div className="w-full p-3">
                <div className="w-full p-3 bg-white rounded-lg">
                    <Table 
                        className="w-full"
                        data={getData()}
                        bordered={true}
                        cellBordered={true}
                        hover={true}
                        rowHeight={40}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        height={height}
                        wordWrap="break-word"
                        headerHeight={headerHeight}
                        locale={{ emptyMessage: "No se encontró información"}}
                    >
                    { 
                        header.map((item, index) => {
                            var sortable_column = true;
                            if(item.button_cell){
                                sortable_column = false;
                            }
                            return (
                                
                                <Column width={item.width} sortable={sortable_column} fixed={item.fixed} resizable key={index} align={item.align}>
                                    <HeaderCell>
                                    {
                                        item.summary ?
                                            <HeaderSummary title={item.title} summary={item.summary} />
                                        :
                                            item.title
                                    }
                                    </HeaderCell>

                                    {
                                        item.summary ?
                                            <NumberCell dataKey={item.key}/>
                                        :
                                        item.custom_button_cell ?
                                            <CustomButtonCell dataKey={item.key} click_fn={item.click_fn} custom_icon={item.custom_icon}/>
                                        :
                                        item.button_cell ?
                                            <ButtonCell dataKey={item.key} click_fn={item.click_fn}/>
                                        :
                                        item.custom_cell ?
                                            <CustomCell dataKey={item.key} compare_value={item.compare_value} true_class={item.true_class} false_class={item.false_class}/>
                                        :
                                        item.url_cell ?
                                            <CustomURLCell dataKey={item.key} />
                                        :
                                        item.alert_cell ? 
                                            <AlertCell dataKey={item.key} click_fn={item.click_fn}/>
                                        :
                                            <Cell dataKey={item.key}/>
                                    }
                                </Column>
                            );
                        })
                    }
                    </Table>
                    <div className="p-5 bg-gray-100 rounded-b-lg">
                        <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={data.length}
                        limitOptions={[10, 30, 50]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        />
                    </div>

                    <CSVLink
                        className="flex rounded-lg bg-secondary-200 p-2 m-1 justify-center items-center text-white font-bold cursor-pointer"
                        data={getData()}
                        headers={header.map(c => ({ label: c.title, key: c.key }))}
                        filename={title+'.csv'}
                        enclosingCharacter={''}
                        separator={';'}>
                        <FaDownload className='inline text-[22px] ml-2 text-white'></FaDownload>
                        &#8203;
                        Descargar
                    </CSVLink>
                </div>
            </div>

        </Fragment>
    );
}

export default Datagrid;

