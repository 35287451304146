import React, {useState, useEffect} from 'react';
import ListIcon from '@rsuite/icons/List';
import Container from '../UI/Container';
import Card from '../UI/Card';
import Modal from '../UI/Modal';
import Datagrid from '../UI/Datagrid';
import { grid_columns_rentas, grid_columns_rentas_detalle } from '../helpers/grid_columns';
import toast from 'react-hot-toast';

import { FaUserAlt, FaUsers, FaCheck, FaTruck, FaFileContract } from 'react-icons/fa';
import { MdNewReleases} from 'react-icons/md';
import { ReactComponent as RoboArm } from '../../assets/robo-arm.svg';
import { merent_instance } from '../helpers/axios_helper';

function Home() {
    const [modalDetalle, setModalDetalle] = useState(false);
    const [renterosActivos, setRenterosActivos] = useState(0);
    const [clientesActivos, setClientesActivos] = useState(0);
    const [renterosRentaActiva, setRenterosRentaActiva] = useState(0);
    const [clientesRentaActiva, setClientesRentaActiva] = useState(0);
    const [rentasActivas, setRentasActivas] = useState(0);
    const [nuevasSolicitudesRenta, setNuevasSolicitudesRenta] = useState(0);
    const [rentasPorAsignarFlete, setRentasPorAsignarFlete] = useState(0);
    const [rentasPorCrearContrato, setRentasPorCrearContrato] = useState(0);
    const [rentasRecientes, setRentasRecientes] = useState([]);
    
    const fechaActual = new Date().toLocaleDateString("en-CA");
    const oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
    ).toLocaleDateString("en-CA");
    
    const [filters, setFilters] = useState({
        fechaInicio: oneMonthAgo,
        fechaFin: fechaActual
    })

    const showDetail = () => {
        setModalDetalle(true);
    }
    const options_dropdown = [
        {
            label: 'Ver detalles de renta',
            icon: <ListIcon/>,
            action: showDetail
        },
    ];

    useEffect(() => {
        merent_instance().get("api/admin").then(({data}) => {
            const {clientes_activos, clientes_renta_activa, rentas_activas, renteros_activos, renteros_renta_activa, nuevas_rentas, rentas_pendiente_flete, rentas_pendiente_contrato} = data;
            setRenterosActivos(renteros_activos);
            setClientesActivos(clientes_activos);
            setRenterosRentaActiva(renteros_renta_activa);
            setClientesRentaActiva(clientes_renta_activa);
            setRentasActivas(rentas_activas);
            setNuevasSolicitudesRenta(nuevas_rentas);
            setRentasPorAsignarFlete(rentas_pendiente_flete);
            setRentasPorCrearContrato(rentas_pendiente_contrato);
        }).catch((e) => {
            console.log(e);
            toast.error("Ha habido un error al consultar la información")
        });


        merent_instance().get("api/admin/");

        cargarRentasRecientes();
    }, []);

    const cargarRentasRecientes = () => {
        const usr_id = localStorage.getItem("usr_id");
        const usr_role = localStorage.getItem("usr_role");

        if(usr_role === "Rentero")
            merent_instance().get(`api/rentas/rentero/${usr_id}?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                console.log(data);
                setRentasRecientes(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
        if(usr_role === "Admin")
            merent_instance().get(`api/rentas/admin?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                setRentasRecientes(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
    }

    return(
        <Container>
            <div className="grid grid-cols-1 lg:grid-cols-2 px-4 gap-x-4 gap-y-1">                    

                <Card   text="Renteros activos" 
                        icon={<FaUsers className="text-primary-400 mx-auto my-2"/>} 
                        qty={renterosActivos}/>
                <Card   text="Clientes activos" 
                        icon={<FaUserAlt className="text-primary-400 mx-auto my-2"/>} 
                        qty={clientesActivos}/>
                <Card   text="Renteros con renta activa" 
                        icon={<div className='flex items-center'><FaUsers className="text-primary-400 my-2"/><FaCheck className="text-primary-400 my-2 text-xl"/></div>} 
                        qty={renterosRentaActiva}/>
                <Card   text="Clientes con renta activa" 
                        icon={<div className='flex items-center'><FaUserAlt className="text-primary-400 my-2"/><FaCheck className="text-primary-400 my-2 text-xl"/></div>} 
                        qty={clientesRentaActiva}/>
                <Card   text="Rentas activas" 
                        icon={<RoboArm className="w-1/12 h-1/6 font-bold fill-primary-400 mx-auto my-2"/>} 
                        qty={rentasActivas}/>
                <Card   text="Nuevas solicitudes de renta" 
                        icon={<MdNewReleases className="w-1/12 h-1/6 font-bold fill-primary-400 mx-auto my-2"/>} 
                        qty={nuevasSolicitudesRenta}/>
                <Card   text="Rentas por asignar precio de flete" 
                        icon={<FaTruck className="w-1/12 h-1/6 font-bold fill-primary-400 mx-auto my-2"/>} 
                        qty={rentasPorAsignarFlete}/>
                <Card   text="Rentas por crear contrato" 
                        icon={<FaFileContract className="w-1/12 h-1/6 font-bold fill-primary-400 mx-auto my-2"/>} 
                        qty={rentasPorCrearContrato}/>
            </div>
            <Datagrid 
                title="Recientes"
                header={grid_columns_rentas(showDetail)}
                data={rentasRecientes}
                height={400}
                headerHeight={60}
                showFilters={false}
                options_dropdown={options_dropdown}
            />
            <Modal show={modalDetalle} setShow={setModalDetalle} title="Detalle de equipos" className="max-w-5xl">
                <Datagrid 
                    header={grid_columns_rentas_detalle}
                    data={[]}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>
        </Container>
    )
}

export default Home;