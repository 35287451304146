import ToggleButton from '../Sidebar/ToggleButton.js';

function Container({children}) {
    return(
        <main className="bg-construction-pattern bg-contain bg-origin-content w-full h-full overflow-y-scroll">
            <ToggleButton/>
            {children}
        </main>
    )
}

export default Container;