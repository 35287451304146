import React, {Fragment, useState, useEffect} from 'react'
import BasicInput from '../Inputs/BasicInput';
import BasicButton from '../Buttons/BasicButton';
import FilePreview from './FilePreview';
import toast from 'react-hot-toast';
import ImgConstancia from '../../../assets/contancia.webp';

function Form ({formFields, formState, formModifyState, formSave, preValidation, children, hidePlaceHolders, buttonText}) {
    const {id, activo} = formState;
    const [filePreview, setFilePreview] = useState({});

    const validateForm = () => {
        const fields = formFields.map((item) => {
            return item.fields
        }).flat(2);
        
        const arrErrors = fields.map((field) => {
            const {key, label, isRequired, regExp} = field;
            if(isRequired && (formState[key] === undefined || formState[key] === null || formState[key] === '')) {
                toast.error(`El campo ${label} es obligatorio`);
                return false;
            }
            if ((!!regExp && !formState[key].match(regExp))) {
                toast.error(`El campo ${label} tiene un formato invalido, por favor validalo`);
                return false;
            }
            return true;
        });

        if(arrErrors.includes(false)){
            return;
        }
        if(preValidation){
            if(!preValidation()){
                return;
            }
        }
        
        formSave();
    }

    const changeState = (state) => {
        formModifyState({...formState, activo:state});
        validateForm();
    }

    const getFilePreview = (key, e) => {
        formModifyState({...formState, [key]: e.target.files});
        setFilePreview({...filePreview, [key]: e.target.files});
    }

    return (
        <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 px-4 gap-x-4 gap-y-1" onSubmit={(e) => {e.preventDefault()}}>
            {formFields.map((header, index) => {
                const {title, fields, schema_text} = header;
                const fieldsObjects = fields.map((item, index) => {
                    const {key, type, label, isRequired, options, disabled, maxFilesQty, accept, className} = item;
                    return (
                        <div key={`header_${index}`} className={`${type === 'file' ? 'col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 ' : className !== undefined && className !== null && className !== "" ? className : ''}`}>
                            <span>{label} <b className="text-primary-500">{isRequired ? '*' : ''}  </b></span>
                                <BasicInput 
                                    type={type}
                                    placeholder={hidePlaceHolders ? "" : label} 
                                    defaultValue={type === 'file' && formState.id !== 0 ? "" : formState[key]} 
                                    
                                    setValue={(e) => {  
                                                type === 'file' 
                                                ? getFilePreview(key, e)
                                                : formModifyState({...formState, [key]: e.target.value})
                                            }}
                                    options={options}
                                    disabled={disabled}
                                    maxFilesQty={maxFilesQty}
                                    accept={accept}
                                />
                                {
                                    type === 'file' && filePreview[key] !== null && filePreview[key] !== undefined && filePreview[key] !== "" ?   
                                        <FilePreview key={[key]} files={filePreview[key]} type="preview" /> 
                                    :
                                    type === 'file' ?
                                        <FilePreview key={[key]} files={formState[key]} type="default" /> 
                                    : 
                                    <></>
                                }
                        </div>
                    )
                })
                return (
                    <Fragment key={`body_${index}`}>
                        <p className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 text-xl text-secondary-500 border-b-4 mb-2">
                            {title}
                        </p>
                        {fieldsObjects}
                        {
                            schema_text !== undefined && schema_text !== null && schema_text !== "" ? 
                            schema_text[formState['esquemaRef']]
                            :
                            ''
                        }
                        
                    </Fragment>
                );
            })}
            {children}
            <BasicButton className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3" type="button" text={buttonText !== undefined && buttonText !== null && buttonText !== "" ? buttonText : id === 0 ? "Guardar nuevo" : "Guardar cambios"} clickFn={validateForm}/>
        </form>
    );
}

export default Form;