import React, { useState, useEffect } from 'react';
import BasicInput from '../UI/Inputs/BasicInput';
import StepWizard from "react-step-wizard";
import Step from '../UI/Wizard/Step';
import Nav from '../UI/Wizard/Nav';
import FileInput from '../UI/Inputs/FileInput';
import { FaSpinner } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { RegExpEmail, RegExpPassword } from '../helpers/constants';

import { merent_instance } from '../helpers/axios_helper';

function RegisterOperador() {
    const [names, setNames] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [categoria, setCategoria] = useState(0);
    const [description, setDescription] = useState("");
    const [estado, setEstado] = useState(0);
    const [ciudad, setCiudad] = useState(0);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [selectedImage, setSelectedImage] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [errorCreating, setErrorCreating] = useState("");
    const [categoriasOperador, setCategoriasOperador] = useState([]);

    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    const navigation = useNavigate();

    const obtenerCategoriasOperador = () => {
        merent_instance().get("api/categoriaoperador").then(({data}) => {
            const newCategorias = data.map(categoria => {
                return {
                    id: categoria.id,
                    value: categoria.id,
                    label: categoria.nombre
                }
            });
            setCategoriasOperador(newCategorias);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const obtenerEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);
    
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    const obtenerCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
          }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
          })
    }

    useEffect(() => {
        obtenerCategoriasOperador();
        obtenerEstados();
    }, []);
    

    const handleSave = () => {
        if(selectedImage.length <= 0){
            toast.error("Selecciona una imagen de perfil para terminar tu registro");
            return;
        }
        setIsCreating(true);
        const informacion_operador = {
            nombre: names,
            apellidos: lastNames,
            email: email,
            password: password,
            telefono: phone,
            categoriaOperador: categoria,
            descripcion: description,
            estado: estado,
            ciudad: ciudad,
            imagenPerfil: 'aaaa'
        }
        merent_instance().post("api/operador", informacion_operador).then(({data, status}) => {
            saveImageOperador(data);
        }).catch(({response}) => {
            setIsCreating(false);
            toast.error("Hubo un error con su registro, intente nuevamente más tarde");
            setErrorCreating(response);
        });
    }

    const saveImageOperador = (user_id) => {
        let form_data = new FormData();
        form_data.append("imagen", selectedImage[0].file);
        merent_instance().post(`api/usuarios/${user_id}/imagen`,form_data, {"Content-Type": "multipart/form-data",})
        .then(({data}) => {
            setIsCreating(false);
            toast.success("Registro exitoso, serás redireccionado a la pantalla principal");
            setTimeout(() => {
                navigation("/");
            }, 3000);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar su imagen de perfil, guardala en tu perfil más adelante");
        });
    }

    const validaContacto = () => {
        if(names == ''){
            toast.error("Por favor introduce tu(s) nombre");
            return false;
        }
        if(lastNames == ''){
            toast.error("Por favor introduce tu(s) apellidos");
            return false;
        }
        if(phone == ''){
            toast.error("Por favor introduce un numero de telefono valido");
            return false;
        }
        if(password == ''){
            toast.error("Por favor introduce una contraseña");
            return false;
        }

        if(email == ''){
            toast.error("Por favor introduce una dirección de correo");
            return false;
        }
        
        if(!email.match(RegExpEmail)){
            toast.error("Introduce una direccion de correo valida");
            return false;
        }

        if(!password.match(RegExpPassword)){
            toast.error("La contraseña debe tener 8 caracteres, una minuscula, una mayuscula y un numero");
            return false;
        }

        if(password != confirmPassword){
            toast.error("Las contraseñas no coinciden")
            return false;
        }
        return true;
    }

    const validaServicios = () => {
        if(categoria == 0) {
            toast.error("Por favor selecciona una categoria");
            return false;
        }

        if(description == ''){
            toast.error("Por favor introduce una descripción")
            return false;
        }
        if(estado == 0){
            toast.error("Por favor selecciona un estado")
            return false;
        }
        if(ciudad == 0){
            toast.error("Por favor selecciona una ciudad")
            return false;
        }

        return true;
    }

    return (
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-construction-pattern bg-contain bg-origin-content drop-shadow-sm ">
            <div className="relative h-[45rem] mx-5 md:w-3/4 lg:w-3/4 xl:w-3/4 xs:w-full sm:w-full">
                <h1 className='text-2xl text-primary-400 font-semibold text-center'>Regístrate como operador</h1>
                <div className="absolute w-full h-full bg-white flex justify-center items-center rounded-lg border-small border-primary-200 shadow-md shadow-primary-100">
                    <StepWizard
                        className='w-full h-full p-12'
                        nav={<Nav/>}>
                        <Step validaPaso={validaContacto}>
                            <p className="text-lg text-gray-500 font-semibold">Información de contacto</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa tu información personal para iniciar tu registro</p>
                            <section className="grid grid-cols-1 grid-rows-1 gap-x-2 gap-y-0.5 mb-12 md:grid-cols-2">
                                <BasicInput type="text" placeholder="Nombre(s)" defaultValue={names} setValue={(e) => setNames(e.target.value)}/>
                                <BasicInput type="text" placeholder="Apellido(s)" defaultValue={lastNames} setValue={(e) => setLastNames(e.target.value)}/>
                                <BasicInput type="text" placeholder="Correo electronico" defaultValue={email} setValue={(e) => setEmail(e.target.value)}/>
                                <BasicInput type="text" placeholder="Telefono de contacto" defaultValue={phone} setValue={(e) => setPhone(e.target.value)} autoComplete={"new-password"}/>
                                <BasicInput type="password" placeholder="Contraseña" defaultValue={password} setValue={(e) => setPassword(e.target.value)} autoComplete={"new-password"}/>
                                <BasicInput type="password" placeholder="Confirma tu contraseña" defaultValue={confirmPassword} setValue={(e) => setConfirmPassword(e.target.value)} autoComplete={"new-password"}/>
                            </section>
                        </Step>
                        <Step validaPaso={validaServicios}>
                            <p className="text-lg text-gray-500 font-semibold">Información de tus servicios</p>
                            <p className="text-sm text-gray-600 font-semibold">Por favor, ingresa la información de los servicios que puedes proveer como operario, el tipo de equipos que manejas, tu experiencia laboral en este ámbito, el estado y ciudad en la que trabajas. </p>
                            <section className="grid grid-cols-1 grid-rows-1 gap-x-2 gap-y-0.5 md:grid-cols-1">
                                <BasicInput type="select" placeholder="Servicio / Categoria" defaultValue={categoria} setValue={(e)=>{setCategoria(e.target.value)}} options={categoriasOperador}/>
                                <BasicInput type="text" placeholder="Descripción de tu trabajo y experiencia" defaultValue={description} setValue={(e) => setDescription(e.target.value)}/>
                            </section>
                            <h6 className='text-left'>Dirección (Estado y Ciudad)</h6>
                            <section className="grid grid-cols-1 grid-rows-1 gap-x-2 gap-y-0.5 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 mb-10">
                                <BasicInput type="select" placeholder="Estado" defaultValue={estado} setValue={(e)=>{setEstado(e.target.value); obtenerCiudades(e.target.value);}} options={estados} />
                                <BasicInput type="select" placeholder="Ciudad" defaultValue={ciudad} setValue={(e)=>{setCiudad(e.target.value)}} options={ciudades}/>
                            </section>
                        </Step>
                        <Step save={handleSave}>
                            <p className="text-lg text-gray-500 font-semibold">Imagen de perfil</p>
                            <FileInput 
                            onFileSelectSuccess={(file) => setSelectedImage(file)}
                            onFileSelectError={({ error }) => alert(error)}
                            amount={1}
                            filesText="Adjunta tu foto de perfil"
                            />
                            {
                                isCreating ?
                                <p className="bg-white text-gray-700 py-1 m-3 rounded relative">
                                    <center><FaSpinner icon="spinner" className="text-primary-400 animate-spin text-4xl m-8" /></center>
                                    Registro en proceso
                                </p>
                                :
                                <></>
                            }
                        </Step>
                    </StepWizard>
                </div>
            </div>
        </div>
    );
}

export default RegisterOperador;