import React, { useState, useEffect } from 'react';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import Datagrid from '../UI/Datagrid';
import Container from '../UI/Container';
import BasicButton from '../UI/Buttons/BasicButton';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import toast from 'react-hot-toast';
import { grid_columns_categorias } from '../helpers/grid_columns';
import { form_fields_categorias } from '../helpers/form_fields';
import { merent_instance } from '../helpers/axios_helper';

function CategoriasOperador() {
    const [modalCategoria, setModalCategoria] = useState(false);
    const [modalDeleteCategoria, setModalDeleteCategoria] = useState(false);
    const [categoriaFields, setCategoriaFields] = useState({
        id: 0,
        nombre: '',
        descripcion: '',
        keywords: '',
        activo: true
    });
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        obtenerCategorias();
    }, []);

    const editCategoria = (item) =>  {
        setCategoriaFields(item);
        setModalCategoria(true);
    };

    const obtenerCategorias = () => {
        merent_instance().get("api/categoriaoperador").then(({data}) => {
            setCategorias(data);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al consultar informaicion")
        });
    }

    const newCategoria = () => {
        setCategoriaFields({
            id: 0,
            nombre: '',
            descripcion: '',
            keywords: '',
            activo: true
        });
        setModalCategoria(true);
    }

    const saveCategoria = () => {
        console.log(categoriaFields);
        const body_categoria = {
            nombre: categoriaFields.nombre,
            descripcion: categoriaFields.descripcion,
            keyword: categoriaFields.keywords
        }
        merent_instance().post("api/categoriaoperador", body_categoria).then(({data}) => {
            console.log(data);
            toast.success("Categoría guardada");
            obtenerCategorias();
            setModalCategoria(false);
        }).catch(error => {
            console.log(error)
            toast.error("Hubo un error al guardar la categoría")
        })
    };

    const deleteCategoria = (item) => {
        console.log(item);
        setCategoriaFields(item);
        setModalDeleteCategoria(true);
    }

    const confirmDeleteCategoria = () => {
        merent_instance().delete(`api/categoriaoperador/${categoriaFields.id}`).then(({data}) => {
            console.log(data);
            toast.success("Categoria Eliminada");
            setCategoriaFields({
                id: 0,
                nombreCategoria: '',
                descripcion: '',
                keywords: '',
                activo: true
            });
            setModalDeleteCategoria(false);
            obtenerCategorias();
        }).catch(error => {
            console.log(error);
            toast.error("Hubo un error al borrar la categoria")
        })
    }
    const options_dropdown = [
        {
            label: 'Ver/Editar',
            icon: <EditIcon/>,
            action: editCategoria
        },
        {
            label: 'Desactivar',
            icon: <TrashIcon/>,
            action: deleteCategoria
        }
    ];

    return (
        <Container>
            <Datagrid 
                title="Categorias de operador"
                header={grid_columns_categorias(editCategoria)}
                data={categorias}
                height={500}
                headerHeight={40}
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar nueva" type="button" clickFn={newCategoria}/>}
            />

            <Modal show={modalCategoria} setShow={setModalCategoria} title="Agregar categorias" className="max-w-5xl">
                <Form 
                    formFields={form_fields_categorias} 
                    formState={categoriaFields} 
                    formModifyState={setCategoriaFields}
                    formSave={saveCategoria}
                    />
            </Modal>
            <Modal show={modalDeleteCategoria} setShow={setModalDeleteCategoria} title="Categoria" className="max-w-5xl">
                <header className="w-full">
                    <p>¿Seguro que deseas desactivar la categoria {categoriaFields.nombre}?</p>
                </header>
                <section className="grid grid-cols-2 gap-x-8 gap-y-1">
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1" type="button" text="Cancelar" clickFn={() => {setModalDeleteCategoria(false)}}/>
                    <BasicButton className="col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 bg-red-500" type="button" text="Desactivar" clickFn={confirmDeleteCategoria}/>
                </section>
            </Modal>
        </Container>
    )
}

export default CategoriasOperador;