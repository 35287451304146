import React, { useState } from 'react';
import ListIcon from '@rsuite/icons/List';
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import Datagrid from '../UI/Datagrid'
import Container from '../UI/Container';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import { grid_columns_pagos, grid_columns_rentas_detalle } from '../helpers/grid_columns';
import { dummy_data_pagos, dummy_data_rentas_detalle } from '../helpers/dummy_data';
import { form_fields_pagos } from '../helpers/form_fields';
import toast from 'react-hot-toast';
import { merent_instance } from '../helpers/axios_helper';

function Pagos() {
    const [modalDetalle, setModalDetalle] = useState(false);
    const [modalPago, setModalPago] = useState(false);
    const [pagos, setPagos] = useState([]);
    const [pagoFields, setPagoFields] = useState({
        id: 0,
        monto: '',
        cuentaOrigen: '',
        concepto: '',
        file: ''
    });

    const fechaActual = new Date().toLocaleDateString("en-CA");
    const oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
    ).toLocaleDateString("en-CA");
    
    const [filters, setFilters] = useState({
        fechaInicio: oneMonthAgo,
        fechaFin: fechaActual
    })

    const cargarPagos = () => {
        merent_instance().get(`api/pagos/admin?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
            setPagos(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }

    const showDetail = (rowData) => {
        setModalDetalle(true);
    }

    const showPago = (rowData) => {
        setPagoFields(
            {
                id: 0,
                monto: '',
                cuentaOrigen: '',
                concepto: '',
                file: ''
            }
        );
        setModalPago(true);
    }

    const savePago = () => {
        toast.success("Pago agregado");
        setModalPago(false);
    }

    const options_dropdown = [
        {
            label: 'Ver detalle de pagos',
            icon: <ListIcon/>,
            action: showDetail
        },
        {
            label: 'Agregar pago',
            icon: <CreditCardPlusIcon/>,
            action: showPago
        },
    ];

    return (
        <Container>
            <Datagrid 
                title="Pagos"
                header={grid_columns_pagos(showDetail)}
                data={pagos}
                height={520}
                options_dropdown={options_dropdown}
                headerHeight={60}
                showFilters={true}
                filters={{...filters, changeFechaInicio: (val) => setFilters({...filters, fechaInicio: val}), changeFechaFin: (val) => setFilters({...filters, fechaFin: val}), applyFilter: () => cargarPagos()}}
            />
            <Modal show={modalPago} setShow={setModalPago} title="Agregar pago a cliente" className="max-w-5xl">
                <Form
                    formFields={form_fields_pagos} 
                    formState={pagoFields} 
                    formModifyState={setPagoFields}
                    formSave={savePago}
                />
            </Modal>
            <Modal show={modalDetalle} setShow={setModalDetalle} title="Detalle de equipos" className="max-w-5xl">
                <Datagrid 
                    header={grid_columns_rentas_detalle}
                    data={dummy_data_rentas_detalle}
                    height={520}
                    headerHeight={60}
                    showFilters={false}
                />
            </Modal>
        </Container>
    )
}

export default Pagos;