import React, { useState, useEffect } from 'react';
import Container from '../UI/Container';
import Datagrid from '../UI/Datagrid';
import { grid_columns_equipos_admin, grid_columns_obras, grid_columns_obras_equipo } from '../helpers/grid_columns';
import toast from 'react-hot-toast';
import { merent_instance } from '../helpers/axios_helper';
import EditIcon from '@rsuite/icons/Edit';
import Modal from '../UI/Modal';
import Form from '../UI/Form';
import BasicButton from '../UI/Buttons/BasicButton';
import BasicInput from '../UI/Inputs/BasicInput';
import { form_fields_obra } from '../helpers/form_fields';

function AdminEquipos() {
    const [equiposAdmin, setEquiposAdmin] = useState([]);
    const [modalObras, setModalObras] = useState(false);
    const [modalObrasEquipo, setModalObrasEquipo] = useState(false);
    const [nameEquipo, setNameEquipo] = useState("");
    const [idEquipo, setIdEquipo] = useState("");

    const [obras, setObras] = useState([]);
    const [obrasSelect, setObrasSelect] = useState([]);
    const [obrasEquipo, setObrasEquipo] = useState([]);
    const [obrasBody, setObrasBody] = useState({
        id: 0,
        nombre:"",
        ubicacion:"",
    });
    const [newObraEquipo, setNewObraEquipo] = useState(0);

    useEffect(() => {
        cargarEquipos();
        cargarObras();
    }, [])

    const cargarEquipos = () => {
        merent_instance().get(`api/equipos`).then(({data}) => {
            setEquiposAdmin(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }

    const cargarObras = () => {
        merent_instance().get(`api/obra`).then(({data}) => {
            setObras(data);
            const new_obras = data.map(obra => {
                return {
                    id: obra.id,
                    value: obra.id,
                    label: obra.nombre
                }
            });
            setObrasSelect(new_obras)
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }

    const cargarObrasEquipo = (id) => {
        setIdEquipo(id);
        merent_instance().get(`api/obra/equipo/${id}`).then(({data}) => {
            setObrasEquipo(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error en la solicitud");
        });
    }

    const actionOptions = (item) => {
        setModalObrasEquipo(true);
        setNameEquipo(item.nombre);
        cargarObrasEquipo(item.id);
    }
    
    const options_dropdown = [
        {
            label: 'Agregar Subcomunidad especial',
            icon: <EditIcon/>,
            action: actionOptions
        },
    ];

    const saveObra = () => {
        merent_instance().post(`api/obra/`,obrasBody).then(({data}) => {
            toast.success(data);
            cargarObras();
            resetObraFields();
            
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar la obra");
        })
    }

    const newObra = () => {
        setModalObras(true);
    }

    const resetObraFields = () => {
        setObrasBody({
            id: 0,
            nombre:"",
            ubicacion:"",
        })
    }

    const saveObraEquipo = () => {
        const body_form = {
            obraRef:newObraEquipo,
            equipoRef:idEquipo
        }
        merent_instance().post(`api/obra/equipo`,body_form).then(({data}) => {
            toast.success(data);
            cargarObrasEquipo(idEquipo);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al guardar la obra");
        })
    }

    return (
        <Container>
            <Datagrid 
                title="Listado de equipos"
                header={grid_columns_equipos_admin()}
                data={equiposAdmin}
                height={500}
                headerHeight={40}   
                showFilters={false}
                options_dropdown={options_dropdown}
                buttonAdd={<BasicButton text="Agregar nueva Subcomunidad especial" type="button" clickFn={newObra}/>}
            />

            <Modal show={modalObras} setShow={setModalObras} title={"Listado de subcomunidades especiales"} className="max-w-5xl">
                <section className="grid grid-cols-1">
                    <Form 
                        formFields={form_fields_obra()} 
                        formState={obrasBody} 
                        formModifyState={setObrasBody}
                        formSave={saveObra}
                        buttonText={"Guardar nueva subcomunidad especial"}
                    />
                </section>
                <Datagrid 
                    title="Listado de obras"
                    header={grid_columns_obras}
                    data={obras}
                    height={500}
                    headerHeight={40}   
                    showFilters={false}
                />
            </Modal>

            <Modal show={modalObrasEquipo} setShow={setModalObrasEquipo} title={"Subcomunidad especial para equipo '" + nameEquipo + "'"} className="max-w-5xl">
                <section>
                    <p>Agregar Subcomunidad especial al equipo</p>
                    <BasicInput
                        type="select"
                        placeholder="Selecciona..."
                        defaultValue={newObraEquipo}
                        setValue={(e) => setNewObraEquipo(e.target.value)}
                        options={obrasSelect}
                        disabled={false}
                    />
                <BasicButton className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3" type="button" text={"Agregar obra al equipo"} clickFn={saveObraEquipo}/>
            </section>
                <section className="grid grid-cols-1">
                <Datagrid 
                    title="Listado de Subcomunidad especial"
                    header={grid_columns_obras_equipo}
                    data={obrasEquipo}
                    height={500}
                    headerHeight={40}   
                    showFilters={false}
                />
                </section>
            </Modal>
        </Container>
    )
}

export default AdminEquipos;