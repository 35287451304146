import React, { useState, useEffect } from 'react';
import Container from '../UI/Container';
import Datagrid from '../UI/Datagrid';
import { grid_columns_soporte } from '../helpers/grid_columns';
import { dummy_data_soporte } from '../helpers/dummy_data';
import toast from 'react-hot-toast';
import { merent_instance } from '../helpers/axios_helper';

function Soporte() {
    const [ticketsSoporte, setTicketsSoporte] = useState([]);
    const fechaActual = new Date().toLocaleDateString("en-CA");
    const oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
    ).toLocaleDateString("en-CA");
    
    const [filters, setFilters] = useState({
        fechaInicio: oneMonthAgo,
        fechaFin: fechaActual
    });

    useEffect(() => {
        cargarTicketsSoporte();
    }, [])

    const cargarTicketsSoporte = () => {
        const usr_id = localStorage.getItem("usr_id");
        const usr_role = localStorage.getItem("usr_role");

        if(usr_role === "Rentero")
            merent_instance().get(`api/ticketsoporte/rentero/${usr_id}?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                setTicketsSoporte(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
        if(usr_role === "Admin")
            merent_instance().get(`api/ticketsoporte/admin?fechaInicio=${filters.fechaInicio}&fechaFin=${filters.fechaFin}`).then(({data}) => {
                console.log(data);
                setTicketsSoporte(data);
            }).catch((error) => {
                console.log(error);
                toast.error("Hubo un error en la solicitud");
            });
    }

    return (
        <Container>

            <div className='m-5 text-secondary-300'>    
                <p className='mt-3 text-lg text-center'>¿Necesitas ayuda con algo en particular?</p>
                <h2 className='mt-3 text-lg text-center'>Puedes llamarnos al numero <strong className='text-primary-400'>+52 1 55 8015 2500</strong></h2>
                <h3 className='mt-3 text-lg text-center'>Tambien nos puedes encontrar por correo <strong className='text-primary-400'>contacto@merent.mx</strong> </h3>
                <p className='text-center'>Estamos para ayudarte</p>
            </div>
            <Datagrid 
                title="Soporte"
                header={grid_columns_soporte}
                data={ticketsSoporte}
                height={500}
                headerHeight={40}
                showFilters={true}
                filters={{...filters, changeFechaInicio: (val) => setFilters({...filters, fechaInicio: val}), changeFechaFin: (val) => setFilters({...filters, fechaFin: val}), applyFilter: () => cargarTicketsSoporte()}}
            />
        </Container>
    )
}

export default Soporte;