import React, {Fragment, useState, useEffect} from 'react'
import BasicInput from '../Inputs/BasicInput';
import BasicButton from '../Buttons/BasicButton';
import FilePreview from './FilePreview';
import toast from 'react-hot-toast';
import { MdRemoveCircleOutline } from 'react-icons/md';

function ItemList ({formFields, formState, formModifyState, title, itemsList, setItemsList, lineFileds, textAdd}) {
    
    const [fields, setFileds] = useState([]);
    const [initialValue, setInitialValue] = useState(formState);

    useEffect(() => {
        if(formFields !== undefined && formFields !== null && formFields.length > 0){
            setFileds(formFields.map((item) => {
                return item.fields
            }).flat(2));
        }

        setInitialValue(formState);
    }, []);     

    const agregarItem = () => {
        const arrErrors = fields.map((field) => {
            const {key, label, isRequired, regExp, repited} = field;
            if(isRequired && (formState[key] === undefined || formState[key] === null || formState[key] === '')) {
                toast.error(`El campo ${label} es obligatorio`);
                return false;
            }
            if ((!!regExp && !formState[key].match(regExp))) {
                toast.error(`El campo ${label} tiene un formato invalido, por favor validalo`);
                return false;
            }
            const found = itemsList.some(el => el[key] === formState[key]);
            if (repited === false && found){
                toast.error(`${label} ya existe en el listado`);
                return false;
            }

            return true;
        });

        if(arrErrors.includes(false))
            return;
        
        const max = itemsList !== null && itemsList.length > 0 ? itemsList.reduce((prev, current) => (prev.item_id > current.item_id) ? prev : current).item_id : 0;
        
        var item = {...formState, item_id: max + 1};
        
        setItemsList([...itemsList, item]);
        formModifyState(initialValue);
    }

    const removerItem = (item_id) => {
        setItemsList(itemsList.filter(item => item.item_id !== item_id));
    }
    return (
        <section className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-${lineFileds} xl:grid-cols-${lineFileds} p-4 gap-x-4 gap-y-1`}>
            {formFields.map((header, index) => {
                const {fields} = header;
                const fieldsObjects = fields.map((item, index) => {
                    const {key, type, label, isRequired, options, disabled, maxFilesQty, accept} = item;
                    return (
                        <div key={`header_list_${index}`}>
                            <span>{label} <b className="text-primary-500">{isRequired ? '*' : ''}  </b></span>
                            <BasicInput 
                                type={type} 
                                placeholder={label} 
                                defaultValue={formState[key]} 
                                setValue={(e) => { formModifyState({...formState, [key]: e.target.value}) }}
                                value={formState[key]}
                                options={options}
                                disabled={disabled}
                                maxFilesQty={maxFilesQty}
                                accept={accept}
                            />
                        </div>
                    )
                })
                return (
                    <Fragment key={`body_list_${index}`}>
                        <p className={`col-span-1 md:col-span-1 lg:col-span-${lineFileds} xl:col-span-${lineFileds} text-xl text-secondary-500 border-b-4 mb-2`}>
                            {title}
                        </p>
                        {fieldsObjects}
                        <BasicButton className="h-10 mt-6" type="button" text={textAdd} clickFn={agregarItem}/>
                    </Fragment>
                );
            })}
            {
                itemsList !== undefined && itemsList !== null && itemsList.length > 0 ?
                    <section className={`col-span-1 md:col-span-1 lg:col-span-${lineFileds} xl:col-span-${lineFileds} grid grid-cols-1 md:grid-cols-1 lg:grid-cols-${lineFileds} xl:grid-cols-${lineFileds} p-4 gap-x-4 gap-y-1 bg-gray-100`}>
                        <p className={`col-span-1 md:col-span-1 lg:col-span-${lineFileds} xl:col-span-${lineFileds} text-lg text-primary-500`}>
                            Elementos a guardar
                        </p>
                        {
                            fields.map((field, idx) =>{
                                return <p className='text-secondary-300 mt-0 text-center' key={`header_${idx}`}> {field.label} </p>
                            })
                        }
                        {
                            itemsList.map((item, idx) => {
                                return(
                                    <Fragment key={`frag_${idx}`}>
                                        {
                                            fields.map((field, idx) =>{
                                                const {key} = field;
                                                return  <p className='text-center mx-2' key={`label_${item.item_id}_${idx}`}> 
                                                            {item[key] === "true" ? "Sí" : item[key] === "false" ? "No" : item[key]} 
                                                        </p>
                                            })
                                        }
                                        <BasicButton className="h-8 m-0 text-xs !w-1/2 justify-self-center" type="button" text="Remover" 
                                        clickFn={() => removerItem(item.item_id)}
                                        key={`btn_${idx}`}/>
                                    </Fragment>    
                                )                            
                            })
                        }
                    </section>
                :
                ''
            }
            
        </section>
    );
}

export default ItemList;