import React, { forwardRef } from 'react'
import { Popover, Dropdown } from 'rsuite';

const DropdownButton = React.forwardRef (({ onClose, left, top, className, options, row_data }, ref) => {
    return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu >
                {
                    options.map((item, index) => {
                        const { label, icon, action }= item;
                        return(
                            <Dropdown.Item key={index} onClick={() => action(row_data)} icon={icon}>{label}</Dropdown.Item>
                        )
                    })
                }
        </Dropdown.Menu>
        </Popover>
    );
});

export default DropdownButton;